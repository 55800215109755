import { useState, useEffect } from 'react';

export const useDeviceResizing = (): { mobile: boolean; tablet: boolean } => {
  const [isDeviceFound, setIsDeviceFound] = useState({
    mobile: window.innerWidth <= 767,
    tablet: window.innerWidth > 767 && window.innerWidth <= 991,
  });
  const handleResize = (): void => {
    if (window.innerWidth <= 767) {
      setIsDeviceFound({ mobile: true, tablet: false });
    } else if (window.innerWidth > 767 && window.innerWidth <= 991) {
      setIsDeviceFound({ mobile: false, tablet: true });
    } else {
      setIsDeviceFound({ mobile: false, tablet: false });
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('load', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', handleResize);
    };
  }, []);
  return isDeviceFound;
};
