import { AppContent } from '../AppContent';

const cookiePolicy: AppContent['cookiePolicy'] = {
  CookieHeading: 'Cookie Policy',
  CookieLastUpdated: 'Last updated May 2024 ',
  CookieTitle:
    'Our Cookie Policy explains how we use cookies and similar tracking technologies. It also explains the choices you can make about whether we can put some of these cookies on your computer, tablet or mobile device. ',
  CookieData: `In this policy, where we say 'cookies' it can also mean similar tracking technologies. Similar tracking technologies are those that collect data while you use our websites and mobile apps, and when you receive, open or respond to emails we send you. You can find more information on the tracking we use in our emails, including how you can stop it, in the Email Tracking section.`,
  CookieDataCollectTo:
    'Data we collect will be held by Black Horse, which is part of Lloyds Banking Group. We use this data to: ',
  CookieListfraud: 'Protect you from fraud and keep improving security',
  CookieListService:
    'Study how people use our websites and apps and our other services. This helps us to improve them',
  CookieListProduct:
    'Decide which of our products, services and offers may be relevant for you',
  CookieListMarketing:
    'Tailor the marketing you see on social media, apps and other websites ',
  CookieListShareData:
    'We may share this data with other companies in the Group. We also share some data with outside organisations. Some of them may place their own cookies on your device when you visit our website. You can find out more about how we share data – and who we share it with – in our <a href="/privacy-policy" target="_blank">Privacy notice.</a>',
  CookieSellData:
    'We do not sell the personal information we have about you to outside organisations.',
  CookiesWhatIsHeading: 'UNDERSTANDING COOKIES ',
  CookiesDefenition:
    'Cookies are small files that are sent to your computer, tablet or mobile device by websites when you visit them. They stay on your device, so that when you visit the website again, it knows that you’ve been there before and can give you a better experience. Cookies store information about your visits to that website. This information could be your choices, the type of device you used to connect to it, where you were when you connected, and the pages you visited on the site. ',
  MultipleUsersHeading: 'MULTIPLE USERS',
  MultipleUsersTxt:
    'If more than one person uses your device, the choices set up by other people will apply to you as well unless you change them. For example, if you share a computer with your family, you may see ads based on sites they have visited as well as sites you have visited. ',
  CookiesSessionHeading: 'SESSION AND PERSISTENT COOKIES',
  CookiesSessionContent:
    'All cookies are either session cookies or persistent cookies.',
  SessionCookies:
    'Session cookies last for the length of your visit to a website. They delete themselves when you close your browser. Session cookies can help with security. For instance, they can keep you logged on as you move around a website. ',
  PersistentCookies:
    'Persistent cookies stay on your device when you close your browser. Persistent cookies are used for many jobs. For instance, they can remember your User ID for websites where you log on. They can also store your choices for when you go back to a website. ',
  CookieAdsAndMarketing:
    'Organisations find them useful to see how people use their websites. We may also use them to tailor the ads and marketing you see on social media, apps and other websites. ',
  CookieFirstAndThirdHeading: 'FIRST AND THIRD-PARTY COOKIES',
  CookieFirstAndThirdContent:
    'Whether a cookie is first or third-party depends on where it comes from.',
  CookieFirstWebsite: 'First-party cookies are set by websites you go to.',
  CookieFirstWebsiteContent: `Third-party cookies are set by outside organisations such as social media, search engines, other advertising networks and our business partners. `,
  ThirdPartyHeading: `OUR THIRD-PARTY PARTNERS`,
  ThirdPartyConsent: `If you have given us your consent, we may allow third parties to collect your data by setting their own cookies on your device. If you use our Mobile Banking app, they may also use mobile device identifiers to personalise and measure adverts shown to you. `,
  ThirdPartyChoice: `You can change your choices at any time. Select the 'Cookies' link at the bottom of any page of our website and go from there. `,
  ThirdPartyMobile: `If you use one of our mobile apps, you can also change how your mobile device identifier is used. You can do that by updating your settings in the app.`,
  ThirdPartyLinks: `Here are links to the information notices for our main Third-Party Partners.`,
  GoogleLinks: `GOOGLE`,
  GoogleCookies: `<a href="https://policies.google.com/technologies/ads" target="_blank">How Google use cookies in advertising</a>`,
  GoogleData: `<a href="https://policies.google.com/technologies/partner-sites" target="_blank">How Google uses the data it collects</a>`,
  GoodleType: `<a href="https://policies.google.com/technologies/cookies#types-of-cookies" target="_blank">Types of cookies we use with Google</a>`,
  OracleLinks: `ORACLE CLOUD`,
  OracleData: `<a href="https://www.oracle.com/uk/legal/privacy/privacy-policy/" target="_blank">Oracle Data Cloud Privacy Policy</a>`,
  OracleCookies: `<a href="https://datacloudoptout.oracle.com/#optout" target="_blank">Opt-out from Oracle cookies</a>`,
  MetaLinks: `META`,
  MetaCookies: `<a href="https://www.facebook.com/policy/cookies" target="_blank">Meta Cookies Policy</a>`,
  MetaPrivacy: `<a href="https://www.facebook.com/privacy/policy%60" target="_blank">Meta Privacy Policy</a>`,
  MicrosoftLinks: `MICROSOFT ADVERTISING (BING/YAHOO)`,
  MicrosoftLegal: `<a href="https://help.ads.microsoft.com/#apex/ads/en/ext60212" target="_blank">Legal, privacy and security policies - Microsoft Advertising</a>`,
  MicrosoftPrivacy: `<a href="https://www.microsoft.com/en-us/privacy/privacystatement" target="_blank">Microsoft Privacy Statement – Microsoft privacy</a>`,
  LinkedinLinks: `LINKEDIN`,
  LinkedInCookies: `<a href="https://www.linkedin.com/legal/cookie-policy" target="_blank">LinkedIn Cookie Policy</a>`,
  CookiesUses: `COOKIES WITH MORE THAN ONE USE`,
  CookiesUsesTxt: `Some of our cookies collect data for more than one use. We will only use these cookies for their essential purposes unless you have given your consent to any other uses they have. `,
  CookiesOnDevice: `COOKIES THAT ARE ALREADY ON YOUR DEVICE`,
  CookiesOnDeviceTxt: `Turning off one or more types of cookies won’t delete any that have been downloaded in the past. We may still use data we collected up to that point, but will stop collecting new data. `,
  CookiesBrowser: `MANAGING COOKIES CHOICES IN YOUR BROWSER`,
  BrowserDelete: `You can turn off or delete cookies in your browser. If you do this, it may affect sites that use similar cookies to us.  `,
  BrowserReplace: `Cookies choices you set in your browser replace any you set on a single website. For instance, if you run an ad blocker on your browser, you won't see ads that are tailored to you. This will still be true, even if you have turned on marketing cookies.  `,
  BrowserManage: `Find out how to manage cookies in common browsers (Internet Explorer, Chrome, Firefox and Safari) on the <a href="https://ico.org.uk/" target="_blank">Information Commissioners’ Office (ICO) website.</a>`,
  EmailTrackingHeader: `EMAIL TRACKING OR SIMILAR TRACKING TECHNOLOGIES`,
  EmailTrackingSub: `This section explains more about the technologies we use to review how you interact with emails we send you. It explains why we do this, and how you can stop it. `,
  EmailTrackingTxt: `We review how you interact with emails we send you by using small images that you cannot see called ‘pixels’ within our emails. These pixels allow us to capture some information about emails we’ve sent you, such as: `,
  EmailTrackingList: `When you opened the email,`,
  EmailTrackingListHow: `How many times you opened it, and`,
  EmailTrackingListOpen: `The device you used to open it.  `,
  EmailPixel: `This is called ‘email tracking’. We use this information to help us understand how effective our emails are, and to improve our communications with you. The pixel stays in the email, but leaves nothing else on your device. `,
  StopEmailHeading: `HOW TO STOP EMAIL TRACKING`,
  StopBy: `You can stop this by:`,
  StopClose: `Closing the email before you download any images.`,
  StopRestrict: `Setting your browser or email program to restrict or block our emails.`,
  StopBrowser: `Sometimes your browser or email program setting will automatically download images. For more details on this, you’ll need to read the instructions for your browser, email program or device. `,
  TrackingMarketing: `HOW TO STOP EMAIL TRACKING FOR MARKETING PURPOSES`,
  MarketingTxt: `You can stop this by opting out from marketing emails by reviewing your marketing choices.`,
  MarketingSubTxt: `You can review your marketing choices in a few ways:`,
  MarketingInternet: `Use your internet or mobile banking applications, `,
  MarketingBranch: `Visit one of our branches, or `,
  MarketingContact: `See the ‘How to contact us’ section in our Data Privacy Notice to speak to us about this`,
  EmailLinkHeading: `EMAIL LINK TRACKING`,
  EmailLinkTxt: `When we send you emails including links to our web pages, we track the Lloyds Banking Group web pages you visit when using those links. We’ll only do this if you accept Marketing Cookies when you enter our site. We do not track any other non-Lloyds Banking Group sites you may also visit. `,
  StopEmailLink: `HOW TO STOP EMAIL LINK TRACKING`,
  StopEmailTxt: `You can stop this anytime by visiting our Cookie Policy and rejecting Marketing Cookies. `,
  // Later down the page
  CookieEssentialHeading: 'ESSENTIAL COOKIES AND COOKIES YOU CAN CHOOSE ',
  CookieEssentialContent: `Different types of cookies do different jobs on our website. Some are needed to make the website work. We need your consent to use others that are not essential. You can change your choices at any time. Just click the 'Cookies' link at the end of any page.  `,
  CookieCategoryHeading: 'CATEGORY',
  CookieStrictlyNecessary: 'Strictly necessary',
  CookieWhatTheyDoHeading: 'WHAT THEY DO',
  CookieWhatTheyDoContent:
    'These cookies are needed to run our website, to keep it secure if you are logged on and to obey regulations that apply to us.',
  CookieDetailsSafe:
    'If you are a customer, they help us know who you are so that you can log on and manage your accounts. They also help us keep your details safe and private. ',
  CookieOtherJobs: 'Other important jobs they do are:',
  CookieHelpMove: 'Help you move around the site',
  CookieListTellUs: `Tell us if you've been to it before and which pages you went to`,
  CookieListProblemFix:
    'Tell us how the site is working, so we can find and fix any problems. ',
  CookieMyChoicesHeading: 'MY CHOICES',
  CookieMyChoiceContent: `You can't turn off these cookies`,
  CookieFunctionalTitle: 'Functional',
  CookieRememberTitle: 'These cookies are used for remembering things like:',
  CookieListId: 'Your user ID on the log on page',
  CookieListCountry: 'Your region or country',
  CookieListLanguage: 'Your preferred language ',
  CookieListAccessibility:
    'Accessibility options like large font or high contrast pages.',
  CookieCantTurnOFF: `You can't turn off these cookies`,
  CookiePerformanceTitle: 'Performance',
  CookieStudyTitle:
    'These cookies tell us how you and our other customers use our website. We combine all this data together and study it. This helps us to:',
  CookieImprovePerformance: 'Improve the performance of our services',
  CookieImproveProducts: 'Improve the products we provide.',
  CookieConsentTitle: `We'll ask for your consent to use these cookies`,
  CookieMarketingTitle: 'Marketing and tailored advertising',
  CookieDecideTitle:
    'These cookies help us understand which of our products, services and offers may be relevant for you. They also help us tailor and measure how effective our ads are on other websites, social media, apps and devices, like Smart TVs.',
  CookieUseDataTitle:
    'If you provide consent, we may also share your phone number and email address with selected partners. When we share your information this way, we protect it by changing its characteristics to random letters and numbers: a ‘key’. Selected partners can only identify that this is your information if you already hold an account with them, and they hold the same key. We do this so we can show you ads on other selected partners’ platforms. ',
  CookieOnlineAds:
    'If you turn off marketing cookies, you’ll still see our ads in your online services, but these will be tailored only by what we already know about you. ',
  CookieChoice: `You can turn off marketing cookies in the data consents section of your mobile app, or on our website by visiting the Cookie policy page. If you turn off marketing cookies and any use of your information to tailor marketing to you, you’ll still see our ads online, but they may not be for things that interest you. `,
  goBackLink: 'Go back',
};

export default cookiePolicy;
