import React, { ReactElement, useEffect, useRef } from 'react';

import { Link, List, ListItem } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { HelpCentreContent, HelpCentreProps } from './helpCentre.config';
import {
  StyledBox,
  StyledHeading,
  StyledHr,
  StyledText,
  StyleSubHeading,
  StyledVerticalHr,
  StyledListDiv,
  StyledInnerDiv,
  StyledWriteUsHr,
  StyledBottomHr,
  StyledGoBack,
  StyledContainer,
} from './helpCentre.styled';
import BulletIcon from '../../assets/icons/bulletIcon';
import MailIcon from '../../assets/icons/mailIcon';
import PhoneIcon from '../../assets/icons/phoneIcon';
import HelpCentreQns from '../../components/helpCentre/helpCentreQns';
import LinkButton from '../../components/linkButton/LinkButton';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import { RootState } from '../../store';

export default function HelpCentre({
  setCreateAccountFAQ,
  createAccountFAQ,
}: HelpCentreProps): ReactElement {
  const theme: any = useTheme();
  const {
    headingLogin,
    headingReg,
    headingMoreHelp,
    moreHelpContent,
    headingTalkToUs,
    talkToUsNum,
    talkToUsTime,
    talkToUsSupport,
    headingWriteTous,
    talkToUsContent,
    writeToBrand,
    writeToStreet,
    writeToCity,
    writeToCode,
    writeToUsContent,
    goBack,
    helpCentreLoginQns,
    helpCentreRegistrationQns,
  } = useContent<HelpCentreContent>();

  const handleOnNavigateBack = () => {
    window.history.back();
    setCreateAccountFAQ(false);
  };
  const scrollToCreateAcc = useRef(null);
  const { isWss } = useSelector((state: RootState) => state.TogglerReducer);
  const isDeviceFound = useDeviceResizing();
  useEffect(() => {
    if (createAccountFAQ) {
      const target = scrollToCreateAcc.current.getBoundingClientRect();
      window.scrollTo({
        top: window.scrollY + target.top - 100,
        behavior: 'smooth',
      });
    }
  });
  return (
    <StyledContainer lg={3.9} md={6} className={isWss ? 'wssTheme' : 'BHTheme'}>
      <StyledBox marginBottom="07" marginTop="07">
        <StyledInnerDiv
          className={isDeviceFound.mobile ? 'mobileFound' : 'notMobile'}
        >
          <StyledHeading data-testid="loginHeading" size="s4">
            {headingLogin}
          </StyledHeading>
          <HelpCentreQns helpCentreQns={helpCentreLoginQns} />
        </StyledInnerDiv>
        <StyledHr />
        <StyledInnerDiv
          className={isDeviceFound.mobile ? 'mobileFound' : 'notMobile'}
        >
          <StyledHeading size="s4" ref={scrollToCreateAcc}>
            {headingReg}
          </StyledHeading>
          <HelpCentreQns helpCentreQns={helpCentreRegistrationQns} />
        </StyledInnerDiv>
        <StyledHr />
        <StyledInnerDiv
          className={isDeviceFound.mobile ? 'mobileFound' : 'notMobile'}
        >
          <StyleSubHeading size="s4">{headingMoreHelp}</StyleSubHeading>
          <StyledText size="s2">{moreHelpContent}</StyledText>
          <StyledVerticalHr />
          <StyleSubHeading size="s3">{headingTalkToUs}</StyleSubHeading>
          <StyledListDiv>
            <List>
              <ListItem
                icon={<PhoneIcon colour={theme.color_text_inverse_default_1} />}
              >
                <Link
                  className="phoneNumber"
                  href={`tel:${talkToUsNum}`}
                  data-qa-id="qwq"
                >
                  {talkToUsNum}
                </Link>
              </ListItem>
              <ListItem
                icon={
                  <BulletIcon colour={theme.color_text_inverse_default_1} />
                }
              >
                {talkToUsTime}
              </ListItem>
              <ListItem
                icon={
                  <BulletIcon colour={theme.color_text_inverse_default_1} />
                }
              >
                {talkToUsSupport}
              </ListItem>
            </List>
            <StyledText size="s1">{talkToUsContent}</StyledText>
          </StyledListDiv>
          <StyledWriteUsHr />
          <StyleSubHeading size="s3">{headingWriteTous}</StyleSubHeading>
          <StyledListDiv>
            <List>
              <ListItem icon={<MailIcon colour="#202020" />}>
                {writeToBrand}
                <br />
                {writeToStreet}
                <br />
                {writeToCity}
                <br />
                {writeToCode}
              </ListItem>
            </List>
            <StyledText size="s1">{writeToUsContent}</StyledText>
          </StyledListDiv>
        </StyledInnerDiv>
        <StyledBottomHr />
        <StyledGoBack>
          <LinkButton
            handleButtonClick={handleOnNavigateBack}
            testId="goBackLink"
          >
            {goBack}
          </LinkButton>
        </StyledGoBack>
      </StyledBox>
    </StyledContainer>
  );
}
