import React, { ChangeEvent, ReactElement, useRef, useState } from 'react';

import {
  Button,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useTheme } from 'styled-components';

import { RegistrationContent, SetupPasswordProps } from './Registration.config';
import CircleIcon from '../../assets/icons/circleIcon';
import TickIcon from '../../assets/icons/tickIcon';
import {
  StyleIconBox,
  StyledBtnBox,
  StyledConstrainBox,
} from '../../components/appConfig/common.styled';
import PasswordField from '../../components/passwordField/passwordField';
import {
  PASSWORD_ADD_PATTERN,
  passwordCharacters,
  passwordPattern,
} from '../../constants/REGEX';
import dataQaIds from '../../dataModel/dataQaIds';
import { hasRepeatedCharacters } from '../../utils/hasRepeatedCharacters';
import { isEmptyString } from '../../utils/isEmptyString';

export default function SetupPassword({
  handleStepper,
  isForgotPassword,
  handleOnPassword,
}: SetupPasswordProps): ReactElement {
  const {
    passwordConstrain,
    emptyFieldErrorMessage,
    createAccountButtonLabel,
    confirmPasswordFieldLabel,
    constrainErrorMessage,
    specialCharErrorMessage,
    passwordNotMatchErrorMessage,
    createYourAccountLabel,
    createPasswordSubText,
    setPasswordFieldLabel,
    createPasswordFieldLabel,
    createPasswordConstrain,
    continueLabel,
    repeatedCharactersErrorMessage,
  } = useContent<RegistrationContent>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();
  const [password, setPassword] = useState('');
  const confirmPasswordRef = useRef(null);
  const [createFieldErrorMsg, setCreateFieldErrorMsg] = useState('');
  const [confirmFieldErrorMsg, setConfirmFieldErrorMsg] = useState('');
  const [showBox, setShowBox] = useState(!isForgotPassword);
  const [dataArray, setDataArray] = useState(
    passwordConstrain.map((items) => ({
      ...items,
      icon: <CircleIcon colour={theme.color_icon_default_2} />,
    })),
  );
  const dataPattern = new RegExp(PASSWORD_ADD_PATTERN, 'i');
  const passwordValidation = (entries: string): object => {
    const result = {};
    Object.entries(passwordPattern).forEach(([constrain, pattern]) => {
      result[constrain] = new RegExp(pattern).test(entries);
    });
    return result;
  };
  const handleFieldName = (txtFieldName: string): boolean =>
    txtFieldName === 'createPassword';
  const handleOnPCSetErrMsg = (value: string, name: string): void => {
    if (handleFieldName(name)) {
      setCreateFieldErrorMsg(value);
    } else setConfirmFieldErrorMsg(value);
  };

  const handlePCChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = event.target;
    if (handleFieldName(name)) {
      setDataArray((item) =>
        item.map((icon) => ({
          ...icon,
          icon: passwordValidation(value)[icon.validEntry] ? (
            <TickIcon colour={theme.color_icon_success} />
          ) : (
            <CircleIcon colour={theme.color_icon_default_2} />
          ),
        })),
      );
      setShowBox(true);
      setPassword(value);
      handleOnPassword(value);
    }
    handleOnPCSetErrMsg('', name);
  };
  const handleOnCreateFieldErrMsg = (value: string, name: string): void => {
    const isPatternMatched = Object.values(passwordValidation(value)).some(
      (isValidPassword) => isValidPassword === false,
    );
    if (isEmptyString(value)) {
      handleOnPCSetErrMsg(emptyFieldErrorMessage, name);
    } else if (dataPattern.test(value))
      handleOnPCSetErrMsg(constrainErrorMessage, name);
    else if (hasRepeatedCharacters(value))
      handleOnPCSetErrMsg(repeatedCharactersErrorMessage, name);
    else if (isPatternMatched || !new RegExp(passwordCharacters).test(value)) {
      handleOnPCSetErrMsg(
        new RegExp(passwordCharacters).test(value)
          ? constrainErrorMessage
          : specialCharErrorMessage,
        name,
      );
    } else if (isEmptyString(createFieldErrorMsg)) {
      setShowBox(false);
    }
  };
  const handleOnConfirmFieldErrMsg = (value: string, name: string): void => {
    if (isEmptyString(value)) {
      handleOnPCSetErrMsg(emptyFieldErrorMessage, name);
    } else if (value !== password) {
      handleOnPCSetErrMsg(passwordNotMatchErrorMessage, name);
    }
  };
  const handleBlurEvt = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ): void => {
    const { value, name } = event.target;
    if (handleFieldName(name)) {
      handleOnCreateFieldErrMsg(value, name);
    } else {
      handleOnConfirmFieldErrMsg(value, name);
    }
  };
  const handleOnEmptyField = (): boolean => {
    if (isEmptyString(password)) {
      setCreateFieldErrorMsg(emptyFieldErrorMessage);
    }
    if (isEmptyString(confirmPasswordRef.current.value)) {
      setConfirmFieldErrorMsg(emptyFieldErrorMessage);
    } else if (confirmPasswordRef.current.value !== password) {
      setConfirmFieldErrorMsg(passwordNotMatchErrorMessage);
    } else if (
      isEmptyString(confirmFieldErrorMsg) &&
      isEmptyString(createFieldErrorMsg)
    ) {
      return true;
    }
    return false;
  };
  const handleOnCreateAccount = (): void => {
    if (handleOnEmptyField()) {
      handleStepper();
    }
  };
  return (
    <GridItem>
      {!isForgotPassword && (
        <>
          <Heading size="s4" marginBottom="02" aria-level={3}>
            {createYourAccountLabel}
          </Heading>
          <Text size="s2">{createPasswordSubText}</Text>
        </>
      )}
      <Grid>
        <GridItem lg={8} md={8}>
          <PasswordField
            label={
              isForgotPassword
                ? setPasswordFieldLabel
                : createPasswordFieldLabel
            }
            name="createPassword"
            testId="createPassword"
            onChange={handlePCChange}
            onBlur={handleBlurEvt}
            error={createFieldErrorMsg}
            inputWidth="fluid"
            fieldMarginTop={isForgotPassword ? 'none' : '05'}
            dataQaId={dataQaIds.loginAndRegistration.createPasswordField}
            fieldMarginBottom={showBox ? 'none' : '05'}
          />
          {showBox && (
            <StyledConstrainBox
              bgColor="none"
              marginBottom="05"
              marginTop="03"
              data-testid="constrainBox"
            >
              <ContentGroup marginBottom="05">
                <Text weight="bold" size="s2">
                  {createPasswordConstrain}
                </Text>
              </ContentGroup>
              {dataArray.map((items, index) => (
                <StyleIconBox key={`constrain_${index + 1}`}>
                  {items.icon}
                  <Text size="s2" marginLeft="04">
                    {dataArray[index].constrain}
                  </Text>
                </StyleIconBox>
              ))}
            </StyledConstrainBox>
          )}
          <PasswordField
            label={confirmPasswordFieldLabel}
            name="confirmPassword"
            testId="confirmPassword"
            onBlur={handleBlurEvt}
            onChange={handlePCChange}
            inputRef={confirmPasswordRef}
            error={confirmFieldErrorMsg}
            inputWidth="fluid"
            dataQaId={dataQaIds.loginAndRegistration.confirmPasswordField}
            fieldMarginBottom="07"
          />
        </GridItem>
      </Grid>
      <StyledBtnBox>
        <Button
          data-testid="createAccbtn"
          onClick={handleOnCreateAccount}
          data-qa-id={dataQaIds.loginAndRegistration.createAccBtn}
        >
          {isForgotPassword ? continueLabel : createAccountButtonLabel}
        </Button>
      </StyledBtnBox>
    </GridItem>
  );
}
