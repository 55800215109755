export interface IUtilsReducer {
  showSpinner: boolean;
}

export interface IUtilsShowHideAction extends IUtilsReducer {
  type: string;
}

export const ShowHideSpinnerAction = (
  showSpinner: boolean,
): IUtilsShowHideAction => ({
  type: 'SHOW_HIDE_SPINNER',
  showSpinner,
});
