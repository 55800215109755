/* eslint-disable sonarjs/cognitive-complexity */
import React, { ReactElement, useState } from 'react';

import {
  Button,
  ContentGroup,
  GridItem,
  Heading,
  Spinner,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { authSmsOtp } from '../../apis/authSMSOTPApi';
import { authUpdateEmailAPI } from '../../apis/authUpdateEmailAPI';
import { authZAPI } from '../../apis/authZApi';
import { authZMockApi } from '../../apis/authZMockApi';
import { authZTokenAPI } from '../../apis/authZTokenApi';
import { createPassword } from '../../apis/createPasswordApi';
import { getCustomerAPI } from '../../apis/getCustomerAPI';
import SuccessIcon from '../../assets/icons/successIcon';
import dataQaIds from '../../dataModel/dataQaIds';
import * as routes from '../../routes/manifest';
import {
  OtpAuthenticateProps,
  RegistrationContent,
} from '../../routes/registration/Registration.config';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import { forgotEmailAction } from '../../store/action/login.action';
import { UpdateEmailAPIAction } from '../../store/action/updateEmail.action';
import { challenge, verifier } from '../../utils/codeGenerator';
import { setCookie } from '../../utils/cookie';
import { passwordExpiryDateFormat } from '../../utils/dateFormat';
import { isPre } from '../../utils/handleEnvVariables';
import { isEmptyString } from '../../utils/isEmptyString';
import { StyledButtonsDiv } from '../appConfig/common.styled';
import DataModal from '../dataModal/dataModal';
import LinkButton from '../linkButton/LinkButton';
import OTPField from '../otpField/otpField';

export default function OTPAuthentication({
  isForgotEmail,
  sessionId,
  expiryTime,
  phoneNumber,
  mtaCustomerId,
  handleSendOtp,
  isForgetPassword,
  handleStepper,
  brand,
  password,
  handleSessionId,
  handleAuthToken,
  setForgotCredentialSelector,
}: OtpAuthenticateProps): ReactElement {
  const [otpInputs, setOtpInputs] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isValidOtp, setIsValidOtp] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [email, setEmail] = useState('');
  const {
    invalidPasscodeErrorMessage,
    expiredPasscodeErrorMessage,
    verifyText,
    sendPasscodeText,
    passcodeFieldLabel,
    resendPasscodeButton,
    dataModalHeading,
    dataModalText,
    forgotEmailDataModalButtonText,
    forgotEmailDataModalDescription,
    forgotEmailDataModalHeading,
    continueLabel,
    passcodeExpiredApiErrMessage,
    authzFailureErrorMsg,
    passcodeExpiryTimeText,
    passcodeExpiryMinutes,
  } = useContent<RegistrationContent>();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();
  const navigate = useNavigate();
  const customerDetails = useSelector((state: RootState) => state.LoginReducer);
  const [authToken, setAuthToken] = useState('');
  const { isUpdateEmail } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const handleEncoding = (): string =>
    btoa(`auth_token=${authToken}&a=1&email=${email}`);
  const handleNavigateLogin = (): void => {
    dispatch(DataModelAction(false));
    if (isForgotEmail) {
      setForgotCredentialSelector(false);
      navigate(routes.LoginWss);
    } else {
      navigate(`${routes.Marketingpreferences}?${handleEncoding()}`);
    }
  };

  const handleErrorMessge = (
    error: [
      {
        authStatus: string;
        message: string;
      },
    ],
  ): void => {
    handleSessionId(error[0].message);
    if (
      error[0].authStatus === 'PREVIOUS_OTP' ||
      error[0].authStatus === 'OTP_INVALID' ||
      error[0].authStatus === 'AUTHENTICATION_FAILURE'
    ) {
      setErrorMsg(invalidPasscodeErrorMessage);
      setIsValidOtp(false);
    }
    if (
      error[0].authStatus === 'OTP_EXPIRED' ||
      error[0].message === passcodeExpiredApiErrMessage
    ) {
      setErrorMsg(expiredPasscodeErrorMessage);
      setIsValidOtp(false);
    }
  };
  const handleOnCreatePassword = (
    contractId: string,
    emailID: string,
    bpid: string,
    accessToken: string,
  ) => {
    setShowSpinner(true);
    createPassword(
      mtaCustomerId,
      password,
      parseInt(contractId, 10),
      emailID,
      parseInt(bpid, 10),
      { Authorization: `Bearer ${accessToken}` },
    )
      .then(() => {
        setIsValidOtp(true);
        dispatch(DataModelAction(true));
        setShowSpinner(false);
      })
      .catch(() => {
        setShowSpinner(false);
      });
  };
  const getEmail = (accessToken: string) => {
    setShowSpinner(true);
    getCustomerAPI(mtaCustomerId, { Authorization: `Bearer ${accessToken}` })
      .then(
        (
          res: {
            emailAddress: string;
            contractId: string;
            businessPartnerId: string;
          }[],
        ) => {
          if (!isForgetPassword && !isForgotEmail) {
            handleOnCreatePassword(
              res[0].contractId,
              res[0].emailAddress,
              res[0].businessPartnerId,
              accessToken,
            );
          } else {
            setShowSpinner(false);
            setIsValidOtp(true);
          }
          if (isForgotEmail) dispatch(DataModelAction(true));
          setEmail(res[0].emailAddress);
          dispatch(forgotEmailAction(res[0].emailAddress));
        },
      )
      .catch(() => {
        setShowSpinner(false);
      });
  };
  const updateEmail = (accessToken: string): void => {
    setShowSpinner(true);
    authUpdateEmailAPI(mtaCustomerId, customerDetails.emailAddress, {
      Authorization: `Bearer ${accessToken}`,
    })
      .then(() => {
        dispatch(UpdateEmailAPIAction(false));
        getEmail(accessToken);
      })
      .catch(() => {
        setShowSpinner(false);
      });
  };
  const handleAuthorization = (response) => {
    const dateExpiry = new Date().getTime() + 30 * 60000;

    authZAPI(response.accessToken, challenge)
      .then((res: { authorization_code: string }) => {
        authZTokenAPI(res.authorization_code, verifier)
          .then((auth: { access_token: string; refresh_token: string }) => {
            setShowSpinner(false);
            setAuthToken(auth.access_token);
            setCookie('refreshToken', auth.refresh_token, dateExpiry);
            setCookie('authCode', res.authorization_code, dateExpiry);
            setCookie('verifier', verifier, dateExpiry);
            if (
              !isEmptyString(auth.access_token) &&
              !isForgetPassword &&
              !isUpdateEmail
            ) {
              getEmail(auth.access_token);
            }
            if (
              !isEmptyString(auth.access_token) &&
              !isForgetPassword &&
              isUpdateEmail
            ) {
              updateEmail(auth.access_token);
            }
            if (isForgetPassword) {
              setIsValidOtp(true);
              handleAuthToken(auth.access_token);
              handleStepper();
            }
          })
          .catch(() => {
            setShowSpinner(false);
            setErrorMsg(authzFailureErrorMsg);
          });
      })
      .catch(() => {
        setShowSpinner(false);
        setErrorMsg(authzFailureErrorMsg);
      });
  };

  const handleOtpValidation = (otpValue: string): void => {
    setIsValidOtp(false);
    setShowSpinner(true);
    authSmsOtp(sessionId, otpValue)
      .then((res: { accessToken: string }) => {
        if (isPre) {
          const token = JSON.parse(atob(res.accessToken.split('.')[1]));
          authZMockApi(token.sub.replace('+', '%2B'))
            .then((response: any) => {
              setShowSpinner(false);
              setAuthToken(response);
              if (
                !isEmptyString(response) &&
                !isForgetPassword &&
                !isUpdateEmail
              ) {
                getEmail(response);
              }
              if (
                !isEmptyString(response) &&
                !isForgetPassword &&
                isUpdateEmail
              ) {
                updateEmail(response);
              }
              if (isForgetPassword) {
                setIsValidOtp(true);
                handleAuthToken(response);
                handleStepper();
              }
            })
            .catch(() => {
              setShowSpinner(false);
              setErrorMsg(authzFailureErrorMsg);
            });
        } else handleAuthorization(res);
      })
      .catch((error) => {
        setShowSpinner(false);
        handleErrorMessge(error.response.data[0].errors.errors);
      });
  };
  const handleOnEmptyFields = (isFieldError: boolean): void => {
    if (isFieldError) setErrorMsg(invalidPasscodeErrorMessage);
  };
  const handleOtpValues = (value: string): void => {
    setOtpInputs(value);
    setErrorMsg('');
  };
  const handleOnClick = (): void => {
    if (
      otpInputs.length === 6 &&
      !isEmptyString(otpInputs) &&
      isEmptyString(errorMsg)
    )
      handleOtpValidation(otpInputs);
    else setErrorMsg(invalidPasscodeErrorMessage);
  };
  return (
    <>
      <GridItem>
        <Heading size="s4" marginBottom="02" aria-level={3}>
          {verifyText}
        </Heading>
        <Text size="s2">
          {sendPasscodeText}
          {phoneNumber}
        </Text>
        <br />
        <ContentGroup marginTop="02" marginBottom="none">
          <Text size="s1">
            {passcodeExpiryTimeText}
            {passwordExpiryDateFormat(expiryTime)}
            {passcodeExpiryMinutes}
          </Text>
        </ContentGroup>
        {showSpinner ? (
          <ContentGroup marginBottom="07" marginTop="05">
            <Spinner />
          </ContentGroup>
        ) : (
          <OTPField
            label={passcodeFieldLabel}
            name="otp"
            fieldMarginTop="05"
            fieldMarginBottom="07"
            handleOtpValues={handleOtpValues}
            numOfValues={6}
            otpValues={otpInputs}
            error={errorMsg}
            testId="otpField"
            handleOnEmptyFields={handleOnEmptyFields}
            dataQaId={dataQaIds.loginAndRegistration.passcodeField}
          />
        )}
        <StyledButtonsDiv>
          <LinkButton
            handleButtonClick={handleSendOtp}
            testId="resendPasscode"
            dataQaId={dataQaIds.loginAndRegistration.resendPasscodeBtn}
          >
            {resendPasscodeButton}
          </LinkButton>
          <Button
            onClick={handleOnClick}
            data-testid="continueBtn"
            data-qa-id={dataQaIds.loginAndRegistration.continueBtn}
          >
            {continueLabel}
          </Button>
        </StyledButtonsDiv>
      </GridItem>
      {isValidOtp && !isForgetPassword && !isForgotEmail && (
        <DataModal
          brand={brand}
          onClick={() => handleNavigateLogin()}
          closeIcon={false}
          icon={<SuccessIcon colour={theme.color_text_default_1} />}
          description={dataModalText}
          heading={dataModalHeading}
          buttonTxt={continueLabel}
          dataQaId={{
            btn: dataQaIds.loginAndRegistration.regcompleteBtn,
          }}
        />
      )}
      {isForgotEmail && isValidOtp && (
        <DataModal
          brand={brand}
          onClick={() => handleNavigateLogin()}
          closeIcon={false}
          icon={<SuccessIcon colour={theme.color_text_default_1} />}
          description={`${forgotEmailDataModalDescription} ${customerDetails.emailAddress}`}
          heading={forgotEmailDataModalHeading}
          buttonTxt={forgotEmailDataModalButtonText}
          dataQaId={{
            btn: dataQaIds.loginAndRegistration.regcompleteBtn,
          }}
        />
      )}
    </>
  );
}
