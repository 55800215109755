import { ReactElement, useEffect } from 'react';

import { useLocation } from '@interstellar/react-app-routing';

export default function ScrollToTop(): ReactElement {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
