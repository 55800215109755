import loginAndRegistrationWss from './loginAndRegistrationWss';
import { AppContent } from '../AppContent';

const registration: AppContent['registration'] = {
  yourDetailsHeading: 'Your details',
  credentialsHeading: 'Credentials',
  authenticateHeading: 'Authenticate',
  createYourAccountLabel: 'Create your account',
  sendPasscodeLabel: 'Send passcode',
  apiErrorMessage: 'Request failed with status code 404',
  changeEmailLabel: 'Change email address',
  howUseEmailLabel: 'How we’ll use your email ',
  yourDataLabel: 'Your data',
  numberNotRightLabel: 'If this number doesn’t look right, please call us.',
  emailErrorMessage: 'Please enter a valid email address.',
  credentialsCreateAccountLabel: 'Create an account',
  changeEmailSubText:
    'Please enter the email address you’d like to use for your online account.',
  confirmEmailErrorMessage: `This doesn't match.`,
  emailLabel: 'What’s your email?',
  confirmEmailLabel: 'Confirm email',
  invalidPasscodeErrorMessage: 'This passcode doesn’t look right. ',
  expiredPasscodeErrorMessage: 'This passcode has expired.',
  authzFailureErrorMsg: 'Sorry, something went wrong. Please try again.',
  verifyText: 'Verify it’s you',
  sendPasscodeText: 'We’ve sent a verification passcode to your mobile number ',
  passcodeExpiryTimeText: 'Your code expires in ',
  passcodeExpiryMinutes: ' minutes.',
  passcodeFieldLabel: 'Enter your passcode',
  resendPasscodeButton: 'Resend passcode',
  dataModalText:
    'You can now log in to your account anytime to manage your car finance.',
  dataModalHeading: 'Online account successfully created',
  forgotEmailDataModalDescription:
    'Please note the email address linked to this account:',
  forgotEmailDataModalHeading: 'Identity verified',
  forgotEmailDataModalButtonText: 'Log in',
  cantVerifyNumberLabel: 'We can’t verify you using your mobile number',
  authSubText:
    'To verify your account, we’ll send a passcode to your registered mobile number:',
  pleaseCallUsLabel:
    'Please call us, so we can verify you using a different method.',
  credentialsConfirmEmailLabel:
    'Is the following email address the one you’d like to use for your online account?',
  noContactFoundHeading: 'Missing information',
  noContactFoundDescription: `We haven't been able to find your contact details. Don’t worry, please call us so our team can help.`,
  noAgreementFoundHeading: 'Your agreement is being processed',
  noCustomerRecordFoundHeading: 'Unable to find your details',
  noCustomerRecordFoundDescription:
    'This is because the incorrect details have been entered or you have not yet signed an agreement.',
  backToHomeButton: 'Go back to homepage',
  dateErrorMessageLabel: 'Please enter a valid date of birth',
  enterInfoLabel:
    'Please enter the information so we can find your Black Horse finance agreement.',
  whatIsDobLabel: 'What’s your date of birth?',
  whatIsPostcodeLabel: 'What’s your postcode?',
  passwordConstrain: [
    {
      validEntry: 'containMinCharaters',
      constrain: '8-32 characters',
    },
    {
      validEntry: 'containLowerCase',
      constrain: '1 lowercase letter',
    },
    {
      validEntry: 'containUpperCase',
      constrain: '1 uppercase letter',
    },
    {
      validEntry: 'containNumber',
      constrain: '1 number',
    },
    {
      validEntry: 'containSpecialCharacter',
      constrain: '1 special character',
    },
  ],
  emptyFieldErrorMessage: 'Please fill this in.',
  createAccountButtonLabel: 'Create account',
  confirmPasswordFieldLabel: 'Confirm password',
  constrainErrorMessage:
    'Your password doesn’t meet the requirements. Please try again.',
  specialCharErrorMessage:
    'We don’t  support that special character, please try again.',
  passwordNotMatchErrorMessage: 'Your new password doesn’t match.',
  createPasswordSubText: 'Create a strong password for your account.',
  setPasswordFieldLabel: 'New password',
  createPasswordFieldLabel: 'Create password',
  createPasswordConstrain: 'Your new password must include at least:',
  whatDoYouNeedHelpWithLabel: 'What do you need help with?',
  forgotPasswordLabel: 'Forgot password',
  forgotEmailLabel: 'Forgot email address',
  emailAlreadyUsed: 'The email address is already in use.',
  emailApiErrMsg: 'Email already in use in the MTA DB',
  repeatedCharactersErrorMessage:
    'Your password cannot contain the same character twice in a row.',
  passcodeExpiredApiErrMessage: 'Authentication Error. Authentication timeout',
  noContactFoundApiError: 'Mobile Number Not Found',
  wssErrorMessage:
    'Sorry the application is undergoing maintenance, please try again later',
  ...loginAndRegistrationWss,
};

export default registration;
