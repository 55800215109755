import React, { ReactElement } from 'react';

export default function padlockIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M3.53572 17.998C3.18214 17.998 2.87947 17.8722 2.62768 17.6204C2.37589 17.3686 2.25 17.0659 2.25 16.7123V7.41233C2.25 7.05876 2.37589 6.75608 2.62768 6.5043C2.87947 6.25251 3.18214 6.12662 3.53572 6.12662H5.03572V4.06948C5.03572 2.94305 5.43291 1.98287 6.22728 1.18894C7.02165 0.395011 7.98237 -0.00195312 9.10943 -0.00195312C10.2365 -0.00195312 11.1964 0.395011 11.9893 1.18894C12.7822 1.98287 13.1786 2.94305 13.1786 4.06948V6.12662H14.6786C15.0322 6.12662 15.3348 6.25251 15.5866 6.5043C15.8384 6.75608 15.9643 7.05876 15.9643 7.41233V16.7123C15.9643 17.0659 15.8384 17.3686 15.5866 17.6204C15.3348 17.8722 15.0322 17.998 14.6786 17.998H3.53572ZM3.53572 16.7123H14.6786V7.41233H3.53572V16.7123ZM9.11076 13.7123C9.5655 13.7123 9.95359 13.555 10.275 13.2402C10.5964 12.9255 10.7572 12.5472 10.7572 12.1052C10.7572 11.6766 10.5952 11.2873 10.2714 10.9373C9.94759 10.5873 9.5583 10.4123 9.10356 10.4123C8.64881 10.4123 8.26073 10.5873 7.9393 10.9373C7.61787 11.2873 7.45715 11.6802 7.45715 12.1159C7.45715 12.5516 7.61907 12.9266 7.9429 13.2409C8.26673 13.5552 8.65601 13.7123 9.11076 13.7123ZM6.32144 6.12662H11.8929V4.06948C11.8929 3.29566 11.6223 2.63793 11.0811 2.09627C10.5399 1.5546 9.88279 1.28376 9.10968 1.28376C8.33657 1.28376 7.67858 1.5546 7.13572 2.09627C6.59286 2.63793 6.32144 3.29566 6.32144 4.06948V6.12662Z"
        fill="#202020"
      />
    </svg>
  );
}
