import { Page } from '@constellation/core';
import styled from 'styled-components';

export const StyledPage = styled(Page)`
  position: relative;
  background: transparent !important;
  width: 100%;
`;

export const StyledGrid = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
`;
