import React, { ReactElement } from 'react';

interface ISuccessIconIconProps {
  colour: string;
}

export default function successIcon({
  colour,
}: ISuccessIconIconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
    >
      <g clipPath="url(#clip0_1207_22891)">
        <path
          d="M30.1 51.5057L55.7077 25.898L53.3385 23.5518L30.1 46.8672L18.4077 35.1749L16.1384 37.5211L30.1 51.5057ZM36.0134 71.998C31.0506 71.998 26.3908 71.0534 22.034 69.1641C17.6771 67.2748 13.8611 64.6941 10.5859 61.4219C7.31077 58.1498 4.72767 54.3374 2.8366 49.9846C0.945533 45.6319 0 40.9742 0 36.0114C0 31.0332 0.944634 26.3529 2.8339 21.9704C4.72323 17.588 7.30397 13.7758 10.5761 10.5339C13.8482 7.29215 17.6607 4.72572 22.0134 2.83465C26.3661 0.943583 31.0239 -0.00195312 35.9866 -0.00195312C40.9648 -0.00195312 45.6451 0.942679 50.0276 2.83195C54.4101 4.72128 58.2222 7.28535 61.4641 10.5241C64.7059 13.7629 67.2723 17.5715 69.1634 21.9498C71.0545 26.3282 72 31.0065 72 35.9846C72 40.9474 71.0554 45.6072 69.1661 49.964C67.2768 54.3209 64.7127 58.1369 61.4739 61.4121C58.2351 64.6873 54.4265 67.2704 50.0482 69.1615C45.6698 71.0525 40.9915 71.998 36.0134 71.998ZM36 68.9212C45.159 68.9212 52.9359 65.7198 59.3308 59.3172C65.7257 52.9147 68.9231 45.1416 68.9231 35.998C68.9231 26.839 65.7257 19.0621 59.3308 12.6672C52.9359 6.27238 45.159 3.07495 36 3.07495C26.8564 3.07495 19.0833 6.27238 12.6808 12.6672C6.2782 19.0621 3.0769 26.839 3.0769 35.998C3.0769 45.1416 6.2782 52.9147 12.6808 59.3172C19.0833 65.7198 26.8564 68.9212 36 68.9212Z"
          fill={colour}
        />
      </g>
      <defs>
        <clipPath id="clip0_1207_22891">
          <rect
            width="72"
            height="72"
            fill="white"
            transform="translate(0 -0.00195312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
