import React, { ReactElement } from 'react';

interface IInfoIconProps {
  colour: string;
}
export default function infoIcon({ colour }: IInfoIconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      aria-label="information icon"
    >
      <path
        d="M34.7624 51.9965H37.4974V32.441H34.7624V51.9965ZM35.9984 27.641C36.5328 27.641 36.9846 27.4652 37.3539 27.1138C37.7231 26.7623 37.9077 26.309 37.9077 25.7538C37.9077 25.1938 37.7236 24.7324 37.3554 24.3697C36.9872 24.007 36.5359 23.8256 36.0016 23.8256C35.4216 23.8256 34.9584 24.007 34.612 24.3697C34.2655 24.7324 34.0923 25.1938 34.0923 25.7538C34.0923 26.309 34.2764 26.7623 34.6446 27.1138C35.0128 27.4652 35.4641 27.641 35.9984 27.641ZM36.0356 67.9965C31.5807 67.9965 27.4238 67.1568 23.565 65.4775C19.7061 63.7981 16.321 61.5041 13.4097 58.5955C10.4985 55.687 8.20237 52.3029 6.52142 48.4433C4.84047 44.5836 4 40.4238 4 35.964C4 31.5497 4.83967 27.3995 6.51902 23.5134C8.19843 19.6273 10.4924 16.2434 13.401 13.3618C16.3095 10.4802 19.6936 8.19889 23.5532 6.51795C27.4129 4.837 31.5727 3.99652 36.0325 3.99652C40.4468 3.99652 44.597 4.83619 48.4831 6.51554C52.3692 8.19495 55.7531 10.4741 58.6348 13.3531C61.5164 16.232 63.7976 19.6174 65.4786 23.5092C67.1595 27.4011 68 31.5517 68 35.961C68 40.4158 67.1603 44.5727 65.481 48.4315C63.8016 52.2905 61.5224 55.6701 58.6435 58.5704C55.7645 61.4708 52.3791 63.7669 48.4873 65.4587C44.5954 67.1506 40.4448 67.9965 36.0356 67.9965ZM36.0444 65.2615C44.1562 65.2615 51.0542 62.4159 56.7385 56.7247C62.4228 51.0336 65.265 44.1094 65.265 35.9521C65.265 27.8404 62.4284 20.9424 56.7551 15.258C51.0819 9.57371 44.1636 6.73154 36 6.73154C27.8724 6.73154 20.963 9.56817 15.2718 15.2414C9.58062 20.9146 6.73502 27.833 6.73502 35.9965C6.73502 44.1242 9.58062 51.0336 15.2718 56.7247C20.963 62.4159 27.8872 65.2615 36.0444 65.2615Z"
        fill={colour}
      />
    </svg>
  );
}
