export default {
  loginAndRegistration: {
    container: 'loginAndRegistration-container',
    loginBtn: 'loginAndRegistration-loginBtn',
    createOnlineAccBtn: 'loginAndRegistration-createOnlineAccBtn',
    needHelpLink: 'loginAndRegistration-needHelpLink',
    createPasswordField: 'loginAndRegistration-createPasswordField',
    confirmPasswordField: 'loginAndRegistration-confirmPasswordField',
    noContactFoundModalBtn: 'loginAndRegistration-noContactFoundModalBtn',
    noContactFoundModalLink: 'loginAndRegistration-noContactFoundModalLink',
    noAgreementFoundModalBtn: 'loginAndRegistration-noAgreementFoundModalBtn',
    noAgreementFoundModalLink: 'loginAndRegistration-noAgreementFoundModalLink',
    noCustomerRecordFoundModalBtn:
      'loginAndRegistration-noCustomerRecordFoundModalBtn',
    noCustomerRecordFoundModalLink:
      'loginAndRegistration-noCustomerRecordFoundModalLink',
    continueBtn: 'loginAndRegistration-continueBtn',
    dateField: 'loginAndRegistration-dateField',
    postCodeField: 'loginAndRegistration-postCodeField',
    changeEmailBtn: 'loginAndRegistration-changeEmailBtn',
    confirmAndContinueBtn: 'loginAndRegistration-confirmAndContinueBtn',
    howWeEmailUseLink: 'loginAndRegistration-howWeEmailUseLink',
    createAccBtn: 'loginAndRegistration-createAccBtn',
    phoneLink: 'loginAndRegistration-phoneLink',
    regcompleteBtn: 'loginAndRegistration-regcompleteBtn',
    resendPasscodeBtn: 'loginAndRegistration-resendPasscodeBtn',
    passcodeField: 'loginAndRegistration-passcodeField',
    cancelLink: 'loginAndRegistration-cancelLink',
    cancelLinkMobile: 'loginAndRegistration-cancelLinkMobile',
    continueBtnMobile: 'loginAndRegistration-continueBtnMobile',
    othersEmailField: 'loginAndRegistration-othersEmailField',
    othersConfirmEmailField: 'loginAndRegistration-othersConfirmEmailField',
    loginCredentialsEmail: 'loginAndRegistration-loginCredentialsEmail',
    loginCredentialsPassword: 'loginAndRegistration-loginCredentialsPassword',
    loginCredentialsForgotEmailLink:
      'loginAndRegistration-loginCredentialsForgotEmailLink',
    loginCredentialsForgotPasswordLink:
      'loginAndRegistration-loginCredentialsForgotPasswordLink',
    verifyEmailField: 'loginAndRegistration-verifyEmailField',
    previousLink: 'loginAndRegistration-previousLink',
    noValidAccountStatusModalBtn:
      'loginAndRegistration-noValidAccountStatusModalBtn',
    noValidAccountStatusModalLink:
      'loginAndRegistration-noValidAccountStatusModalLink',
    noRegistrationCompletedModalBtn:
      'loginAndRegistration-noRegistrationCompletedModalBtn',
    noRegistrationCompletedModalLink:
      'loginAndRegistration-noRegistrationCompletedModalLink',
    isLockoutModalBtn: 'loginAndRegistration-isLockoutModalBtn',
    isLockoutModalLink: 'loginAndRegistration-isLockoutModalLink',
    tryAgainWssBtn: 'loginAndRegistration-tryAgainWssBtn',
    loginWssBtn: 'loginAndRegistration-loginWssBtn',
    contactUsLink: 'loginAndRegistration-contactUsLink',
    emailTooltip: 'loginAndRegistration-emailTooltip',
  },
  needhelp: {
    regContainer: 'needHelp-registration-container',
  },
  notFoundPage: {
    mainCard: 'NotFoundPage-card',
    title: 'NotFoundPage-title',
    subTitle: 'NotFoundPage-sub-title',
    content: 'NotFoundPage-content',
    homeLink: 'NotFoundPage-link',
  },
  security: {
    container: 'security-container',
    editBtn: 'security-editBtn',
    saveBtn: 'security-saveBtn',
    currentPasswordField: 'security-currentPasswordField',
    cancelBtn: 'security-cancelBtn',
  },
  footer: {
    container: 'footer-container',
    documentationLink: 'footer-documentationLink',
    termsAndCondtionLink: 'footer-termsAndCondtionLink',
    cookiePolicyLink: 'footer-cookiePolicyLink',
    privacyLink: 'footer-privacyLink',
    privacyCookieLinks: 'footer-privacy-cookieLinks',
    privacyReportLink: 'footer-privacy-ReportLink',
    privacyCallLink: 'footer-privacy-callLink',
    privacyRecordLink: 'footer-privacy-recordLink',
  },
  helpCentre: {
    container: 'help-centre-container',
    previousLink: 'helpc-centre-previous',
  },
  setMarketingPreferences: {
    savePreference: 'setMarketingPrefs-savePreference',
    skipForNoW: 'setMarketingPrefs-skipForNoW',
    backButton: 'setMarketingPref-backButton',
  },
};
