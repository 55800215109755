import React, { ReactElement } from 'react';

interface IWarningIconProps {
  colour: string;
  width?: string;
  height?: string;
}

export default function warningIcon({
  colour,
}: IWarningIconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
    >
      <g clipPath="url(#clip0_80_2896)">
        <path
          d="M0 59.2364L32 4L64 59.2364H0ZM4.5942 56.5808H59.4058L32 9.31115L4.5942 56.5808ZM32.1623 51.1302C32.6561 51.1302 33.0645 50.9632 33.3876 50.6293C33.7106 50.2953 33.8722 49.8814 33.8722 49.3877C33.8722 48.8941 33.7052 48.4857 33.3712 48.1625C33.0373 47.8395 32.6234 47.6779 32.1297 47.6779C31.636 47.6779 31.2277 47.8449 30.9046 48.1788C30.5815 48.5129 30.4199 48.9267 30.4199 49.4204C30.4199 49.914 30.5869 50.3224 30.9209 50.6456C31.2549 50.9687 31.6687 51.1302 32.1623 51.1302ZM30.8183 43.8074H33.4739V25.5368H30.8183V43.8074Z"
          fill={colour}
        />
      </g>
      <defs>
        <clipPath id="clip0_80_2896">
          <rect width="64" height="64" fill={colour} />
        </clipPath>
      </defs>
    </svg>
  );
}
