import { Box, Heading, Paragraph, Hr } from '@constellation/core';
import styled from 'styled-components';

interface ICookiePolicy {
  isSubheading?: boolean;
  isBold?: boolean;
  isPaddingLeft?: boolean;
}

export const StyledBoldedParagraph = styled(Paragraph)`
  font-weight: ${({ isBold }: ICookiePolicy) =>
    isBold ? '600' : ''} !important;
  padding-top: ${({ isSubheading }: ICookiePolicy) =>
    isSubheading ? ({ theme }) => theme.spacing_05 : 'none'} !important;
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
`;
export const StyledBoldParagraph = styled(Paragraph)`
  font-weight: 600 !important;
  padding-top: ${({ isSubheading }: ICookiePolicy) =>
    isSubheading
      ? ({ theme }) => theme.spacing_05
      : ({ theme }) => theme.spacing_04} !important;
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
`;
export const StyledBox = styled(Box)`
  background-color: ${({ theme }) =>
    theme.color_background_panel_brand_2} !important;
  padding: 0 !important;
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  a {
    color: #0f7eb1 !important;
  }
`;
export const StyledInnerDiv = styled('div')`
  padding: 1rem !important;
  padding-bottom: ${({ theme }) => theme.spacing_07}!important;
  > a {
    color: #0f7eb1 !important;
  }
  a:focus {
    background: none !important;
    box-shadow: none !important;
  }
  &.mobileFound {
    padding: 1rem !important;
  }
  &.notMobile {
    padding: 1.5rem !important;
  }
`;
export const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  padding-bottom: ${({ theme }) => theme.spacing_05}!important;
  margin-bottom: ${({ theme }) => theme.spacing_05}!important;
  border-bottom: 1px solid #adadad !important;
`;
export const StyledGoBack = styled('div')`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  button {
    padding: ${({ theme }) => theme.spacing_04} 0 !important;
  }
  > button {
    color: #0f7eb1 !important;
  }
`;
export const StyledBottomHr = styled(Hr)`
  border-color: ${({ theme }) => theme.color_borser_default_2} !important;
  margin: 0 !important;
`;
