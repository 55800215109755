import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ForgotEmailMTA from './forgotEmailMTA';
import { RootState } from '../../store';
import { changeStepAction } from '../../store/action/formStep.action';
import { DOBPostcodeContractsDataAction } from '../../store/action/registration.action';
import { dataLayer } from '../../utils/dataLayer';
import { isEmptyString } from '../../utils/isEmptyString';
import ForgotEmailWss from '../loginAndRegistrationWss/forgotEmailWss';
import { ApiAuthResponse } from '../registration/Registration.config';

import { ForgotEmailProps } from 'routes/forgotPassword/forgotPassword.config';

export default function ForgotEmail({
  brand,
  setForgotCredentialSelector,
}: ForgotEmailProps) {
  const { stepNumber } = useSelector(
    (state: RootState) => state.FormStepsReducer,
  );
  const { isWss } = useSelector((state: RootState) => state.TogglerReducer);
  const { customerData } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    window.appConfig.JOURNEY_NAME = 'Login';
    window.appConfig.JOURNEY_STEP_NAME = 'Forgot Email';
    window.appConfig.PAGE_ROLE = 'Servicing';
    dataLayer();
  }, []);

  const [mtaCustomerId, setMtaCustomerId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isContactFound, setIsContactFound] = useState(
    !isEmptyString(customerData[0].phoneNumber),
  );
  const [isCustomerIdentificationError, setIsCustomerIdentificationError] =
    useState(false);
  const numberOfSteps = 2;
  const [isForgetEmailWss, setIsForgetEmailWss] = useState(false);
  const [isMultiAgreement, setIsMultiAgreement] = useState(false);
  const isNoContactFound = (number: string): boolean => {
    if (isEmptyString(number)) {
      setIsContactFound(false);
      return true;
    }
    setIsContactFound(true);
    return false;
  };

  const handleOnStep = (isMultipleAgreement: boolean) => {
    if (!isMultipleAgreement) {
      dispatch(
        changeStepAction(
          stepNumber < numberOfSteps ? stepNumber + 1 : numberOfSteps,
        ),
      );
    }
  };

  const handleOnNoContactCondition = (number: string): void => {
    if (isNoContactFound(number)) {
      setIsCustomerIdentificationError(true);
      setIsContactFound(false);
    } else {
      setPhoneNumber(number);
      handleOnStep(isMultiAgreement);
    }
  };
  const handleCustomerIdentityError = (bool): void => {
    setIsCustomerIdentificationError(bool);
  };
  const handleMultiAgreement = (value: boolean): void =>
    setIsMultiAgreement(value);
  const handleOnModalConditions = (
    res: ApiAuthResponse[],
    { isForgotEmail },
  ): void => {
    dispatch(
      DOBPostcodeContractsDataAction(res, {
        isDetailsFound: true,
        isApiFailed: false,
        isMoreThanOneUser: false,
      }),
    );
    setIsForgetEmailWss(isForgotEmail);
    handleOnNoContactCondition(res[0].phoneNumber);
    setMtaCustomerId(res[0].mtaCustomerId);
  };
  return isWss ? (
    <ForgotEmailWss
      handleOnPassDetails={handleOnModalConditions}
      handleMultiAgreement={handleMultiAgreement}
      isMultiAgreement={isMultiAgreement}
    />
  ) : (
    <ForgotEmailMTA
      brand={brand}
      phoneNumber={phoneNumber || customerData[0].phoneNumber}
      mtaCustomerId={mtaCustomerId || customerData[0].mtaCustomerId}
      handlePassDetails={handleOnModalConditions}
      isContactFound={isContactFound}
      isCustomerIdentificationError={isCustomerIdentificationError}
      numberOfSteps={numberOfSteps}
      handleCustomerIdentityError={handleCustomerIdentityError}
      isForgotEmailWss={isForgetEmailWss}
      handleMultiAgreement={handleMultiAgreement}
      isMultiAgreement={isMultiAgreement}
      setForgotCredentialSelector={setForgotCredentialSelector}
    />
  );
}
