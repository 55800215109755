import { DateProps } from '../../components/appConfig/AppConfig';
import { ApiAuthResponse } from '../../routes/registration/Registration.config';

export interface UpdateDOBPostcodeInterface {
  type: string;
  postcode: string;
  dob: DateProps;
  resetValues?: boolean;
}

export const UpdateDOBPostcodeAction = (
  postcode: string,
  dob: DateProps,
  resetValues?: boolean,
): UpdateDOBPostcodeInterface => ({
  type: 'UPDATE_POSTCODE_DOB',
  postcode,
  dob,
  resetValues,
});

export interface DOBPostcodeContractsInterface {
  type: string;
  customerData: ApiAuthResponse[];
  constraints: {
    isDetailsFound?: boolean;
    isApiFailed?: boolean;
    isMoreThanOneUser?: boolean;
  };
}

export const DOBPostcodeContractsDataAction = (
  customerData: ApiAuthResponse[],
  constraints?: {
    isDetailsFound?: boolean;
    isApiFailed?: boolean;
    isMoreThanOneUser?: boolean;
  },
): DOBPostcodeContractsInterface => ({
  type: 'POSTCODE_DOB_DATA',
  customerData,
  constraints,
});

export interface CustomerMtaDataInterface {
  type: string;
  customerDataMta: ApiAuthResponse[];
  contracts: {
    isMTA?: boolean;
    isWSS?: boolean;
  };
}

export const CustomerMtaDataAction = (
  customerDataMta: ApiAuthResponse[],
  contracts: {
    isMTA?: boolean;
    isWSS?: boolean;
  },
): CustomerMtaDataInterface => ({
  type: 'CUSTOMER_DATA_MTA',
  customerDataMta,
  contracts,
});

export interface CustomerWssDataInterface {
  type: string;
  customerDataWss: ApiAuthResponse[];
  contracts: {
    isMTA?: boolean;
    isWSS?: boolean;
  };
}

export const CustomerWssDataAction = (
  customerDataWss: ApiAuthResponse[],
  contracts: {
    isMTA?: boolean;
    isWSS?: boolean;
  },
): CustomerWssDataInterface => ({
  type: 'CUSTOMER_DATA_WSS',
  customerDataWss,
  contracts,
});
