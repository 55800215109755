// eslint-disable-next-line import/no-extraneous-dependencies
import CryptoJS from 'crypto-js';

const codeGenerator = (words) => {
  return CryptoJS.enc.Base64.stringify(words)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};
export const verifier = codeGenerator(CryptoJS.lib.WordArray.random(50));
export const challenge = codeGenerator(CryptoJS.SHA256(verifier));
