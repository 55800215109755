import React, { ReactElement } from 'react';

export default function arrowRightIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.99094 16V14.5883H14.2986C14.371 14.5883 14.4374 14.5581 14.4978 14.4978C14.5581 14.4374 14.5883 14.371 14.5883 14.2986V1.70137C14.5883 1.62896 14.5581 1.56259 14.4978 1.50224C14.4374 1.44191 14.371 1.41175 14.2986 1.41175H7.99094V0H14.2986C14.774 0 15.1765 0.164706 15.5059 0.494119C15.8353 0.823532 16 1.22595 16 1.70137V14.2986C16 14.774 15.8353 15.1765 15.5059 15.5059C15.1765 15.8353 14.774 16 14.2986 16H7.99094ZM6.71496 12.0181L5.73762 10.9973L8.02901 8.70586H0V7.29414H8.02901L5.73762 5.00272L6.71496 3.98194L10.733 8L6.71496 12.0181Z"
        fill="#0F7EB1"
      />
    </svg>
  );
}
