import axios from 'axios';

import { ApiEndpoints } from '../constants/apiEndpoints';

const config = {
  headers: {
    'content-Type': 'application/json',
    'x-lbg-user-id': '1',
    'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
  },
};

export const authUserAPI = (userId, param, additionalConfig): Promise<object> =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${window.appConfig.REACT_APP_API_URL}${window.appConfig.REACT_APP_AUTH_PROXY}/mta/api/v1/${ApiEndpoints.AUTH_USER_UPDATE}/${userId}`,
        param,
        {
          headers: {
            ...config.headers,
            'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
            ...additionalConfig,
          },
        },
      )
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });
