import { Box } from '@constellation/core';
import styled from 'styled-components';

import { device } from '../../utils/breakpoints';

interface StyledBoxProps {
  padding?: boolean;
  isMarketingPrefs?: boolean;
}

export const StyledInlineBox = styled('div')`
  display: inline-block;
`;
export const StyledTextBox = styled(Box)`
  padding-left: ${({ theme }) => theme.spacing_none} !important;
  padding-right: ${({ theme }) => theme.spacing_none} !important;
`;

export const StyledBox = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing_07};
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.spacing_04};
  background-color: ${({ theme }) => theme.color_background_panel_default_1};
  ${({ padding }: StyledBoxProps) => {
    if (padding) {
      return `padding-bottom: ${({ theme }) => theme.spacing_05}`;
    }
    return '';
  }}

  @media ${device.md} {
    padding: ${({ theme }) => theme.spacing_05};
  }
`;

export const StyleIconBox = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing_04};
`;

export const StyledButtonsDiv = styled('div')<StyledBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  button: first-child {
    order: 2;
    margin-top: ${({ theme }) => theme.spacing_05};
    ${({ padding, theme }) =>
      padding && `padding-bottom: ${theme.spacing_02} !important`}
  }

  @media ${device.md} {
    button: first-child {
      padding: 0 !important;
      ${({ isMarketingPrefs }) =>
        isMarketingPrefs && `margin-top: 0 !important`}
    }
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    button: last-child {
      order: 2;
    }
  }
`;

export const StyledConstrainBox = styled(Box)`
  padding: ${({ theme }) => theme.spacing_04} !important;
  border-radius: ${({ theme }) => theme.spacing_02};
  border: 1px solid ${({ theme }) => theme.color_border_default_2};
  padding-bottom: 0 !important;
`;

export const StyledBtnBox = styled('div')`
  display: flex;
  button {
    margin-left: auto;
  }
`;
