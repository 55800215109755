import React, { ReactElement } from 'react';

import { ContentGroup, GridItem, Heading, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { ForgotEmailContent } from './forgotEmail.config';

export default function CustomerIdentification(): ReactElement {
  const { emailNotFoundHeading, noCustomerRecordFoundDescription } =
    useContent<ForgotEmailContent>();
  return (
    <>
      <GridItem>
        <Heading size="s4" marginBottom="02" aria-level={3}>
          {emailNotFoundHeading}
        </Heading>
      </GridItem>
      <GridItem>
        <ContentGroup marginBottom="05" marginTop="none">
          <Text size="s2">{noCustomerRecordFoundDescription}</Text>
        </ContentGroup>
      </GridItem>
    </>
  );
}
