import React, { ReactElement } from 'react';

interface ITickIconProps {
  colour: string;
  width?: string;
  height?: string;
}

export default function tickIcon({ colour }: ITickIconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M10.2073 17.3928L18.6996 8.9005L17.3684 7.56935L10.2073 14.7305L6.60728 11.1305L5.27613 12.4616L10.2073 17.3928ZM12.0021 24.0195C10.3424 24.0195 8.78236 23.7046 7.32198 23.0747C5.86157 22.4448 4.59123 21.5899 3.51096 20.5102C2.43066 19.4303 1.57543 18.1606 0.945255 16.7008C0.315085 15.2411 0 13.6814 0 12.0216C0 10.3619 0.314948 8.80189 0.944845 7.34151C1.57474 5.8811 2.42959 4.61076 3.50938 3.53049C4.58919 2.45019 5.85897 1.59496 7.31872 0.964786C8.77845 0.334616 10.3382 0.0195312 11.9979 0.0195312C13.6576 0.0195312 15.2176 0.334479 16.678 0.964376C18.1384 1.59427 19.4088 2.44912 20.489 3.52891C21.5693 4.60872 22.4246 5.8785 23.0547 7.33826C23.6849 8.79799 24 10.3577 24 12.0174C24 13.6771 23.6851 15.2372 23.0552 16.6976C22.4253 18.158 21.5704 19.4283 20.4906 20.5086C19.4108 21.5889 18.141 22.4441 16.6813 23.0743C15.2215 23.7044 13.6618 24.0195 12.0021 24.0195ZM12 22.1248C14.8211 22.1248 17.2105 21.1459 19.1684 19.188C21.1263 17.2301 22.1053 14.8406 22.1053 12.0195C22.1053 9.19847 21.1263 6.80899 19.1684 4.85109C17.2105 2.89319 14.8211 1.91424 12 1.91424C9.17894 1.91424 6.78946 2.89319 4.83156 4.85109C2.87366 6.80899 1.89471 9.19847 1.89471 12.0195C1.89471 14.8406 2.87366 17.2301 4.83156 19.188C6.78946 21.1459 9.17894 22.1248 12 22.1248Z"
        fill={colour}
      />
    </svg>
  );
}
