import { passwordPattern } from '../constants/REGEX';

const handleOnAlphabet = (alpha: string): string => {
  if (alpha === 'd') {
    return 'a';
  }
  if (alpha === 'e') {
    return 'b';
  }
  if (alpha === 'f') {
    return 'c';
  }
  return String.fromCharCode(alpha.charCodeAt(0) + 3);
};
const handleOnDigit = (digit: number): string => {
  if (digit + 3 > 9) {
    return (digit + 3 - 10).toString();
  }
  return (digit + 3).toString();
};
const replaceCharacter = (index, replacement, string) => {
  return (
    string.substring(0, index) +
    replacement +
    string.substring(index + replacement.length)
  );
};

const digitRegex = new RegExp(passwordPattern.containNumber);
const checkAlphaOrDigit = (indexValue: any) => {
  if (digitRegex.test(indexValue)) {
    return handleOnDigit(parseInt(indexValue, 10));
  }
  return handleOnAlphabet(indexValue);
};

export const handleUserId = (mtaCustId: string): string => {
  const mtaCustomerId = replaceCharacter(
    2,
    checkAlphaOrDigit(mtaCustId.charAt(2)),
    mtaCustId,
  );
  return replaceCharacter(
    mtaCustomerId.length - 3,
    checkAlphaOrDigit(mtaCustId.charAt(mtaCustomerId.length - 3)),
    mtaCustomerId,
  );
};
