import React, { ReactElement } from 'react';

import { ContentGroup, PasscodeField } from '@constellation/core';

import { OtpFieldProps } from './OtpField.config';
import { StyledPasscodeGrid } from './styledOTPFieldComponent';

export default function OTPField({
  label,
  name,
  error,
  fieldMarginBottom,
  fieldMarginLeft,
  fieldMarginRight,
  fieldMarginTop,
  testId,
  dataQaId,
  otpValues,
  handleOtpValues,
  handleOnEmptyFields,
}: OtpFieldProps): ReactElement {
  const handleOnOTPChange = (event: any): void => {
    const { value } = event.target;
    handleOtpValues(value);
  };
  const handleOnOTPBlur = (event: any): void => {
    const { value } = event.target;
    if (value.length !== 6) {
      handleOnEmptyFields(true);
    }
  };
  return (
    <ContentGroup
      marginBottom={fieldMarginBottom}
      marginLeft={fieldMarginLeft}
      marginRight={fieldMarginRight}
      marginTop={fieldMarginTop}
    >
      <StyledPasscodeGrid lg={8}>
        <PasscodeField
          name={name}
          numberOfChars={6}
          label={label}
          error={error}
          data-qa-id={`${dataQaId}`}
          data-testid={testId}
          inputWidth="fluid"
          onChange={(event: any) => handleOnOTPChange(event)}
          onBlur={(event: any) => handleOnOTPBlur(event)}
          value={otpValues}
        />
      </StyledPasscodeGrid>
    </ContentGroup>
  );
}
