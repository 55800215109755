import { getThemeFromUrl } from '../../utils/getThemeFromUrl';
import _base from '../_base';
import { AppContent } from '../AppContent';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { BRAND_NAME } = (window as any).appConfig;
const brand = getThemeFromUrl() || BRAND_NAME;

/* eslint no-param-reassign: "error" */
if (brand === 'bos') {
  _base.welcome.brandName = 'bos';
}
const content: AppContent = {
  ..._base,
  ..._base.welcome,
};
export default content;
