import React, { ReactElement } from 'react';

import { ContentGroup, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import PadlockIcon from '../../assets/icons/padlockIcon';
import ProfileIcon from '../../assets/icons/profileIcon';
import CTA from '../../components/Cta/Cta';
import {
  StyledForgotCredBox,
  StyledCtaBorderDiv,
} from '../loginAndRegistrationWss/LoginAndRegistrationWss.styled';
import * as routes from '../manifest';
import { RegistrationContent } from '../registration/Registration.config';

export function ForgotCredentialSelector(): ReactElement {
  const { forgotPasswordLabel, forgotEmailLabel, whatDoYouNeedHelpWithLabel } =
    useContent<RegistrationContent>();
  return (
    <ContentGroup marginTop="none" marginBottom="none">
      <Text size="s1">{whatDoYouNeedHelpWithLabel}</Text>
      <StyledForgotCredBox marginTop="04" marginBottom="none">
        <StyledCtaBorderDiv>
          <CTA
            title={forgotPasswordLabel}
            subTitle=""
            navigateRoute={routes.ForgotPassword}
            icon={<PadlockIcon />}
            iconPosition="top"
            titleSize="s1"
            dataQaId={undefined}
          />
        </StyledCtaBorderDiv>
        <ContentGroup marginTop="04" marginBottom="none">
          <StyledCtaBorderDiv>
            <CTA
              navigateRoute={routes.ForgotEmail}
              icon={<ProfileIcon />}
              title={forgotEmailLabel}
              subTitle=""
              iconPosition="top"
              titleSize="s1"
              dataQaId={undefined}
            />
          </StyledCtaBorderDiv>
        </ContentGroup>
      </StyledForgotCredBox>
    </ContentGroup>
  );
}
