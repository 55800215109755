import React, { ReactElement } from 'react';

import { Grid, GridItem, Heading } from '@constellation/core';
import { ContentProvider, useContent } from '@interstellar/react-app-content';
import { useTheme } from 'styled-components';

import { NeedHelpContent, NeedHelpProps } from './NeedHelp.config';
import QuestionMarkIcon from '../../assets/icons/questionMarkIcon';
import content from '../../content';
import * as routes from '../../routes/manifest';
import Cta from '../Cta/Cta';

function NeedHelpInner({
  hasHeading,
  dataQaId,
  customSubtitle,
}: NeedHelpProps): ReactElement {
  const { headerText, ctaTitle, ctaSubtitle } = useContent<NeedHelpContent>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();

  const renderComponent = () => (
    <Grid>
      <GridItem sm={12} md={12} lg={12}>
        {hasHeading && (
          <Heading
            data-testid="NeedHelpTitle"
            size="s3"
            marginBottom="04"
            marginTop="07"
            aria-level={2}
          >
            {headerText}
          </Heading>
        )}
        <Cta
          navigateRoute={routes.HelpCentre}
          icon={<QuestionMarkIcon colour={theme.color_icon_default_2} />}
          title={ctaTitle}
          subTitle={customSubtitle !== '' ? customSubtitle : ctaSubtitle}
          iconPosition="top"
          hasBottomSpacing
          dataQaId={dataQaId}
          testId="navigationLink"
        />
      </GridItem>
    </Grid>
  );
  return renderComponent();
}

export default function NeedHelp({
  hasHeading,
  dataQaId,
  brand,
  customSubtitle = '',
}: NeedHelpProps) {
  return (
    <ContentProvider value={content({ brand }).needHelp}>
      <NeedHelpInner
        hasHeading={hasHeading}
        dataQaId={dataQaId}
        customSubtitle={customSubtitle}
      />
    </ContentProvider>
  );
}
