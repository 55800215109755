import common from './common';
import { AppContent } from '../AppContent';

const marketingPreferences: AppContent['marketingPreferences'] = {
  marketingIntroText: `From time to time, we'd like to send you information about products and
    services that may interest you.`,
  listItemOne:
    'You can change your preferences at any time from your profile page.',
  listItemTwo: `We'll use the contact details you gave us when registering your
    account.`,
  listItemThree: `Whatever you choose, we'll still always send you important messages
    about your agreement.`,
  accordionLabel: 'Who may contact you',
  accordionIntroText: `By saying ‘Yes’ to your choices above, you’re giving consent to Black
    Horse to use your personal information to send you relevant offers and
    new products. Black Horse includes the following legal entitles:`,
  accordionListItemOne: 'Black Horse Limited',
  accordionListItemTwo: 'Lloyds Bank Insurance Services Ltd.',
  accordionOutroText: `Black Horse may send you carefully selected messages about products
  and offers from other companies within <a href="https://www.lloydsbankinggroup.com/who-we-are/our-brands.html" target="_blank" data-qa-id="marketingPrefs-Lloyds">Lloyds Banking Group.</a>`,
  yesLabel: 'Yes',
  noLabel: 'No',
  saveButton: 'Save',
  cancelButton: 'Cancel',
  createAccount: 'Create your account',
  marketing: 'Marketing',
  tellUs: 'Tell us how you’d like us to get in touch.',
  marketingpreferences: 'Marketing preferences',
  skipForNow: 'Skip for now',
  savePreference: 'Save preference',
  selectionNotSavedHeading: 'Selections not saved',
  selectionDescription:
    'Sorry, your selections couldn’t be saved at this time. Please skip for now, and try again later.',
  selectionButton: 'Back',
  skipDescription: `This means you won't receive any marketing from us. You can change these settings anytime from your profile page.`,
  doItLaterButton: `I’ll do it later`,
  goBackToPrefsButton: 'Back to preferences',
  perMarketingSwitches: [
    {
      label: 'Email',
      name: 'email',
      furtherInfo: '',
    },
    {
      label: 'Text message',
      name: 'textMessage',
      furtherInfo: '',
    },
    {
      label: 'Phone',
      name: 'phone',
      furtherInfo: '',
    },
    {
      label: 'Post',
      name: 'post',
      furtherInfo: '',
    },
    {
      label: 'Online account',
      name: 'onlineAccount',
      furtherInfo: `We may show you selected messages when you're logged in to your account.`,
    },
    {
      label: 'Third-party offers',
      name: 'thirdPartyOffers',
      furtherInfo: `We'd occasionally like to send you offers from carefully selected third parties. 
      These messages will be sent directly from us, using the marketing preferences you selected.`,
    },
  ],
  ...common,
};

export default marketingPreferences;
