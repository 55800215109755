import { Container, GridItem } from '@constellation/core';
import styled from 'styled-components';

export const StyledContainer = styled(Container)`
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.spacing_05} !important;
  background-color: ${({ theme }) => theme.color_background_panel_default_1};
  .csl-grid {
    padding-left: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`;

export const StyledNoPadding = styled(GridItem)`
  padding: 0 !important;
  pointer-events: all !important;
`;

export const StyledIcon = styled(StyledNoPadding)`
  display: flex;
  justify-content: flex-end;
`;

export const StyledGridItem = styled('div')`
  margin-top: ${({ theme }) => theme.spacing_02};
  padding: 0rem !important;
`;
export const StyledSubtitle = styled('div')`
  padding: 0rem !important;
`;

export const StyledIconGridItem = styled(GridItem)`
  flex: 0 0 18px !important;
  padding-left: 0 !important;
`;
