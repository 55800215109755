import React, { ReactElement } from 'react';

import {
  Button,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Text,
} from '@constellation/core';
import ButtonProps from '@constellation/core/dist/types/components/Button/button.types';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';

import { MarketingPreferencesContent } from './marketingPreferences.config';
import {
  StyledDialog,
  StyledLoginBtnGridItem,
} from './styledMarketingPreferences';
import InfoIcon from '../../assets/icons/infoIcon';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { MarketingDataModelAction } from '../../store/action/dataModal.action';
import { toggleAction } from '../../store/action/toggler.action';
import * as routes from '../manifest';

export default function MarketingDataModal(): ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { skipForNow, goBackToPrefsButton, doItLaterButton, skipDescription } =
    useContent<MarketingPreferencesContent>();
  const isDeviceFound = useDeviceResizing();
  const dataModelDetails = useSelector(
    (state: RootState) => state.DataModalReducer,
  );

  const handleOnDoItLater = (): void => {
    navigate(routes.LoginWss);
    dispatch(MarketingDataModelAction(false));
    dispatch(toggleAction(true));
  };
  const handleOnGoBackToPrefs = (): void => {
    dispatch(MarketingDataModelAction(false));
  };
  const renderButton = (
    btnTxt: string,
    btnVariation?: ButtonProps['variation'],
    dataQaId?: string,
    handleButtonClick?: React.MouseEventHandler<HTMLElement>,
  ): ReactElement => (
    <Button
      width="fluid"
      variation={btnVariation}
      onClick={handleButtonClick}
      data-qa-id={dataQaId}
    >
      {btnTxt}
    </Button>
  );
  return (
    <StyledDialog
      open={dataModelDetails.openMarketingDataModal}
      data-testid="dataModalPopup"
      closeButton={false}
    >
      <Grid alignX="center" alignY="center">
        <InfoIcon colour="white" />
      </Grid>
      <Heading
        as="h4"
        size="s4"
        align="center"
        marginTop="05"
        marginBottom="05"
        data-testid="dataModalHeading"
        aria-level={2}
      >
        {skipForNow}
      </Heading>
      <ContentGroup marginBottom="07">
        <Grid alignX="center">
          <Text size="s2" align="center">
            {skipDescription}
          </Text>
        </Grid>
      </ContentGroup>

      <Grid alignX={isDeviceFound.mobile ? 'center' : 'justify'}>
        <StyledLoginBtnGridItem
          xs={isDeviceFound.mobile ? 12 : 6}
          isMobileFound={isDeviceFound.mobile}
        >
          {renderButton(
            doItLaterButton,
            'primary',
            dataQaIds.loginAndRegistration.loginBtn,
            handleOnDoItLater,
          )}
        </StyledLoginBtnGridItem>
        <GridItem xs={isDeviceFound.mobile ? 12 : 6}>
          {renderButton(
            goBackToPrefsButton,
            'secondary',
            dataQaIds.loginAndRegistration.createOnlineAccBtn,
            handleOnGoBackToPrefs,
          )}
        </GridItem>
      </Grid>
    </StyledDialog>
  );
}
