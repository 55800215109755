import { ContentGroup, Dialog, Grid } from '@constellation/core';
import styled from 'styled-components';

import { DataModalProps } from './DataModel.config';
import { device } from '../../utils/breakpoints';

export const StyledDialog = styled(Dialog)<Partial<DataModalProps>>`
  border-radius: ${({ theme }) => theme.spacing_02};
  ${({ isWss, theme }) =>
    isWss &&
    `div > div > div  {
      background-color: ${theme.color_background_panel_brand_2} !important;
    }
    div > div > div > div > button {
      span {
        color: #0f7eb1 !important;
      }
      text-decoration-color: #0f7eb1 !important;
    }
    svg {
      fill:  ${theme.color_icon_inverse_default_1} !important;
    }
    h4 {
      color: ${theme.color_text_inverse_default_1} !important;
    }
    span {
      color: ${theme.color_text_inverse_default_1} !important;
    }
    svg > path {
      fill: ${theme.color_icon_inverse_default_1} !important;
    }
    div > div > div > div:first-child > button {
      color: #0f7eb1 !important;
      border-color:  #0f7eb1 !important;
      background-color:  #0f7eb1 !important;
      span {
        color: ${theme.color_action_foreground_tertiary_alternate_default} !important;
      }
    }
    span > a  {
      color: #0f7eb1 !important;
      transition: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
    }
    span > a > span > span {
      color: #0f7eb1 !important;
    }
    button > span  {
      transition: none !important;
      box-shadow: none !important;
    }
      button > span > svg > path {
      fill:  #0f7eb1 !important;
    }
    `}
`;
export const StyledDataModalLink = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing_05};
  margin-bottom: ${({ theme }) => theme.spacing_03};
  @media ${device.md} {
    margin-bottom: 0;
  }
`;

export const StyledDialogButtonGrid = styled(Grid)<Partial<DataModalProps>>`
  margin-top: ${({ theme }) => theme.spacing_07};
  ${({ isLink, theme }) =>
    isLink ? `margin-bottom: ${theme.spacing_03}` : 'margin-bottom: 0'};
  @media ${device.md} {
    margin-bottom: 0;
  }
`;

export const StyledPhoneLinkContainer = styled(ContentGroup)`
  margin-bottom: ${({ theme }) => theme.spacing_03} !important;
  @media ${device.md} {
    margin-bottom: 0 !important;
  }
`;
