interface IprofilInfo {
  type: 'PROFILE_INFO';
  profileInfo: object;
}

export const profilInfoAction = (profileInfo: object): IprofilInfo => ({
  type: 'PROFILE_INFO',
  profileInfo,
});

interface MarketingPreferencesFail {
  type: 'MARKETING_PREFS_FAIL';
  isMarketingPrefDataModal: boolean;
}

export const marketingPrefsFailAction = (
  isMarketingPrefDataModal: boolean,
): MarketingPreferencesFail => ({
  type: 'MARKETING_PREFS_FAIL',
  isMarketingPrefDataModal,
});
