import React, { ReactElement } from 'react';

import { useContent } from '@interstellar/react-app-content';
import { useTheme } from 'styled-components';

import { ForgotCredentialsDataModalProps } from './LoginAndRegistrationWss.config';
import WarningIcon from '../../assets/icons/warningIcon';
import DataModal from '../../components/dataModal/dataModal';
import dataQaIds from '../../dataModel/dataQaIds';
import { RegistrationContent } from '../registration/Registration.config';

export default function ForgotCredentialDataModal({
  isApiFailed,
  isDataFound,
  handleModelButtonClick,
  handleNeedHelpNavigate,
}: ForgotCredentialsDataModalProps): ReactElement {
  const {
    noCustomerRecordFoundHeading,
    tryAgainLink,
    needHelpText,
    forgotUserIdDetailsNotFoundMessage,
    dataRetrievalErrorHeading,
    dataRetrievalErrorDescription,
    contactUsLink,
    phoneNumber,
  } = useContent<RegistrationContent>();
  const theme: any = useTheme();
  const handleContactUsLink = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <>
      {isApiFailed && (
        <DataModal
          brand={undefined}
          icon={
            <WarningIcon
              width="72"
              height="72"
              colour={theme.color_icon_inverse_default_2}
            />
          }
          heading={dataRetrievalErrorHeading}
          description={dataRetrievalErrorDescription}
          buttonTxt={tryAgainLink}
          link={contactUsLink}
          onClick={handleModelButtonClick}
          onClickLink={handleContactUsLink}
          dataQaId={{
            btn: dataQaIds.loginAndRegistration.tryAgainWssBtn,
            link: dataQaIds.loginAndRegistration.contactUsLink,
          }}
          isWss
        />
      )}
      {!isDataFound && (
        <DataModal
          brand={undefined}
          icon={
            <WarningIcon
              width="72"
              height="72"
              colour={theme.color_icon_inverse_default_2}
            />
          }
          heading={noCustomerRecordFoundHeading}
          description={forgotUserIdDetailsNotFoundMessage}
          isPhoneLink
          buttonTxt={tryAgainLink}
          link={needHelpText}
          onClick={handleModelButtonClick}
          onClickLink={handleNeedHelpNavigate}
          dataQaId={{
            btn: dataQaIds.loginAndRegistration.tryAgainWssBtn,
            link: dataQaIds.loginAndRegistration.needHelpLink,
          }}
          isWss
        />
      )}
    </>
  );
}
