import { ActionInterface } from '../../components/appConfig/AppConfig';

export interface ITogglerInterface extends ActionInterface {
  isWss: boolean;
}

export const toggleAction = (isWss: boolean): ITogglerInterface => ({
  type: 'TOGGLE',
  isWss,
});
