import axios from 'axios';

const config = {
  headers: {
    'content-Type': 'application/json',
    'x-lbg-user-id': '12',
    'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
  },
};

const requestBody = (requestObj: object) => {
  return {
    ...requestObj,
    customerStatuses: {
      internalCode: '19',
      externalCode: 'x',
      description: 'Internal Trace',
    },
    documentDelivery: {
      code: '4',
      description: 'Paperless',
    },
  };
};

export const APICallHelperPUT = (
  endpoint: string,
  requestObj: object,
  additionalConfig?: object,
): Promise<void> =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${window.appConfig.REACT_APP_API_URL}${window.appConfig.REACT_APP_AUTH_PROXY}/mta/api/v1/${endpoint}`,
        requestBody(requestObj),
        {
          headers: {
            ...config.headers,
            'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
            ...additionalConfig,
          },
        },
      )
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });
