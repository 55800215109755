import React, { ReactElement } from 'react';

interface IPhoneIconProps {
  colour: string;
}
export default function phoneIcon({ colour }: IPhoneIconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 17"
      fill={colour}
      className="phoneIconMargin"
    >
      <path
        d="M15 16.0146C13.1926 16.0146 11.3963 15.5702 9.61111 14.6813C7.82593 13.7924 6.22222 12.6369 4.8 11.2146C3.37778 9.79243 2.22222 8.18872 1.33333 6.40354C0.444444 4.61835 0 2.82206 0 1.01465C0 0.72893 0.0952369 0.490833 0.285711 0.300359C0.476185 0.109885 0.714281 0.0146484 1 0.0146484H4.11111C4.31276 0.0146484 4.4928 0.0850188 4.65124 0.22576C4.80967 0.3665 4.91111 0.555389 4.95556 0.792426L5.55556 3.59243C5.58519 3.79983 5.58148 3.98872 5.54444 4.15909C5.50741 4.32946 5.42963 4.47391 5.31111 4.59243L3.08889 6.83687C3.91852 8.21465 4.84815 9.41465 5.87778 10.4369C6.90741 11.4591 8.07407 12.3258 9.37778 13.0369L11.4889 10.8591C11.637 10.6961 11.8074 10.5813 12 10.5146C12.1926 10.448 12.3852 10.4369 12.5778 10.4813L15.2222 11.0591C15.4491 11.1091 15.6354 11.2216 15.7812 11.3966C15.9271 11.5716 16 11.7776 16 12.0146V15.0146C16 15.3004 15.9048 15.5385 15.7143 15.7289C15.5238 15.9194 15.2857 16.0146 15 16.0146ZM2.42222 5.61465L4.22222 3.79243L3.71111 1.34798H1.33333C1.33333 1.92576 1.42222 2.55909 1.6 3.24798C1.77778 3.93687 2.05185 4.72576 2.42222 5.61465ZM10.6222 13.6813C11.2296 13.9628 11.8889 14.1924 12.6 14.3702C13.3111 14.548 14 14.6517 14.6667 14.6813V12.3035L12.3778 11.8369L10.6222 13.6813Z"
        fill={colour}
      />
    </svg>
  );
}
