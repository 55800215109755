import { ActionInterface } from '../../components/appConfig/AppConfig';

export interface IFormStepsInfo extends ActionInterface {
  stepNumber: number;
}

export const changeStepAction = (stepNumber: number): IFormStepsInfo => ({
  type: 'CHANGE_STEP',
  stepNumber,
});

export interface INumberOfStepsInfo extends ActionInterface {
  numberOfSteps: number;
}
export const changeNumberOfStepsAction = (
  numberOfSteps: number,
): INumberOfStepsInfo => ({
  type: 'CHANGE_NUMBER_OF_STEPS',
  numberOfSteps,
});
