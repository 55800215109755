import {
  Box,
  Button,
  ContentGroup,
  Footer,
  Grid,
  GridItem,
  Hr,
  Link,
  MemorableDate,
  Notification,
  Page,
  Paragraph,
  Text,
} from '@constellation/core';
import styled from 'styled-components';

import WssBackgroundPatternDesktop from '../../assets/common/wss-background-pattern-desktop.png';
import WssBackgroundPatternMobile from '../../assets/common/wss-background-pattern-mobile.png';
import WssBackgroundPatternTablet from '../../assets/common/wss-background-pattern-tablet.png';
import { device } from '../../utils/breakpoints';

interface IBackgroundProps {
  isWss: boolean;
}

export const StyledDetailsBox = styled(Box)`
  background-color: ${({ theme }) =>
    theme.color_background_panel_brand_2} !important;
  div > span:nth-child(2) {
    color: ${({ theme }) => theme.color_text_critical} !important;
  }
  span {
    color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  }
  padding: ${({ theme }) => theme.spacing_04} !important;
  padding-bottom: ${({ theme }) => theme.spacing_05} !important;
  @media ${device.md} {
    padding: ${({ theme }) => theme.spacing_05} !important;
  }
`;
export const StyledBox = styled(Box)`
  background-color: ${({ theme }) =>
    theme.color_background_panel_brand_2} !important;
  padding: ${({ theme }) => theme.spacing_none} !important;
`;
export const StyledMemorableDate = styled(MemorableDate)`
  input {
    background-color: ${({ theme }) =>
      theme.color_background_panel_brand_2} !important;
    color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  }
`;
export const StyledFieldBox = styled('div')`
  input {
    background-color: ${({ theme }) =>
      theme.color_background_panel_brand_2} !important;
    color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
    width: 100% !important;
    max-width: none !important;
  }
  div {
    background-color: ${({ theme }) =>
      theme.color_background_panel_brand_2} !important;
  }
  button > span:first-child {
    color: #0f7eb1 !important;
  }
  button {
    text-decoration-color: #0f7eb1 !important;
  }
`;
export const StyledWssButton = styled(Button)`
  background-color: #0f7eb1 !important;
  border-color: #0f7eb1 !important;
  text-decoration: none !important;
  span {
    color: ${({ theme }) => theme.color_text_default_1} !important;
  }
`;
export const StyledLinkButtonBox = styled(Grid)`
  span {
    color: #0f7eb1 !important;
  }
  button {
    color: #0f7eb1 !important;
  }
`;
export const StyledLinkButtonContainer = styled(Grid)`
  span {
    color: #0f7eb1 !important;
  }
  button {
    color: #0f7eb1 !important;
  }
  margin-top: none !important;
  margin-bottom: ${({ theme }) => theme.spacing_07} !important;
`;

export const StyledParagraphWss = styled(Paragraph)`
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  a {
    color: #0f7eb1 !important;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledWssHeader = styled.header`
  background-color: ${({ theme }) => theme.color_background_section_brand_2};
  padding: ${({ theme }) => theme.spacing_05} 0;
  g:first-child > path:nth-child(-n + 5) {
    fill: #000000 !important;
  }
  g:first-child > path:nth-child(n + 6) {
    fill: #636466 !important;
  }
`;

export const StyledTextGridItem = styled(GridItem)`
  margin-top: ${({ theme }) => theme.spacing_05};
  @media ${device.md} {
    margin-top: 0;
  }
  @media ${device.lg} {
    padding-left: ${({ theme }) => theme.spacing_05} !important;
  }
`;

export const StyledFooterDiv = styled('div')`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing_05};
  a {
    margin-right: ${({ theme }) => theme.spacing_05};
  }
  a:last-child {
    margin-right: 0;
  }
`;

export const StyledLink = styled(Link)`
  span {
    font-weight: 600;
    font-size: ${({ theme }) => theme.typography_text_font_size_s1};
  }
`;

export const StyledLinkContanier = styled(ContentGroup)`
  a {
    margin-right: ${({ theme }) => theme.spacing_05};
  }
  a:last-child {
    margin-right: 0;
  }
`;

export const StyledFooterWss = styled(Footer)<IBackgroundProps>`
  ${({ isWss, theme }) =>
    isWss &&
    `background-color: ${theme.color_background_section_brand_2} !important;
      span {
        color: ${theme.color_text_inverse_default_1} !important;
        }
      a {
        color: #0f7eb1 !important;
      }
      a:focus {
        background: none !important;
        box-shadow: none !important
      }
      a > span > span {
        color: #0f7eb1 !important;
      }`}
  z-index: 0 !important;
`;

export const StyledBackground = styled(Page)<IBackgroundProps>`
  ${({ isWss }) =>
    isWss &&
    ` background: url(${WssBackgroundPatternMobile}) no-repeat fixed 0 / cover !important;
background-attachment: local !important;
@media ${device.md} {
  background: url(${WssBackgroundPatternTablet}) no-repeat fixed center /
    cover !important;
  background-attachment: local !important;
}
@media ${device.lg} {
  background: url(${WssBackgroundPatternDesktop}) no-repeat / cover !important;
  background-attachment: local !important;
}
main {
  display: flex;
  align-items: center;
  justify-content: center;
}`}
`;

export const StyledLinkWss = styled(Link)`
  color: #0f7eb1 !important;
  span {
    color: #0f7eb1 !important;
  }
`;

export const StyledHrWss = styled(Hr)`
  border-top-color: ${({ theme }) => theme.color_border_default_1} !important;
`;

export const StyledLinkBGridItem = styled(GridItem)`
  padding-bottom: ${({ theme }) => theme.spacing_04} !important;
  padding-top: ${({ theme }) => theme.spacing_04} !important;
  @media ${device.md} {
    padding: ${({ theme }) => theme.spacing_05} !important;
  }
  border-right-style: solid;
  border-color: ${({ theme }) => theme.color_border_default_1} !important;
  border-width: 0.0625rem;
`;
export const StyledNoPaddingGridItem = styled(GridItem)`
  padding: 0 !important;
`;
export const StyledNotification = styled(Notification)`
  span {
    color: ${({ theme }) => theme.color_text_default_1} !important;
  }
  div {
    border-style: none !important;
  }
  div > div:nth-child(1) > div {
    padding: 0 !important;
    margin-bottom: ${({ theme }) => theme.spacing_04};
  }
  div > div:nth-child(1) {
    padding: ${({ theme }) => theme.spacing_03} !important;
  }
  @media ${device.md} {
    div > div:nth-child(1) > div {
      padding: 0 !important;
      margin-bottom: 0;
    }
    div > div:nth-child(1) {
      padding: ${({ theme }) => theme.spacing_05} !important;
    }
  }
`;
export const StyledForgotCredBox = styled(Box)`
  div {
    background-color: ${({ theme }) =>
      theme.color_background_panel_brand_2} !important;
  }
  background-color: ${({ theme }) =>
    theme.color_background_panel_brand_2} !important;
  padding: ${({ theme }) => theme.spacing_none} !important;
`;
export const StyledCtaBorderDiv = styled('div')`
  border-style: solid;
  border-color: ${({ theme }) => theme.color_border_default_1} !important;
  border-width: 0.0625rem !important;
  border-radius: 0.5rem;
`;

export const StyledText = styled(Text)`
  cursor: pointer;
`;

export const StyledBackgroundPage = styled(ContentGroup)`
  display: flex;
  background: transparent !important;
  align-items: center;
  justify-content: center;
`;

export const StyledBoldWssText = styled(Text)`
  font-weight: 600 !important;
`;

export const StyledFAQLink = styled(StyledLinkWss)`
  font-weight: 400 !important;
  font-size: ${({ theme }) => theme.typography_text_font_size_s1};
`;
