import React, { ReactElement } from 'react';

interface IBulletIconProps {
  colour: string;
}
export default function BulletIcon({ colour }: IBulletIconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <circle cx="4" cy="12.0488" r="4" fill={colour} />
    </svg>
  );
}
