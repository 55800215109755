import React, { ReactElement } from 'react';

import { GridItem, ContentGroup, Text, Heading } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import {
  ForgotPasswordContent,
  LoginAuthenticateProps,
} from './forgotPassword.config';
import { StyledInlineBox } from '../../components/appConfig/common.styled';
import PhoneLink from '../../components/phoneLink/PhoneLink';

export default function LoginAuthenticate({
  isCustomerIdentificationError,
  isNumberFound,
  phoneNumber,
}: LoginAuthenticateProps): ReactElement {
  const {
    customerNotFoundHeading,
    customerNotFoundSubText,
    verifyText,
    authSubText,
    noContactFoundSubText,
  } = useContent<ForgotPasswordContent>();

  return isCustomerIdentificationError ? (
    <GridItem>
      <Heading size="s4" marginBottom="02" aria-level={3}>
        {customerNotFoundHeading}
      </Heading>
      <Text size="s2">{customerNotFoundSubText}</Text>
    </GridItem>
  ) : (
    <>
      <GridItem>
        <ContentGroup marginBottom="02">
          <Text size="s4">
            {isNumberFound ? verifyText : customerNotFoundHeading}
          </Text>
        </ContentGroup>
      </GridItem>
      <GridItem>
        <ContentGroup marginBottom="05" marginTop="none">
          <Text size="s2">
            {isNumberFound ? authSubText : noContactFoundSubText}
          </Text>
        </ContentGroup>
        {isNumberFound && (
          <ContentGroup marginBottom="05" marginTop="none">
            <Text size="s2" color="subdued">
              {phoneNumber}
            </Text>
          </ContentGroup>
        )}
        {!isNumberFound && (
          <StyledInlineBox>
            <PhoneLink />
          </StyledInlineBox>
        )}
      </GridItem>
    </>
  );
}
