import {
  IUtilsReducer,
  IUtilsShowHideAction,
} from '../action/utilsModal.action';

const initialState = {
  showSpinner: false,
};

const initialAction = { type: '', ...initialState };

export const UtilsReducer = (
  state: IUtilsReducer = initialState,
  action: IUtilsShowHideAction = initialAction,
): IUtilsReducer => {
  if (action.type === 'SHOW_HIDE_SPINNER') {
    return {
      ...state,
      showSpinner: action.showSpinner,
    };
  }
  return state;
};
