import React, { ReactElement } from 'react';

export default function carIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M2 15.9081V17.2581C2 17.4706 1.92813 17.6487 1.78438 17.7925C1.64062 17.9362 1.4625 18.0081 1.25 18.0081H0.75C0.5375 18.0081 0.359375 17.9362 0.215625 17.7925C0.071875 17.6487 0 17.4706 0 17.2581V9.15812L2.125 2.75812C2.20833 2.52478 2.34583 2.34145 2.5375 2.20812C2.72917 2.07478 2.95 2.00812 3.2 2.00812H14.8C15.05 2.00812 15.2708 2.07478 15.4625 2.20812C15.6542 2.34145 15.7917 2.52478 15.875 2.75812L18 9.15812V17.2581C18 17.4706 17.9281 17.6487 17.7844 17.7925C17.6406 17.9362 17.4625 18.0081 17.25 18.0081H16.725C16.5083 18.0081 16.3333 17.9362 16.2 17.7925C16.0667 17.6487 16 17.4706 16 17.2581V15.9081H2ZM2.075 7.65812H15.925L14.55 3.50812H3.45L2.075 7.65812ZM4.14413 13.1581C4.53138 13.1581 4.85417 13.0269 5.1125 12.7644C5.37083 12.5019 5.5 12.1831 5.5 11.8081C5.5 11.4192 5.36875 11.0887 5.10625 10.8164C4.84375 10.5442 4.525 10.4081 4.15 10.4081C3.76112 10.4081 3.43056 10.5437 3.15832 10.8147C2.88611 11.0858 2.75 11.415 2.75 11.8022C2.75 12.1895 2.88554 12.5123 3.15663 12.7706C3.42769 13.029 3.75686 13.1581 4.14413 13.1581ZM13.875 13.1581C14.2639 13.1581 14.5944 13.0269 14.8667 12.7644C15.1389 12.5019 15.275 12.1831 15.275 11.8081C15.275 11.4192 15.1395 11.0887 14.8684 10.8164C14.5973 10.5442 14.2681 10.4081 13.8809 10.4081C13.4936 10.4081 13.1708 10.5437 12.9125 10.8147C12.6542 11.0858 12.525 11.415 12.525 11.8022C12.525 12.1895 12.6562 12.5123 12.9187 12.7706C13.1813 13.029 13.5 13.1581 13.875 13.1581ZM1.5 14.4081H16.5V9.15812H1.5V14.4081Z"
        fill="#202020"
      />
    </svg>
  );
}
