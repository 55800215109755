export interface ILoginInfo {
  type: string;
  emailAddress: string;
}

export const forgotEmailAction = (emailAddress: string): ILoginInfo => ({
  type: 'FORGOT_EMAIL',
  emailAddress,
});

export interface ILoginModalInfo {
  type: string;
  loginConstraints: {
    isLoginAttemptPrevented: boolean;
    isLoginAttemptExceeded: boolean;
    isRegistered: boolean;
  };
}

export const loginModalAction = (loginConstraints: {
  isLoginAttemptPrevented: boolean;
  isLoginAttemptExceeded: boolean;
  isRegistered: boolean;
}): ILoginModalInfo => ({
  type: 'LOGIN_MODAL_CONSTRAINTS',
  loginConstraints,
});
