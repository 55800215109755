import { Button } from '@constellation/core';
import styled from 'styled-components';

export const StyledLinkBtn = styled(Button)`
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  min-width: auto !important;
  color: ${({ theme }) => theme.color_text_brand_1} !important;
  text-decoration: underline !important;
  border-radius: 0 !important;
  &:focus:hover {
    box-shadow: none !important;
  }
  svg {
    color: ${({ theme }) => theme.color_text_brand_1} !important;
  }
`;
