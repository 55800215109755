import { Grid, GridItem } from '@constellation/core';
import styled from 'styled-components';

import { device } from '../../utils/breakpoints';

interface IStepperProps {
  isActive: boolean;
}
interface IOuterStepperProps {
  isFirst: boolean;
  isLast: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stepSpacing = (isMobile, isAlternate): any => {
  if (isAlternate) {
    return ({ theme }) => theme.spacing_03;
  }
  if (isMobile) {
    return ({ theme }) => theme.spacing_01;
  }
  return ({ theme }) => theme.spacing_02;
};

export const StyledStepperContainer = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing_07};
`;

export const StyledSpan = styled('div')`
  height: ${({ theme }) => theme.spacing_01};
  background: ${({ isActive }: IStepperProps) =>
    isActive
      ? ({ theme }) => theme.color_border_brand_1
      : ({ theme }) => theme.color_border_default_2};
  border-radius: ${({ theme }) => theme.spacing_01};
`;

export const StyledOuterStepper = styled(GridItem)`
  padding-right: ${({ isLast }: IOuterStepperProps) =>
    stepSpacing(true, isLast)} !important;
  padding-left: ${({ isFirst }: IOuterStepperProps) =>
    stepSpacing(true, isFirst)} !important;

  @media ${device.md} {
    padding-right: ${({ isLast }: IOuterStepperProps) =>
      stepSpacing(false, isLast)} !important;
    padding-left: ${({ isFirst }: IOuterStepperProps) =>
      stepSpacing(false, isFirst)} !important;
  }
`;
