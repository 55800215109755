import { Grid, GridItem, Box, Heading, Hr, Text } from '@constellation/core';
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  width: 100%;
  margin: 0;
`;
export const StyledGridItem = styled(GridItem)`
  padding: 0 !important;
`;
export const StyledBox = styled(Box)`
  background-color: ${({ theme }) =>
    theme.color_background_panel_brand_2} !important;
  padding: 0 !important;
`;
export const StyledInnerDiv = styled('div')`
  &.mobileFound {
    padding: 1rem !important;
  }
  &.notMobile {
    padding: 1.5rem !important;
  }
`;

export const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  padding-bottom: ${({ theme }) => theme.spacing_05}!important;
  margin-bottom: ${({ theme }) => theme.spacing_02}!important;
  border-bottom: 1px solid #adadad !important;
`;
export const StyledHr = styled(Hr)`
  margin: 0 !important;
  padding-bottom: ${({ theme }) => theme.spacing_04} !important;
`;
export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
`;
export const StyleSubHeading = styled(Heading)`
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  margin-bottom: ${({ theme }) => theme.spacing_04}!important;
`;
export const StyledVerticalHr = styled(Hr)`
  margin: ${({ theme }) => theme.spacing_05} 0 !important;
`;
export const StyledListDiv = styled('div')`
  width: 100%;
  a.phoneNumber,
  a.phoneNumber span {
    color: #0f7eb1 !important;
  }
  a.phoneNumber :focus {
    background: none !important;
    box-shadow: none !important;
    color: #0f7eb1 !important;
  }
  ul {
    margin-bottom: ${({ theme }) => theme.spacing_04} !important;
  }
  ul li span,
  ul li span svg {
    color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  }
  ul li span svg.phoneIconMargin,
  ul li span svg.mailIconMargin {
    margin-top: ${({ theme }) => theme.spacing_02} !important;
  }
`;
export const StyledWriteUsHr = styled(Hr)`
  border-color: #e6e6e6 !important;
  margin: ${({ theme }) => theme.spacing_05} 0 !important;
`;

export const StyledGoBack = styled('div')`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  button {
    padding: ${({ theme }) => theme.spacing_04} 0 !important;
  }
  > button {
    color: #0f7eb1 !important;
  }
`;
export const StyledBottomHr = styled(Hr)`
  border-color: ${({ theme }) => theme.color_borser_default_2} !important;
  margin: 0 !important;
`;
export const StyledContainer = styled(GridItem)`
  &.BHTheme {
    margin: 0 auto !important;
  }
`;
