import React, { ReactElement } from 'react';

export default function profileIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9 8.94543C7.7625 8.94543 6.75 8.55167 5.9625 7.76416C5.175 6.97665 4.78125 5.96414 4.78125 4.72662C4.78125 3.4891 5.175 2.47659 5.9625 1.68908C6.75 0.901568 7.7625 0.507812 9 0.507812C10.2375 0.507812 11.25 0.901568 12.0375 1.68908C12.825 2.47659 13.2188 3.4891 13.2188 4.72662C13.2188 5.96414 12.825 6.97665 12.0375 7.76416C11.25 8.55167 10.2375 8.94543 9 8.94543ZM0 17.9737V15.3299C0 14.6174 0.178125 14.008 0.534375 13.5017C0.890625 12.9955 1.35 12.6111 1.9125 12.3486C3.16875 11.7861 4.37344 11.3642 5.52656 11.083C6.67969 10.8017 7.8375 10.6611 9 10.6611C10.1625 10.6611 11.3156 10.8064 12.4594 11.097C13.6031 11.3876 14.8024 11.8067 16.0572 12.3541C16.644 12.6189 17.1146 13.003 17.4687 13.5062C17.8229 14.0095 18 14.6174 18 15.3299V17.9737H0ZM1.6875 16.2861H16.3125V15.3299C16.3125 15.0299 16.2234 14.7439 16.0453 14.4721C15.8672 14.2002 15.6469 13.9986 15.3844 13.8674C14.1844 13.2861 13.0875 12.8877 12.0938 12.672C11.1 12.4564 10.0687 12.3486 9 12.3486C7.93125 12.3486 6.89062 12.4564 5.87812 12.672C4.86562 12.8877 3.76875 13.2861 2.5875 13.8674C2.325 13.9986 2.10938 14.2002 1.94062 14.4721C1.77187 14.7439 1.6875 15.0299 1.6875 15.3299V16.2861ZM9 7.2579C9.73125 7.2579 10.3359 7.01884 10.8141 6.54071C11.2922 6.06257 11.5312 5.45788 11.5312 4.72662C11.5312 3.99536 11.2922 3.39066 10.8141 2.91253C10.3359 2.4344 9.73125 2.19533 9 2.19533C8.26875 2.19533 7.66406 2.4344 7.18594 2.91253C6.70781 3.39066 6.46875 3.99536 6.46875 4.72662C6.46875 5.45788 6.70781 6.06257 7.18594 6.54071C7.66406 7.01884 8.26875 7.2579 9 7.2579Z"
        fill="#202020"
      />
    </svg>
  );
}
