import React, { KeyboardEvent, ReactElement, useRef, useState } from 'react';

import {
  Button,
  ContentGroup,
  GridItem,
  Spinner,
  Text,
  TextField,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch } from 'react-redux';

import { isWssMtaApi } from '../../apis/isWssMtaApi';
import { REGEX } from '../../constants/REGEX';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import * as routes from '../../routes/manifest';
import {
  ChangeEmailProps,
  RegistrationContent,
} from '../../routes/registration/Registration.config';
import { forgotEmailAction } from '../../store/action/login.action';
import { UpdateEmailAPIAction } from '../../store/action/updateEmail.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { isEmptyString } from '../../utils/isEmptyString';
import { StyledButtonsDiv } from '../appConfig/common.styled';
import LinkButton from '../linkButton/LinkButton';

export default function ChangeEmail({
  handleStepper,
  handleOnReverseStepper,
  handleChangeEmailToggle,
  isEmailFound,
}: ChangeEmailProps): ReactElement {
  const isDeviceFound = useDeviceResizing();
  const emailValue = useRef({} as HTMLInputElement);
  const cnfrmEmailValue = useRef({} as HTMLInputElement);
  const [emailErrMsg, setEmailErrMsg] = useState('');
  const [confirmEmailErrMsg, setConfirmEmailErrMsg] = useState('');
  const [isApiCalled, setIsApiCalled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({
    confirmEmailValue: '',
    newEmailValue: '',
  });
  const {
    emailErrorMessage,
    credentialsCreateAccountLabel,
    changeEmailSubText,
    confirmEmailErrorMessage,
    emailLabel,
    confirmEmailLabel,
    cancelLabel,
    continueLabel,
    emailAlreadyUsed,
  } = useContent<RegistrationContent>();

  const handleNavigate = (): void => {
    if (isEmailFound) handleChangeEmailToggle(false);
    else {
      handleOnReverseStepper();
      navigate(routes.Registration);
    }
  };
  const emailPattern = new RegExp(REGEX.EMAIL_PATTERN);
  const handleOnEmailSetErrMsg = (value: string): void => setEmailErrMsg(value);
  const handleEmailKeyDown = (
    event: KeyboardEvent<HTMLInputElement>,
  ): boolean => {
    if (REGEX.SPECIAL_CHARACTERS_EMAIL.indexOf(event.key) !== -1) {
      event.preventDefault();
      return false;
    }
    handleOnEmailSetErrMsg('');
    setConfirmEmailErrMsg('');
    return true;
  };
  const handleOnEmailBlurEvt = (): void => {
    if (
      !isEmptyString(emailValue.current.value) &&
      !emailPattern.test(emailValue.current.value)
    ) {
      handleOnEmailSetErrMsg(emailErrorMessage);
    } else handleOnEmailSetErrMsg('');
  };
  const handleConfirmEmailValidation = (): void => {
    if (
      !isEmptyString(cnfrmEmailValue.current.value) &&
      !emailPattern.test(cnfrmEmailValue.current.value)
    ) {
      setConfirmEmailErrMsg(emailErrorMessage);
    } else if (emailValue.current.value === cnfrmEmailValue.current.value) {
      setConfirmEmailErrMsg('');
    } else if (!isEmptyString(cnfrmEmailValue.current.value)) {
      setConfirmEmailErrMsg(confirmEmailErrorMessage);
    }
  };
  const handleOnCnFrmEmailBlurEvt = (): void => {
    handleOnEmailBlurEvt();
    handleConfirmEmailValidation();
  };

  const renderEmail = (): ReactElement => (
    <TextField
      name="email"
      label={emailLabel}
      inputRef={emailValue}
      defaultValue={inputValues.newEmailValue}
      onKeyDown={handleEmailKeyDown}
      onBlur={handleOnEmailBlurEvt}
      marginTop="none"
      marginBottom="05"
      data-testid="emailId"
      error={emailErrMsg}
      inputWidth={isDeviceFound.mobile ? 'fluid' : 'default'}
      data-qa-id={dataQaIds.loginAndRegistration.othersEmailField}
      autoComplete="off"
    />
  );

  const renderConfirmEmail = (): ReactElement => (
    <TextField
      name="confirmEmail"
      label={confirmEmailLabel}
      inputRef={cnfrmEmailValue}
      defaultValue={inputValues.confirmEmailValue}
      onKeyDown={handleEmailKeyDown}
      onBlur={handleOnCnFrmEmailBlurEvt}
      marginBottom="none"
      marginTop="none"
      data-testid="confirmEmailId"
      error={confirmEmailErrMsg}
      inputWidth={isDeviceFound.mobile ? 'fluid' : 'default'}
      data-qa-id={dataQaIds.loginAndRegistration.othersConfirmEmailField}
      autoComplete="off"
    />
  );
  const handleOnEmailFields = (): boolean => {
    if (
      isEmptyString(cnfrmEmailValue.current.value) &&
      isEmptyString(emailValue.current.value)
    ) {
      setEmailErrMsg(emailErrorMessage);
      setConfirmEmailErrMsg(emailErrorMessage);
      return true;
    }
    if (
      !isEmptyString(emailValue.current.value) &&
      !emailPattern.test(emailValue.current.value)
    ) {
      handleOnEmailSetErrMsg(emailErrorMessage);
      return true;
    }
    if (
      !isEmptyString(cnfrmEmailValue.current.value) &&
      !emailPattern.test(cnfrmEmailValue.current.value)
    ) {
      setConfirmEmailErrMsg(emailErrorMessage);
      return true;
    }
    if (emailValue.current.value !== cnfrmEmailValue.current.value) {
      setConfirmEmailErrMsg(confirmEmailErrorMessage);
      return true;
    }
    setInputValues({
      confirmEmailValue: cnfrmEmailValue.current.value,
      newEmailValue: emailValue.current.value,
    });
    return false;
  };
  const handleOnClickContinue = (): void => {
    if (handleOnEmailFields()) {
      setInputValues({
        confirmEmailValue: '',
        newEmailValue: '',
      });
    } else if (
      isEmptyString(confirmEmailErrMsg) &&
      isEmptyString(emailErrMsg)
    ) {
      setInputValues({
        confirmEmailValue: cnfrmEmailValue.current.value,
        newEmailValue: emailValue.current.value,
      });
      dispatch(forgotEmailAction(cnfrmEmailValue.current.value));
      setIsApiCalled(true);
      isWssMtaApi(emailValue.current.value.trim())
        .then(() => {
          dispatch(forgotEmailAction(''));
          setIsApiCalled(false);
          dispatch(ShowHideSpinnerAction(false));
          setEmailErrMsg(emailAlreadyUsed);
          setConfirmEmailErrMsg(emailAlreadyUsed);
        })
        .catch((error) => {
          if (error.message.includes('404')) {
            dispatch(UpdateEmailAPIAction(true));
            handleStepper();
          }
          setIsApiCalled(false);
          dispatch(ShowHideSpinnerAction(false));
        });
    }
  };
  const renderUpdateEmail = (): ReactElement =>
    isApiCalled ? (
      <GridItem>
        <ContentGroup marginBottom="09" marginTop="09">
          <Spinner />
        </ContentGroup>
      </GridItem>
    ) : (
      <>
        <GridItem>
          <ContentGroup marginBottom="02">
            <Text size="s4">{credentialsCreateAccountLabel}</Text>
          </ContentGroup>
          <ContentGroup marginBottom="05">
            <Text size="s2">{changeEmailSubText}</Text>
          </ContentGroup>
          {renderEmail()}
        </GridItem>
        <GridItem>
          <ContentGroup marginBottom="07" marginTop="none">
            <Text size="s2" weight="bold">
              {renderConfirmEmail()}
            </Text>
          </ContentGroup>
          <StyledButtonsDiv padding>
            <LinkButton
              handleButtonClick={handleNavigate}
              testId="cancelButtonMobile"
              dataQaId={dataQaIds.loginAndRegistration.cancelLinkMobile}
            >
              {cancelLabel}
            </LinkButton>
            <Button
              data-testid="continueBtnMobile"
              onClick={handleOnClickContinue}
              data-qa-id={dataQaIds.loginAndRegistration.continueBtnMobile}
            >
              {continueLabel}
            </Button>
          </StyledButtonsDiv>
        </GridItem>
      </>
    );
  return renderUpdateEmail();
}
