import React, { ReactElement } from 'react';

import { FooterItem, Grid, Text, GridItem } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';

import { IHeaderFooterProps } from './LoginAndRegistrationWss.config';
import {
  StyledFooterDiv,
  StyledFooterWss,
  StyledLink,
  StyledLinkContanier,
  StyledTextGridItem,
} from './LoginAndRegistrationWss.styled';
import { FooterContent } from '../../components/footer/Footer.config';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import * as routes from '../manifest';

export default function FooterWss({ isWss }: IHeaderFooterProps): ReactElement {
  const {
    footerContent,
    footerTermsText,
    footerPrivacyText,
    footerCookiePolicyText,
  } = useContent<FooterContent>();
  const navigate = useNavigate();
  const isDeviceFound = useDeviceResizing();
  const handleOnTermsAndConditions = (): void => {
    navigate(routes.TermsAndConditions);
  };
  const handleOnPrivacyNotice = (): void => {
    navigate(routes.PrivacyPolicy);
  };
  const handleOnCookiePolicy = (): void => {
    navigate(routes.CookiePolicy);
  };
  const displayLink = (
    txt: string,
    dataQaId: string,
    navigateFunction: any,
  ): ReactElement => (
    <StyledLink data-qa-id={dataQaId} onClick={navigateFunction}>
      {txt}
    </StyledLink>
  );
  const displayText = (txt: string): ReactElement => (
    <Text size="s1" color="subdued">
      {txt}
    </Text>
  );
  return (
    <StyledFooterWss
      isWss={isWss}
      marginTop="none"
      data-qa-id={dataQaIds.footer.container}
    >
      <FooterItem>
        {!isDeviceFound.tablet ? (
          <Grid alignX="justify">
            <GridItem xl={4} sm={12} lg={4} md={4}>
              <StyledLinkContanier marginBottom="none" marginLeft="none">
                {displayLink(
                  footerTermsText,
                  dataQaIds.footer.termsAndCondtionLink,
                  handleOnTermsAndConditions,
                )}
                {displayLink(
                  footerPrivacyText,
                  dataQaIds.footer.privacyLink,
                  handleOnPrivacyNotice,
                )}
                {displayLink(
                  footerCookiePolicyText,
                  dataQaIds.footer.cookiePolicyLink,
                  handleOnCookiePolicy,
                )}
              </StyledLinkContanier>
            </GridItem>
            <StyledTextGridItem xl={8} sm={12} lg={8} md={8}>
              {displayText(footerContent)}
            </StyledTextGridItem>
          </Grid>
        ) : (
          <Grid alignX="justify">
            <GridItem md={5}>
              <StyledFooterDiv>
                {displayLink(
                  footerTermsText,
                  dataQaIds.footer.termsAndCondtionLink,
                  handleOnTermsAndConditions,
                )}
                {displayLink(
                  footerPrivacyText,
                  dataQaIds.footer.privacyLink,
                  handleOnPrivacyNotice,
                )}
              </StyledFooterDiv>
              {displayLink(
                footerCookiePolicyText,
                dataQaIds.footer.cookiePolicyLink,
                handleOnCookiePolicy,
              )}
            </GridItem>
            <GridItem md={7}>{displayText(footerContent)}</GridItem>
          </Grid>
        )}
      </FooterItem>
    </StyledFooterWss>
  );
}
