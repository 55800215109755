import React, { ReactElement } from 'react';

import { Paragraph } from '@constellation/core';

import { IHelpCentreQuestionsProps } from './helpCentreQns.config';
import { StyledAccordion, StyledContentGroup } from './helpCentreQns.styled';

export default function HelpCentreQns({
  helpCentreQns,
}: IHelpCentreQuestionsProps): ReactElement {
  const getQuestionsList = helpCentreQns.map((item: any, index: number) => {
    return (
      <StyledAccordion
        label={item.hcQuestions}
        variation="inpage1"
        key={`answers${index + 1}`}
      >
        <Paragraph
          size="s1"
          dangerouslySetInnerHTML={{ __html: item.hcQnsAnswer }}
        >
          {}
        </Paragraph>
      </StyledAccordion>
    );
  });
  return (
    <StyledContentGroup data-testid="helpCentreQns">
      {getQuestionsList}
    </StyledContentGroup>
  );
}
