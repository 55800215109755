import React, { ReactElement, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { DateFieldWssProps } from './dateFieldWss.config';
import { StyledMemorableDate } from '../../routes/loginAndRegistrationWss/LoginAndRegistrationWss.styled';
import { DateObject } from '../../routes/registration/Registration.config';
import { RootState } from '../../store';
import { dateFormat } from '../../utils/dateFormat';
import { isEmptyString } from '../../utils/isEmptyString';
import { DateProps } from '../appConfig/AppConfig';

export default function DateFieldWss({
  FieldLabel,
  errorMessage,
  handleDateValue,
  handleErrorMessage,
  handleOnEmptyFields,
  isDateFieldsEmpty,
  handleDataFormat,
}: DateFieldWssProps): ReactElement {
  const { dob } = useSelector((state: RootState) => state.RegistrationReducer);
  const [date, setDate] = useState<DateProps>(
    dob || {
      day: undefined,
      month: undefined,
      year: undefined,
    },
  );
  const [dateErrorMessage, setDateErrorMessage] = useState('');
  const today = new Date();
  const getAge = (): number => {
    const birthDate = new Date(dateFormat(date, '/'));
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }
    return age;
  };
  const handleOnDateValidation = (event: any): void => {
    const { name } = event.target;
    if (name.includes('year')) {
      if (
        getAge() < 18 ||
        handleOnEmptyFields() ||
        parseFloat(date.year) > today.getFullYear() ||
        !handleDataFormat()
      ) {
        setDateErrorMessage(errorMessage);
        handleErrorMessage(true);
      } else {
        setDateErrorMessage('');
        handleErrorMessage(false);
      }
    }
  };
  useEffect(() => {
    if (!handleOnEmptyFields() && handleDataFormat()) handleErrorMessage(false);
  }, [handleDataFormat, handleOnEmptyFields, handleErrorMessage]);
  const handleOnDateChange = (event: DateObject): void => {
    if (isEmptyString(dateErrorMessage)) {
      setDate(event);
      handleDateValue(event);
      handleErrorMessage(false);
    }
    handleErrorMessage(true);
    setDateErrorMessage('');
  };
  return (
    <StyledMemorableDate
      data-testid="dateField"
      label={FieldLabel}
      name="dateField"
      marginBottom="05"
      day={date.day}
      month={date.month}
      year={date.year}
      error={isDateFieldsEmpty ? errorMessage : dateErrorMessage}
      onChange={(event) => handleOnDateChange(event)}
      onBlur={handleOnDateValidation}
    />
  );
}
