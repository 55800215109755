import React, { ReactElement } from 'react';

import { ContentGroup, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useLocation } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';

import { LoginAndRegistrationWssContent } from './LoginAndRegistrationWss.config';
import { StyledForgotCredBox } from './LoginAndRegistrationWss.styled';
import { RootState } from '../../store';
import { changeStepAction } from '../../store/action/formStep.action';
import { toggleAction } from '../../store/action/toggler.action';
import { IMultiAgreementSelectorProps } from '../multiAgreementWss/multiAgreementSelector.config';
import MultiAgreementSelector from '../multiAgreementWss/multiAgreementSelectorWss';

export function MultiAgreement({
  handleResponse,
  isForgotEmail,
  handleMultiAgreement,
}: Partial<IMultiAgreementSelectorProps>): ReactElement {
  const {
    chooseAnAccountLabel,
    agreementNumberLabel,
    vehicleRegistrationLabel,
  } = useContent<LoginAndRegistrationWssContent>();
  const dispatch = useDispatch();
  const location = useLocation();
  const { customerDataMta, customerDataWss, contracts } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const handleOnNavigateMta = (data: object): void => {
    if (location.pathname.includes('/public/login')) {
      handleMultiAgreement(false);
    } else {
      dispatch(changeStepAction(isForgotEmail ? 2 : 1));
      handleResponse([data], {
        isForgotPassword: false,
        isMultiAgreement: true,
      });
      dispatch(toggleAction(false));
    }
  };
  const handleOnNavigateWss = (redirectLink: string): void => {
    window.location.href = redirectLink;
  };
  const handleObsucredNumber = (agreementNumber: string): string =>
    '*'.repeat(Math.max(0, agreementNumber.length - 4)) +
    agreementNumber.slice(-4);
  const renderMTAAgreements = (): ReactElement => {
    return (
      <StyledForgotCredBox marginTop="04" marginBottom="none">
        {customerDataMta.map((data, index, inputArray) => (
          <ContentGroup
            marginBottom={inputArray.length - 1 === index ? 'none' : '04'}
            key={`selector + ${index + 1}`}
          >
            <MultiAgreementSelector
              firstTitle={agreementNumberLabel}
              firstInfo={handleObsucredNumber(
                data.agreementNumber || data.obscuredContractId,
              )}
              secondTitle={vehicleRegistrationLabel}
              secondInfo={`${data.vehicleMakeModel} ${data.vehicleReg}`}
              navigateRoute={() => handleOnNavigateMta(data)}
              testId={`data${index + 1}`}
            />
          </ContentGroup>
        ))}
      </StyledForgotCredBox>
    );
  };
  const renderWSSAgreements = (): ReactElement => {
    return (
      <StyledForgotCredBox marginTop="04" marginBottom="none">
        {customerDataWss.map((data, index, inputArray) => (
          <ContentGroup
            marginBottom={inputArray.length - 1 === index ? 'none' : '04'}
            key={`selector + ${index + 1}`}
          >
            <MultiAgreementSelector
              firstTitle={agreementNumberLabel}
              firstInfo={handleObsucredNumber(
                data.agreementNumber || data.obscuredContractId,
              )}
              secondTitle={vehicleRegistrationLabel}
              secondInfo={`${data.vehicleMakeModel} ${data.vehicleReg}`}
              navigateRoute={() => handleOnNavigateWss(data.redirectLink)}
              testId={`data${index + 1}`}
            />
          </ContentGroup>
        ))}
      </StyledForgotCredBox>
    );
  };

  const renderAgreements = (): ReactElement => {
    if (contracts.isMTA && contracts.isWSS)
      return (
        <>
          {renderMTAAgreements()}
          {renderWSSAgreements()}
        </>
      );
    if (contracts.isWSS) return renderWSSAgreements();
    if (contracts.isMTA) return renderMTAAgreements();
    return null;
  };
  return (
    <ContentGroup marginTop="none" marginBottom="none">
      <Text size="s1">{chooseAnAccountLabel}</Text>
      {renderAgreements()}
    </ContentGroup>
  );
}
