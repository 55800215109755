import React, { ReactElement, useEffect } from 'react';

import { healthcheckAPI } from '../../apis/healthCheck';

export default function TestPage(): ReactElement {
  useEffect(() => {
    healthcheckAPI()
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  });
  return <div>Calling</div>;
}
