import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import {
  ContentGroup,
  GridItem,
  MemorableDate,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import dataQaIds from '../../dataModel/dataQaIds';
import {
  DateObject,
  RegistrationContent,
  RegistrationDetailsProps,
} from '../../routes/registration/Registration.config';
import { dateFormat } from '../../utils/dateFormat';
import { isEmptyString } from '../../utils/isEmptyString';
import { DateProps } from '../appConfig/AppConfig';
import PostcodeField from '../postcodeField/postcodeField';

export default function RegistrationYourDetails({
  handleOnPostCodeError,
  handleOnDateError,
  isContinueClicked,
  handleOnDate,
  handleOnPostCode,
}: RegistrationDetailsProps): ReactElement {
  const {
    dateErrorMessageLabel,
    enterInfoLabel,
    whatIsDobLabel,
    whatIsPostcodeLabel,
  } = useContent<RegistrationContent>();
  const [date, setDate] = useState<DateProps>({
    day: undefined,
    month: undefined,
    year: undefined,
  });
  const [dateErrorMessage, setDateErrorMessage] = useState('');
  const today = new Date();
  const getAge = (): number => {
    const birthDate = new Date(dateFormat(date, '/'));
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }
    return age;
  };
  const emptyDayField = useCallback(
    (): boolean => isEmptyString(date.day),
    [date],
  );
  const emptyMonthField = useCallback(
    (): boolean => isEmptyString(date.month),
    [date],
  );
  const emptyYearField = useCallback(
    (): boolean => isEmptyString(date.year),
    [date],
  );
  const handleOnDateValidation = (event: any): void => {
    const { name } = event.target;
    if (name.includes('year')) {
      if (
        getAge() < 18 ||
        emptyDayField() ||
        emptyMonthField() ||
        emptyYearField() ||
        parseFloat(date.year) > today.getFullYear()
      ) {
        handleOnDateError(true);
        setDateErrorMessage(dateErrorMessageLabel);
      } else {
        handleOnDateError(false);
        setDateErrorMessage('');
      }
    }
  };
  const handleOnDateChange = (event: DateObject): void => {
    setDate(event);
    handleOnDate(event);
    setDateErrorMessage('');
  };
  useEffect(() => {
    const handleClick = (): void => {
      if (
        isContinueClicked &&
        (emptyDayField() || emptyMonthField() || emptyYearField())
      ) {
        setDateErrorMessage(dateErrorMessageLabel);
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [
    dateErrorMessageLabel,
    emptyDayField,
    emptyMonthField,
    emptyYearField,
    isContinueClicked,
  ]);
  return (
    <>
      <GridItem>
        <ContentGroup marginBottom="05">
          <Text size="s2" data-testid="registrationYourDetails-info">
            {enterInfoLabel}
          </Text>
        </ContentGroup>
      </GridItem>
      <GridItem>
        <Text size="s2" weight="bold">
          {whatIsDobLabel}
        </Text>
      </GridItem>
      <ContentGroup marginLeft="03" marginBottom="none" marginTop="none">
        <MemorableDate
          data-testid="dateField"
          onChange={(event) => handleOnDateChange(event)}
          onBlur={handleOnDateValidation}
          marginBottom="05"
          data-qa-id={dataQaIds.loginAndRegistration.dateField}
          label=""
          name="dateField"
          error={dateErrorMessage}
        />
      </ContentGroup>
      <GridItem>
        <ContentGroup marginBottom="none">
          <Text size="s2" weight="bold">
            {whatIsPostcodeLabel}
          </Text>
        </ContentGroup>
      </GridItem>
      <GridItem>
        <ContentGroup marginBottom="07">
          <PostcodeField
            isContinueClicked={isContinueClicked}
            handleOnPostCodeError={handleOnPostCodeError}
            handleOnPostCode={handleOnPostCode}
          />
        </ContentGroup>
      </GridItem>
    </>
  );
}
