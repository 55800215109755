import {
  DataModalInterface,
  MarketingDataModalInterface,
} from '../action/dataModal.action';

export interface IDataModalDetails {
  openDialog: boolean;
  openMarketingDataModal: boolean;
}
const initialState = {
  openDialog: false,
  openMarketingDataModal: false,
};
export const DataModalReducer = (
  state: IDataModalDetails = initialState,
  action: Partial<DataModalInterface> & Partial<MarketingDataModalInterface> = {
    type: '',
    ...initialState,
  },
): IDataModalDetails => {
  if (action.type === 'OPEN_DATAMODAL') {
    return {
      ...state,
      openDialog: action.openDialog,
    };
  }
  if (action.type === 'MARKETING_DATAMODAL') {
    return {
      ...state,
      openMarketingDataModal: action.openMarketingDataModal,
    };
  }
  return state;
};
