import React, { ReactElement, useRef, useState } from 'react';

import { Grid, Heading } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { RegistrationWSSProps } from './LoginAndRegistrationWss.config';
import { StyledNoPaddingGridItem } from './LoginAndRegistrationWss.styled';
import { authContractsAPI } from '../../apis/authContractsApi';
import InfoIcon from '../../assets/icons/infoIcon';
import WarningIcon from '../../assets/icons/warningIcon';
import { DateProps } from '../../components/appConfig/AppConfig';
import DataModal from '../../components/dataModal/dataModal';
import SpinnerContainer from '../../components/spinner/spinnerContainer';
import YourDetailsWss from '../../components/yourDetailsWss/yourDetailsWss';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import {
  DOBPostcodeContractsDataAction,
  UpdateDOBPostcodeAction,
} from '../../store/action/registration.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { dateFormat } from '../../utils/dateFormat';
import * as routes from '../manifest';
import {
  RegistrationContent,
  RegistrationModalParams,
} from '../registration/Registration.config';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function RegistrationWss({
  handleOnPassDetails,
  handleSingleAgreementData,
  handleIsRegisteredMta,
  handleIsContactFoundMta,
  isRegisteredMta,
  isContactFoundMta,
  setCreateAccountFAQ,
}: RegistrationWSSProps): ReactElement {
  const {
    createAccountLabel,
    createOnlineAccountText,
    PostcodeAdditionalInfo,
    nextButtonLabel,
    noCustomerRecordFoundHeading,
    detailsNotFoundSubText,
    tryAgainLink,
    needHelpText,
    apiErrorMessage,
    alreadyRegisteredLabel,
    alreadyRegisteredDescription,
    registrationLabel,
    noContactFoundHeading,
    noContactFoundDescription,
    noContactFoundApiError,
    contactUsLink,
    dataRetrievalErrorDescription,
    dataRetrievalErrorHeading,
    phoneNumber,
  } = useContent<RegistrationContent>();
  const isDeviceFound = useDeviceResizing();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme: any = useTheme();
  const { showSpinner } = useSelector((state: RootState) => state.UtilsReducer);
  const { constraints } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const [postcode, setPostCode] = useState('');
  const [moreThanOneCustomer, setMoreThanOneCustomer] = useState(
    constraints.isMoreThanOneUser,
  );
  const [isDataFound, setIsDataFound] = useState(constraints.isDetailsFound);
  const [isApiFailure, setIsApiFailure] = useState(constraints.isApiFailed);
  const [isRegisteredWSS, setIsRegisteredWSS] = useState(false);
  const [WSSLoginLink, setWSSLoginLink] = useState('');
  const postCodeValue = useRef('');
  const handleNeedHelpNavigate = (): void => {
    navigate(routes.HelpCentre);
  };
  const handleOnPostCode = (value: string): void => {
    postCodeValue.current = value;
    setPostCode(value);
  };

  const checkIsMta = (data: any): boolean =>
    Object.keys(data).includes('isMTA');
  const checkIsWss = (data: any): boolean =>
    Object.keys(data).includes('isWSS');
  const handleMultiContractCondition = (res: any) =>
    Object.keys(res).length > 1 ||
    res.isMTA?.length > 1 ||
    res.isWSS?.length > 1;
  const handleOnPostcodeFormat = (): string =>
    `${postcode.split(' ').join('')}`;
  const handleModelButtonClick = (): void => {
    navigate(routes.Registration);
    dispatch(DataModelAction(false));
    dispatch(DOBPostcodeContractsDataAction([], { isDetailsFound: true }));
    setIsDataFound(true);
  };
  const handleLoginNavigateMTA = (): void => {
    navigate(routes.LoginWss);
    dispatch(DOBPostcodeContractsDataAction([], { isDetailsFound: true }));
    dispatch(DataModelAction(false));
  };
  const handleLoginNavigateWSS = (): void => {
    window.location.href = WSSLoginLink;
  };
  const handleApiError = (err: any) => {
    if (err.message === apiErrorMessage) {
      setIsDataFound(false);
      dispatch(DataModelAction(true));
      dispatch(DOBPostcodeContractsDataAction([], { isDetailsFound: false }));
    } else if (err.errors === noContactFoundApiError) {
      handleIsContactFoundMta(false);
      dispatch(DataModelAction(true));
    } else {
      setIsApiFailure(true);
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: true,
          isApiFailed: true,
        }),
      );
      dispatch(DataModelAction(true));
    }
  };
  const handleContactUsLink = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const renderModal = ({
    heading,
    description,
    dataQaId,
    isPhoneLink,
    link,
    onClickLink,
  }: RegistrationModalParams): ReactElement => {
    return (
      <DataModal
        brand={undefined}
        icon={
          <WarningIcon
            width="72"
            height="72"
            colour={theme.color_icon_inverse_default_2}
          />
        }
        heading={heading}
        description={description}
        isPhoneLink={isPhoneLink}
        buttonTxt={tryAgainLink}
        link={link}
        onClick={handleModelButtonClick}
        onClickLink={onClickLink}
        dataQaId={dataQaId}
        isWss
      />
    );
  };
  const handleSingleAgreement = (res: any) => {
    if (checkIsMta(res)) {
      handleSingleAgreementData(res.isMTA);
    } else if (checkIsWss(res) && !res.isWSS[0].isRegistered) {
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: true,
          isApiFailed: false,
          isMoreThanOneUser: false,
        }),
      );
      window.location.href = res.isWSS[0].redirectLink;
    } else if (checkIsWss(res) && res.isWSS[0].isRegistered) {
      dispatch(DataModelAction(true));
      setIsRegisteredWSS(true);
      setWSSLoginLink(res.isWSS[0].redirectLink);
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: true,
          isApiFailed: false,
          isMoreThanOneUser: false,
        }),
      );
    }
  };

  const handleApiResponse = (res: any) => {
    if (handleMultiContractCondition(res)) {
      dispatch(DOBPostcodeContractsDataAction([], { isMoreThanOneUser: true }));
      setMoreThanOneCustomer(true);
    } else if (Object.keys(res).length === 1) {
      handleSingleAgreement(res);
    }
  };

  const handleClick = (date: DateProps) => {
    dispatch(UpdateDOBPostcodeAction(postcode, date, false));
    dispatch(ShowHideSpinnerAction(true));
    authContractsAPI(
      `${dateFormat(date, '-')}/${handleOnPostcodeFormat()}`,
      '1',
    )
      .then((res: any) => {
        handleApiResponse(res);
        dispatch(ShowHideSpinnerAction(false));
      })
      .catch((err) => {
        handleApiError(err);
        dispatch(ShowHideSpinnerAction(false));
      });
    handleIsRegisteredMta(false);
  };

  const renderContent = (): ReactElement => (
    <>
      <StyledNoPaddingGridItem lg={3.8} md={6.5}>
        <Grid alignX="center">
          <Heading
            size="s5"
            marginTop={
              !isDeviceFound.mobile && !isDeviceFound.tablet ? '09' : '07'
            }
            marginBottom="07"
            aria-level={4}
          >
            {createAccountLabel}
          </Heading>
        </Grid>
        <YourDetailsWss
          isMoreThanOneCustomer={moreThanOneCustomer}
          headingText={createOnlineAccountText}
          subText={PostcodeAdditionalInfo}
          isRegistraionWss
          buttonText={nextButtonLabel}
          isForgotUserIdWss={false}
          handleOnPostCode={handleOnPostCode}
          handleClick={handleClick}
          handleOnPassDetails={handleOnPassDetails}
          setCreateAccountFAQ={setCreateAccountFAQ}
        />
      </StyledNoPaddingGridItem>
      {!isDataFound &&
        renderModal({
          heading: noCustomerRecordFoundHeading,
          description: detailsNotFoundSubText,
          dataQaId: {
            btn: dataQaIds.loginAndRegistration.tryAgainWssBtn,
            link: dataQaIds.loginAndRegistration.needHelpLink,
          },
          isPhoneLink: true,
          link: needHelpText,
          onClickLink: handleNeedHelpNavigate,
        })}
      {!isContactFoundMta &&
        !isApiFailure &&
        isDataFound &&
        !moreThanOneCustomer &&
        renderModal({
          heading: noContactFoundHeading,
          description: noContactFoundDescription,
          dataQaId: {
            btn: dataQaIds.loginAndRegistration.tryAgainWssBtn,
            link: dataQaIds.loginAndRegistration.needHelpLink,
          },
          isPhoneLink: true,
          link: needHelpText,
          onClickLink: handleNeedHelpNavigate,
        })}
      {(isRegisteredMta || isRegisteredWSS) && (
        <DataModal
          brand={undefined}
          icon={<InfoIcon colour={theme.color_icon_inverse_default_2} />}
          heading={alreadyRegisteredLabel}
          description={alreadyRegisteredDescription}
          buttonTxt={registrationLabel}
          link={needHelpText}
          onClick={() =>
            isRegisteredMta
              ? handleLoginNavigateMTA()
              : handleLoginNavigateWSS()
          }
          onClickLink={handleNeedHelpNavigate}
          dataQaId={{
            btn: dataQaIds.loginAndRegistration.loginWssBtn,
            link: dataQaIds.loginAndRegistration.needHelpLink,
          }}
          isWss
        />
      )}
      {isApiFailure &&
        renderModal({
          heading: dataRetrievalErrorHeading,
          description: dataRetrievalErrorDescription,
          dataQaId: {
            btn: dataQaIds.loginAndRegistration.tryAgainWssBtn,
            link: dataQaIds.loginAndRegistration.contactUsLink,
          },
          isPhoneLink: false,
          link: contactUsLink,
          onClickLink: handleContactUsLink,
        })}
    </>
  );
  return showSpinner ? <SpinnerContainer brand={undefined} /> : renderContent();
}
