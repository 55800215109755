import React, { ReactElement } from 'react';

import { ContentGroup, GridItem, Text } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import {
  RegistrationAuthenticateProps,
  RegistrationContent,
} from '../../routes/registration/Registration.config';

export default function RegistrationAuthenticate({
  phoneNumber,
  isNumberFound,
}: RegistrationAuthenticateProps): ReactElement {
  const { verifyText, cantVerifyNumberLabel, authSubText, pleaseCallUsLabel } =
    useContent<RegistrationContent>();
  return (
    <>
      <GridItem>
        <ContentGroup marginBottom="02">
          <Text size="s4">
            {isNumberFound ? verifyText : cantVerifyNumberLabel}
          </Text>
        </ContentGroup>
      </GridItem>
      <GridItem>
        <ContentGroup marginBottom="05" marginTop="none">
          <Text size="s2">
            {isNumberFound ? authSubText : pleaseCallUsLabel}
          </Text>
        </ContentGroup>
      </GridItem>
      {isNumberFound && (
        <GridItem>
          <ContentGroup marginBottom="05">
            <Text size="s2" color="subdued">
              {phoneNumber}
            </Text>
          </ContentGroup>
        </GridItem>
      )}
    </>
  );
}
