import React, { ReactElement } from 'react';

import { ILinkButtonProps } from './LinkButton.config';
import { StyledLinkBtn } from './styledLinkButton';

export default function LinkButton({
  children,
  handleButtonClick,
  testId,
  dataQaId,
}: ILinkButtonProps): ReactElement {
  return (
    <StyledLinkBtn
      className="linkBtn"
      onClick={handleButtonClick}
      data-testid={testId}
      data-qa-id={dataQaId}
    >
      {children}
    </StyledLinkBtn>
  );
}
