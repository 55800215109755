import common from './common';
import cookiePolicy from './cookiePlicy';
import footer from './footer';
import forgotEmail from './forgotEmail';
import forgotPassword from './forgotPassword';
import header from './header';
import helpCentre from './helpCentre';
import loginAndRegistrationWss from './loginAndRegistrationWss';
import marketingPreferences from './marketingPreferences';
import needHelp from './needHelp';
import notfound from './notfound';
import postcode from './postcode';
import privacyPolicy from './privacyPolicy';
import registration from './registration';
import termsAndConditions from './termsAndConditions';
import welcome from './welcome';
import { AppContent } from '../AppContent';

const content: AppContent = {
  registration,
  needHelp,
  postcode,
  notfound,
  common,
  welcome,
  forgotEmail,
  forgotPassword,
  loginAndRegistrationWss,
  helpCentre,
  termsAndConditions,
  cookiePolicy,
  privacyPolicy,
  marketingPreferences,
  layoutContent: { ...header, ...footer },
};

export default content;
