import { DateProps } from '../components/appConfig/AppConfig';

export const dateFormat = (date: DateProps, separator: string): string =>
  `${date.year ?? ''}${separator}${String(date.month ?? '').padStart(
    2,
    '0',
  )}${separator}${String(date.day ?? '').padStart(2, '0')}`;

export const passwordExpiryDateFormat = (date: string): string => {
  const newDate = date.substring(0, date.length - 1);
  const timeDifference = Math.max(
    0,
    new Date(newDate).getTime() - new Date().getTime(),
  );
  return Math.floor(timeDifference / 1000 / 60).toString();
};
