import React, { ReactElement } from 'react';

import { FormStepperProps } from './FormStepper.config';
import {
  StyledSpan,
  StyledOuterStepper,
  StyledStepperContainer,
} from './styledFormStepper';

export default function FormStepper({
  activeStep,
  numberOfSteps,
}: FormStepperProps): ReactElement {
  const buildStepsArray = (): number[] => {
    const stepsArray = [];
    for (let i = 1; i < numberOfSteps + 1; i += 1) {
      stepsArray.push(i);
    }
    return stepsArray;
  };

  return (
    <StyledStepperContainer>
      {buildStepsArray().map((i) => (
        <StyledOuterStepper
          sm={numberOfSteps === 2 ? 'fill' : 4}
          isLast={i === numberOfSteps}
          isFirst={i === 1}
          data-testid="stepper"
          key={i}
        >
          <StyledSpan key={i} isActive={i <= activeStep} />
        </StyledOuterStepper>
      ))}
    </StyledStepperContainer>
  );
}
