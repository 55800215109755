export interface IProfileState {
  businessPartnerId: string;
  customerCmsInfo: {
    info: {
      surname: string;
      customerType?: string;
      dateOfBirth: string;
      gender: string;
      vatCode: string;
      type?: string;
    };
    customerStatuses: {
      internalCode: string;
      externalCode: string;
      description: string;
    };
    documentDelivery: {
      code: string;
      description: string;
    };
    address: {
      addressType: string;
      addressId: string;
      houseNo: string;
      houseName: string;
      streetNumber: string;
      streetName: string;
      town: string;
      postcode: string;
    };
    marketingPreferences: object;
    isMarketingPrefUpdated: boolean;
    updatedDate?: string;
    emailAddress: string;
  };
  updateMarketingAPISuccess?: boolean;
  updateMarketingAPIFail?: boolean;
  customerType?: string;
  marketingPreferences: {
    email: string;
    textMessage: string;
    phone: string;
    post: string;
    onlineAccount: string;
    thirdPartyOffers: string;
    emailDataCollection: string;
  };
  otherCustomAttributes: object;
  emailAddress: string;
  documentDelivery: {
    code: string;
    description: string;
  };
  lastLoginTime: string;
  isShowMarketingPref: boolean;
  authLiteUUID: string;
  isMarketingPrefDataModal?: boolean;
  previousPage?: string;
}

export interface ICustomerContactNumber {
  contact_number: string;
  contact_number_type_desc: string;
}
const MarketingCodeInfo = {
  mktngprefEmail: 'email',
  mktngprefPost: 'post',
  mktngprefemaildatacollection: 'emailDataCollection',
  mktngprefdigitalinbox: 'onlineAccount',
  mktngprefPhone: 'phone',
  mktngprefSMS: 'textMessage',
  mktngprefthirdpartyoffer: 'thirdPartyOffers',
};

const MarketingValue = {
  Y: 'yes',
  N: 'no',
};

const initialState = {
  businessPartnerId: '',
  customerCmsInfo: {
    info: {
      surname: '',
      customerType: '',
      dateOfBirth: '',
      gender: '',
      vatCode: '1000',
      type: '',
    },
    customerStatuses: {
      internalCode: '',
      externalCode: '',
      description: '',
    },
    documentDelivery: {
      code: '',
      description: '',
    },
    address: {
      addressType: '',
      addressId: '',
      houseNo: '',
      houseName: '',
      streetNumber: '',
      streetName: '',
      town: '',
      postcode: '',
    },
    marketingPreferences: {},
    updatedDate: '',
    isMarketingPrefUpdated: false,
    emailAddress: '',
  },
  updateMarketingAPISuccess: false,
  updatedDate: '',
  updateMarketingAPIFail: false,
  customerType: '',
  marketingPreferences: {
    email: '',
    textMessage: '',
    phone: '',
    post: '',
    onlineAccount: '',
    thirdPartyOffers: '',
    emailDataCollection: '',
  },
  otherCustomAttributes: {},
  emailAddress: '',
  documentDelivery: {
    code: '',
    description: '',
  },
  lastLoginTime: '',
  isShowMarketingPref: false,
  authLiteUUID: '',
  isMarketingPrefDataModal: false,
  previousPage: '',
};

const getMarketingPrefrences = (info): object => {
  const marketingPreferences = {};

  if (info !== undefined) {
    Object.keys(info)?.forEach((val): void => {
      if (MarketingCodeInfo[info[val].code] !== undefined) {
        marketingPreferences[MarketingCodeInfo[info[val].code]] =
          MarketingValue[info[val].value];
      }
    });
  }
  return marketingPreferences;
};

export const ProfileReducer = (
  state: IProfileState = initialState,
  action: any = {},
): IProfileState => {
  if (action.type === 'PROFILE_INFO') {
    return {
      ...state,
      customerType: action.profileInfo.cmsInfo?.info?.customerType,
      businessPartnerId: action.profileInfo.mtaInfo[0]?.businessPartnerId,
      customerCmsInfo: action.profileInfo.cmsInfo,
      emailAddress: action.profileInfo.cmsInfo?.emailAddress
        ? action.profileInfo.cmsInfo.emailAddress
        : action.profileInfo.mtaInfo[0]?.emailAddress,
      marketingPreferences: {
        ...initialState.marketingPreferences,
        ...getMarketingPrefrences(
          action.profileInfo.cmsInfo?.marketingPreferences,
        ),
      },
      documentDelivery: {
        code: action.profileInfo.cmsInfo?.documentDelivery?.code,
        description: action.profileInfo.cmsInfo?.documentDelivery?.description,
      },
      otherCustomAttributes: action.profileInfo.cmsInfo?.otherCustomAttributes,
      lastLoginTime: action.profileInfo.mtaInfo[0]?.lastLoginTime || '',
      authLiteUUID: action.profileInfo.mtaInfo[0]?.authLiteUUID || '',
    };
  }
  if (action.type === 'MARKETING_PREFS_FAIL') {
    return {
      ...state,
      isMarketingPrefDataModal: action.isMarketingPrefDataModal,
    };
  }
  return state;
};
