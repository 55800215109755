import React, { ReactElement, useEffect, useState } from 'react';

import {
  Table,
  TableRow,
  TableCol,
  Text,
  TextField,
  Button,
} from '@constellation/core';
import axios from 'axios';

import { authLoginApi } from '../../apis/authLoginApi';
import { ApiEndpoints } from '../../constants/apiEndpoints';

export default function APIDownTable(): ReactElement {
  const [healthCheckDbStatus, setHealthCheckDbStatus] = useState('');
  const [getCustomerStatus, setGetCustomerStatus] = useState('');
  const [customerTestEmail, setCustomerTestEmail] = useState('');
  const [customerTestPassword, setCustomerTestPassword] = useState('');
  const [authLoginApiStatus, setAuthLoginApiStatus] = useState('');

  const config = {
    headers: {
      'content-Type': 'application/json',
      'x-lbg-user-id': '12',
      'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
    },
  };

  useEffect(() => {
    axios
      .get(
        `${window.appConfig.REACT_APP_API_URL}${window.appConfig.REACT_APP_UNAUTH_PROXY}/api/healthcheck`,
        {
          headers: {
            ...config.headers,
            'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
          },
        },
      )
      .then((res: any) => {
        setHealthCheckDbStatus(res.data.mtaDbApi.code);
      })
      .catch((err) => {
        setHealthCheckDbStatus(err.status);
      });
  });

  const handleEmailChange = (e) => {
    setCustomerTestEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setCustomerTestPassword(e.target.value);
  };

  const handleLoginApiStatus = () => {
    const formData = new FormData();
    formData.append('username', customerTestEmail);
    formData.append('password', customerTestPassword);
    authLoginApi(formData)
      .then((res: any) => {
        setAuthLoginApiStatus(res.response.status);
      })
      .catch((err) => {
        setAuthLoginApiStatus(err.response.status);
      });
  };

  const handleGetCustomerStatus = () => {
    axios
      .get(
        `${window.appConfig.REACT_APP_API_URL}${window.appConfig.REACT_APP_AUTH_PROXY}/mta/api/v1/${ApiEndpoints.CONTRACT_CUSTOMERS}/?email=${customerTestEmail}`,
        {
          headers: {
            ...config.headers,
            'x-lbg-client-id': window.appConfig.REACT_APP_CLIENT_ID,
          },
        },
      )
      .then((res: any) => {
        setGetCustomerStatus(res.status);
      })
      .catch((err) => {
        setGetCustomerStatus(err.response.status);
      });
  };

  const handleButtonClick = () => {
    handleGetCustomerStatus();
    handleLoginApiStatus();
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          label="Enter requirements for API calls"
          name="emailInput"
          type="email"
          placeholder="email address"
          value={customerTestEmail}
          onChange={handleEmailChange}
        />
        <TextField
          label="."
          name="passwordInput"
          type="password"
          placeholder="password"
          value={customerTestPassword}
          onChange={handlePasswordChange}
        />
        <Button data-testid="submitButton" onClick={handleButtonClick}>
          Submit
        </Button>
      </div>
      <div>
        <Table
          data-testid="apiTable"
          style={{ backgroundColor: 'black' }}
          headings={['API Name', 'Status Code']}
        >
          <TableRow>
            <TableCol>
              <Text>Health Check DB</Text>
            </TableCol>
            <TableCol>
              <Text>{healthCheckDbStatus}</Text>
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol>
              <Text>Auth Login</Text>
            </TableCol>
            <TableCol>
              <Text>{authLoginApiStatus}</Text>
            </TableCol>
          </TableRow>
          <TableRow>
            <TableCol>
              <Text>Get Customer</Text>
            </TableCol>
            <TableCol>
              <Text>{getCustomerStatus}</Text>
            </TableCol>
          </TableRow>
        </Table>
      </div>
    </div>
  );
}
