import { Button } from '@constellation/core';
import styled from 'styled-components';

import { device } from '../../utils/breakpoints';

export const StyledMarginButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.spacing_04};

  @media ${device.md} {
    margin-bottom: ${({ theme }) => theme.spacing_none};
    margin-right: ${({ theme }) => theme.spacing_04};
  }
`;
export const StyledSvg = styled('svg')`
  margin-right: 0.0625rem;
`;
