import registration from './registration';
import { AppContent } from '../AppContent';

const forgotEmail: AppContent['forgotEmail'] = {
  emailNotFoundHeading: `We can't find your details in our records`,
  noCustomerFoundDescription:
    'This is because the incorrect details have been entered or you have not yet signed an agreement.',
  ...registration,
};

export default forgotEmail;
