import React, { ReactElement } from 'react';

import { useContent } from '@interstellar/react-app-content';
import { useSelector } from 'react-redux';

import { CookiePolicyContent } from './CookiePloicy.config';
import {
  StyledFooterList,
  StyledHelpListItem,
  StyledInnerDiv,
} from './cookiePolicy.styled';
import LinkButton from '../../components/linkButton/LinkButton';
import { useDeviceResizing } from '../../customHooks/useDeviceResizing';
import { RootState } from '../../store';
import { StyledContainer } from '../helpCentre/helpCentre.styled';
import {
  StyledBoldedParagraph,
  StyledBoldParagraph,
  StyledBox,
  StyledHeading,
  StyledGoBack,
  StyledBottomHr,
} from '../termsAndConditions/termsAndConditions.styled';

export default function CookiePolicy(): ReactElement {
  const {
    CookieHeading,
    CookieLastUpdated,
    CookieTitle,
    CookieData,
    CookieDataCollectTo,
    CookieListfraud,
    CookieListService,
    CookieListProduct,
    CookieListMarketing,
    CookieListShareData,
    CookieSellData,
    CookiesWhatIsHeading,
    CookiesDefenition,
    MultipleUsersHeading,
    MultipleUsersTxt,
    CookiesSessionHeading,
    CookiesSessionContent,
    SessionCookies,
    PersistentCookies,
    CookieAdsAndMarketing,
    CookieFirstAndThirdHeading,
    CookieFirstAndThirdContent,
    CookieFirstWebsite,
    CookieFirstWebsiteContent,
    ThirdPartyHeading,
    ThirdPartyConsent,
    ThirdPartyChoice,
    ThirdPartyMobile,
    ThirdPartyLinks,
    GoogleLinks,
    GoogleCookies,
    GoogleData,
    GoodleType,
    OracleLinks,
    OracleData,
    OracleCookies,
    MetaLinks,
    MetaCookies,
    MetaPrivacy,
    MicrosoftLinks,
    MicrosoftLegal,
    MicrosoftPrivacy,
    LinkedinLinks,
    LinkedInCookies,
    CookiesUses,
    CookiesUsesTxt,
    CookiesOnDevice,
    CookiesOnDeviceTxt,
    CookiesBrowser,
    BrowserDelete,
    BrowserReplace,
    BrowserManage,
    EmailTrackingHeader,
    EmailTrackingSub,
    EmailTrackingTxt,
    EmailTrackingList,
    EmailTrackingListHow,
    EmailTrackingListOpen,
    EmailPixel,
    StopEmailHeading,
    StopBy,
    StopClose,
    StopRestrict,
    StopBrowser,
    TrackingMarketing,
    MarketingTxt,
    MarketingSubTxt,
    MarketingInternet,
    MarketingBranch,
    MarketingContact,
    EmailLinkHeading,
    EmailLinkTxt,
    StopEmailLink,
    StopEmailTxt,
    CookieEssentialHeading,
    CookieEssentialContent,
    CookieCategoryHeading,
    CookieStrictlyNecessary,
    CookieWhatTheyDoHeading,
    CookieWhatTheyDoContent,
    CookieDetailsSafe,
    CookieOtherJobs,
    CookieHelpMove,
    CookieListTellUs,
    CookieListProblemFix,
    CookieMyChoicesHeading,
    CookieMyChoiceContent,
    CookieFunctionalTitle,
    CookieRememberTitle,
    CookieListId,
    CookieListCountry,
    CookieListLanguage,
    CookieListAccessibility,
    CookieCantTurnOFF,
    CookiePerformanceTitle,
    CookieStudyTitle,
    CookieImprovePerformance,
    CookieImproveProducts,
    CookieConsentTitle,
    CookieMarketingTitle,
    CookieDecideTitle,
    CookieUseDataTitle,
    CookieOnlineAds,
    CookieChoice,
    goBackLink,
  } = useContent<CookiePolicyContent>();
  const handleOnNavigateBack = () => {
    window.history.back();
  };
  const isDeviceFound = useDeviceResizing();
  const { isWss } = useSelector((state: RootState) => state.TogglerReducer);
  const handleOnListItems = (txt: string): ReactElement => (
    <StyledFooterList marginBottom="none">
      <StyledHelpListItem>{txt}</StyledHelpListItem>
    </StyledFooterList>
  );
  const handleOnParagraph = (
    isBold: boolean,
    txt: string,
    marginBottom?: any,
    marginTop?: any,
    isSubheading = false,
  ): ReactElement => (
    <StyledBoldedParagraph
      isSubheading={isSubheading}
      size="s2"
      marginTop={marginTop}
      marginBottom={marginBottom}
      isBold={isBold}
      dangerouslySetInnerHTML={{ __html: txt }}
    >
      {}
    </StyledBoldedParagraph>
  );
  const handleOnHeading = (txt: string): ReactElement => (
    <StyledBoldParagraph marginBottom="none" marginTop="05">
      {txt}
    </StyledBoldParagraph>
  );

  return (
    <StyledContainer lg={8} md={8} className={isWss ? 'wssTheme' : 'BHTheme'}>
      <StyledBox marginBottom="07" marginTop="07">
        <StyledInnerDiv
          className={isDeviceFound.mobile ? 'mobileFound' : 'notMobile'}
        >
          <StyledHeading size="s4" marginBottom="07">
            {CookieHeading}
          </StyledHeading>
          {handleOnParagraph(true, CookieLastUpdated)}
          {handleOnParagraph(false, CookieTitle)}
          {handleOnParagraph(false, CookieData)}
          {handleOnParagraph(false, CookieDataCollectTo, 'none')}
          {handleOnListItems(CookieListfraud)}
          {handleOnListItems(CookieListService)}
          {handleOnListItems(CookieListProduct)}
          {handleOnListItems(CookieListMarketing)}
          {handleOnParagraph(false, CookieListShareData, '05', '07')}
          {handleOnParagraph(false, CookieSellData, 'none', '05')}
          {handleOnHeading(CookiesWhatIsHeading)}
          {handleOnParagraph(false, CookiesDefenition)}
          {handleOnHeading(MultipleUsersHeading)}
          {handleOnParagraph(false, MultipleUsersTxt)}
          {handleOnHeading(CookiesSessionHeading)}
          {handleOnParagraph(false, CookiesSessionContent)}
          {handleOnParagraph(false, SessionCookies)}
          {handleOnParagraph(false, PersistentCookies)}
          {handleOnParagraph(false, CookieAdsAndMarketing)}
          {handleOnHeading(CookieFirstAndThirdHeading)}
          {handleOnParagraph(false, CookieFirstAndThirdContent)}
          {handleOnParagraph(false, CookieFirstWebsite)}
          {handleOnParagraph(false, CookieFirstWebsiteContent)}
          {handleOnHeading(ThirdPartyHeading)}
          {handleOnParagraph(false, ThirdPartyConsent)}
          {handleOnParagraph(false, ThirdPartyChoice)}
          {handleOnParagraph(false, ThirdPartyMobile)}
          {handleOnParagraph(false, ThirdPartyLinks)}
          {handleOnHeading(GoogleLinks)}
          {handleOnParagraph(false, GoogleCookies)}
          {handleOnParagraph(false, GoogleData)}
          {handleOnParagraph(false, GoodleType)}
          {handleOnHeading(OracleLinks)}
          {handleOnParagraph(false, OracleData)}
          {handleOnParagraph(false, OracleCookies)}
          {handleOnHeading(MetaLinks)}
          {handleOnParagraph(false, MetaCookies)}
          {handleOnParagraph(false, MetaPrivacy)}
          {handleOnHeading(MicrosoftLinks)}
          {handleOnParagraph(false, MicrosoftLegal)}
          {handleOnParagraph(false, MicrosoftPrivacy)}
          {handleOnHeading(LinkedinLinks)}
          {handleOnParagraph(false, LinkedInCookies)}
          {handleOnHeading(CookiesUses)}
          {handleOnParagraph(false, CookiesUsesTxt)}
          {handleOnHeading(CookiesOnDevice)}
          {handleOnParagraph(false, CookiesOnDeviceTxt)}
          {handleOnHeading(CookiesBrowser)}
          {handleOnParagraph(false, BrowserDelete)}
          {handleOnParagraph(false, BrowserReplace)}
          {handleOnParagraph(false, BrowserManage)}
          {handleOnHeading(EmailTrackingHeader)}
          {handleOnParagraph(false, EmailTrackingSub)}
          {handleOnParagraph(false, EmailTrackingTxt)}
          {handleOnListItems(EmailTrackingList)}
          {handleOnListItems(EmailTrackingListHow)}
          {handleOnListItems(EmailTrackingListOpen)}
          {handleOnParagraph(false, EmailPixel, '05', '05')}
          {handleOnHeading(StopEmailHeading)}
          {handleOnParagraph(false, StopBy)}
          {handleOnListItems(StopClose)}
          {handleOnListItems(StopRestrict)}
          {handleOnParagraph(false, StopBrowser, '05', '05')}
          {handleOnHeading(TrackingMarketing)}
          {handleOnParagraph(false, MarketingTxt)}
          {handleOnParagraph(false, MarketingSubTxt)}
          {handleOnListItems(MarketingInternet)}
          {handleOnListItems(MarketingBranch)}
          {handleOnListItems(MarketingContact)}
          {handleOnHeading(EmailLinkHeading)}
          {handleOnParagraph(false, EmailLinkTxt)}
          {handleOnHeading(StopEmailLink)}
          {handleOnParagraph(false, StopEmailTxt)}
          {handleOnHeading(CookieEssentialHeading)}
          {handleOnParagraph(false, CookieEssentialContent)}
          {handleOnHeading(CookieCategoryHeading)}
          {handleOnParagraph(false, CookieStrictlyNecessary)}
          {handleOnParagraph(true, CookieWhatTheyDoHeading, 'none', '05')}
          {handleOnParagraph(false, CookieWhatTheyDoContent)}
          {handleOnParagraph(false, CookieDetailsSafe)}
          {handleOnParagraph(false, CookieOtherJobs, 'none')}
          {handleOnListItems(CookieHelpMove)}
          {handleOnListItems(CookieListTellUs)}
          {handleOnListItems(CookieListProblemFix)}
          {handleOnParagraph(true, CookieMyChoicesHeading, 'none', '07')}
          {handleOnParagraph(false, CookieMyChoiceContent)}
          {handleOnParagraph(true, CookieCategoryHeading, 'none', '05')}
          {handleOnParagraph(false, CookieFunctionalTitle)}
          {handleOnParagraph(true, CookieWhatTheyDoHeading, 'none', '05')}
          {handleOnParagraph(false, CookieRememberTitle, 'none')}
          {handleOnListItems(CookieListId)}
          {handleOnListItems(CookieListCountry)}
          {handleOnListItems(CookieListLanguage)}
          {handleOnListItems(CookieListAccessibility)}
          {handleOnParagraph(true, CookieMyChoicesHeading, 'none', '07')}
          {handleOnParagraph(false, CookieCantTurnOFF)}
          {handleOnParagraph(true, CookieCategoryHeading, 'none', '05')}
          {handleOnParagraph(false, CookiePerformanceTitle)}
          {handleOnParagraph(true, CookieWhatTheyDoHeading, 'none', '05')}
          {handleOnParagraph(false, CookieStudyTitle, 'none')}
          {handleOnListItems(CookieImprovePerformance)}
          {handleOnListItems(CookieImproveProducts)}
          {handleOnParagraph(true, CookieMyChoicesHeading, 'none', '07')}
          {handleOnParagraph(false, CookieConsentTitle)}
          {handleOnParagraph(true, CookieCategoryHeading, 'none', '05')}
          {handleOnParagraph(false, CookieMarketingTitle)}
          {handleOnParagraph(true, CookieWhatTheyDoHeading, 'none', '05')}
          {handleOnParagraph(false, CookieDecideTitle)}
          {handleOnParagraph(false, CookieUseDataTitle)}
          {handleOnParagraph(false, CookieOnlineAds)}
          {handleOnParagraph(false, CookieChoice)}
          {handleOnHeading(CookieMyChoicesHeading)}
          {handleOnParagraph(false, CookieConsentTitle)}
        </StyledInnerDiv>
        <StyledBottomHr />
        <StyledGoBack>
          <LinkButton
            handleButtonClick={handleOnNavigateBack}
            testId="goBackLink"
          >
            {goBackLink}
          </LinkButton>
        </StyledGoBack>
      </StyledBox>
    </StyledContainer>
  );
}
