import React, { ReactElement } from 'react';

import {
  Container,
  Grid,
  GridItem,
  useWindowSize,
  pxToRem,
  BackgroundProvider,
  ContentGroup,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import {
  NavLink,
  useLocation,
  useNavigate,
} from '@interstellar/react-app-routing';
import { useTheme } from 'styled-components';

import { IHeaderFooterProps } from './LoginAndRegistrationWss.config';
import {
  StyledWssHeader,
  StyledLinkWss,
} from './LoginAndRegistrationWss.styled';
import ArrowRightIcon from '../../assets/icons/arrowRightIcon';
import { HeaderContent } from '../../components/header/HeaderComponent.config';
import { StyledHeader } from '../../components/header/HeaderComponent.styled';
import * as routes from '../manifest';

export default function HeaderWss({ isWss }: IHeaderFooterProps): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const { width: windowWidth } = useWindowSize();

  const isNarrow = pxToRem(windowWidth) < parseFloat(theme.breakpoint_lg);
  const isWide = !isNarrow;

  const Logo = isWide ? theme.assets.logo.wide : theme.assets.logo.base;
  const { loginLink } = useContent<HeaderContent>();

  const handleNavigate = () => {
    navigate(routes.LoginWss);
  };
  const renderWssHeader = (): ReactElement => (
    <StyledWssHeader>
      <BackgroundProvider value={{ cssValue: theme.header_color_background }}>
        <Container>
          <Grid alignY="center">
            <GridItem xs={4}>
              <NavLink
                to={routes.WelcomePage}
                title="logo"
                data-testid="navLink"
              >
                <Logo
                  style={{
                    height: isWide
                      ? theme.header_logo_height_big
                      : theme.header_logo_height,
                    width: isWide
                      ? theme.header_logo_width_big
                      : theme.header_logo_width,
                  }}
                />
              </NavLink>
            </GridItem>
            <GridItem xs={6} />
            <GridItem xs={2}>
              {![
                '/public/forgot-email',
                '/public/forgot-password',
                '/public/login',
              ].includes(location.pathname) && (
                <ContentGroup marginBottom="none" marginRight="05">
                  <Grid alignY="center" alignX="right">
                    <StyledLinkWss size="s1" onClick={handleNavigate}>
                      <Grid alignY="center" alignX="right">
                        <Text size="s1" marginRight="01">
                          {loginLink}
                        </Text>
                        <ArrowRightIcon />
                      </Grid>
                    </StyledLinkWss>
                  </Grid>
                </ContentGroup>
              )}
            </GridItem>
          </Grid>
        </Container>
      </BackgroundProvider>
    </StyledWssHeader>
  );
  const renderMtaHeader = (): ReactElement => (
    <StyledHeader>
      <BackgroundProvider value={{ cssValue: theme.header_color_background }}>
        <Container>
          <Grid alignY="center">
            <GridItem xs={4}>
              <NavLink
                to={routes.WelcomePage}
                title="logo"
                data-testid="navLink"
              >
                <Logo
                  style={{
                    height: isWide
                      ? theme.header_logo_height_big
                      : theme.header_logo_height,
                    width: isWide
                      ? theme.header_logo_width_big
                      : theme.header_logo_width,
                  }}
                />
              </NavLink>
            </GridItem>
            <GridItem xs={6} />
            <GridItem xs={2} />
          </Grid>
        </Container>
      </BackgroundProvider>
    </StyledHeader>
  );
  return isWss ? renderWssHeader() : renderMtaHeader();
}
