import React, { ReactElement, useEffect } from 'react';

import { useNavigate } from '@interstellar/react-app-routing';

import APIDownTable from './apiDownTable';
import { isBld, isLocal, isInt, isPre } from '../../utils/handleEnvVariables';
import { WelcomePage } from '../manifest';

export default function DownPage(): ReactElement {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isBld && !isInt && !isPre && !isLocal) {
      navigate(WelcomePage);
    }
  });

  return (
    <div>
      <APIDownTable />
    </div>
  );
}
