import React, { ReactElement } from 'react';

interface IInformationIconProps {
  colour: string;
  size: string;
}
export default function emailInfoIcon({
  colour,
  size,
}: IInformationIconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill={colour}
      aria-label="information icon"
    >
      <path
        d="M8.3924 13.5137H9.74238V8.11367H8.3924V13.5137ZM8.99949 6.44867C9.20975 6.44867 9.38614 6.37967 9.52864 6.24167C9.67113 6.10367 9.74238 5.93267 9.74238 5.72867C9.74238 5.51192 9.67127 5.33023 9.52904 5.18361C9.3868 5.03698 9.21055 4.96367 9.0003 4.96367C8.79003 4.96367 8.61365 5.03698 8.47115 5.18361C8.32865 5.33023 8.2574 5.51192 8.2574 5.72867C8.2574 5.93267 8.32852 6.10367 8.47074 6.24167C8.61299 6.37967 8.78924 6.44867 8.99949 6.44867ZM9.00588 18.0137C7.76488 18.0137 6.59865 17.7774 5.50717 17.3049C4.41569 16.8324 3.46121 16.1874 2.64372 15.3699C1.82623 14.5524 1.18124 13.5974 0.708742 12.5048C0.236247 11.4121 0 10.2447 0 9.00242C0 7.76014 0.236247 6.59269 0.708742 5.50009C1.18124 4.40748 1.82623 3.45617 2.64372 2.64617C3.46121 1.83617 4.41625 1.19492 5.50886 0.722422C6.60144 0.249922 7.76887 0.0136719 9.01114 0.0136719C10.2534 0.0136719 11.4208 0.249922 12.5134 0.722422C13.606 1.19492 14.5573 1.83617 15.3673 2.64617C16.1773 3.45617 16.8185 4.40867 17.291 5.50367C17.7635 6.59867 17.9998 7.76668 17.9998 9.00769C17.9998 10.2487 17.7635 11.4149 17.291 12.5064C16.8185 13.5979 16.1773 14.551 15.3673 15.3658C14.5573 16.1805 13.6048 16.8255 12.5099 17.3008C11.4149 17.776 10.2469 18.0137 9.00588 18.0137ZM9.01114 16.6637C11.1336 16.6637 12.9373 15.9174 14.4223 14.4249C15.9073 12.9324 16.6498 11.1249 16.6498 9.00242C16.6498 6.87992 15.9087 5.07617 14.4266 3.59117C12.9444 2.10617 11.1355 1.36367 8.99989 1.36367C6.88492 1.36367 5.08119 2.10476 3.58871 3.58694C2.09622 5.06914 1.34998 6.87805 1.34998 9.01367C1.34998 11.1287 2.09622 12.9324 3.58871 14.4249C5.08119 15.9174 6.88867 16.6637 9.01114 16.6637Z"
        fill={colour}
      />
    </svg>
  );
}
