import React, { ReactElement } from 'react';

interface IMailIconProps {
  colour: string;
}
export default function MailIcon({ colour }: IMailIconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      className="mailIconMargin"
    >
      <path
        d="M1.2 14.4502C0.88 14.4502 0.6 14.3302 0.36 14.0902C0.12 13.8502 0 13.5702 0 13.2502V2.85037C0 2.53038 0.12 2.25038 0.36 2.01039C0.6 1.77039 0.88 1.65039 1.2 1.65039H14.8C15.12 1.65039 15.4 1.77039 15.64 2.01039C15.88 2.25038 16 2.53038 16 2.85037V13.2502C16 13.5702 15.88 13.8502 15.64 14.0902C15.4 14.3302 15.12 14.4502 14.8 14.4502H1.2ZM8 8.4103L1.2 3.95036V13.2502H14.8V3.95036L8 8.4103ZM8 7.21031L14.72 2.85037H1.3L8 7.21031ZM1.2 3.95036V2.85037V13.2502V3.95036Z"
        fill={colour}
      />
    </svg>
  );
}
