import { ContentGroup } from '@constellation/core';
import styled from 'styled-components';

import { StyledLink } from '../appLayout/Applayout.styled';

export const StyledPrevContentGroup = styled(ContentGroup)`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.color_text_brand_1} !important;
  margin-left: ${({ theme }) => theme.spacing_01} !important;
  margin-bottom: ${({ theme }) => theme.spacing_03} !important;
`;

export const StyledHeader = styled(ContentGroup)(
  () => `
  display: flex;
  align-items:center;
  `,
);

export const StyledPreviousLink = styled(StyledLink)`
  padding: 0 !important;
  background-color: transparent !important;
  border: none !important;
  min-width: auto !important;
  color: ${({ theme }) => theme.color_text_brand_1} !important;
  text-decoration: underline !important;
  border-radius: 0 !important;
  &:focus:hover {
    box-shadow: none !important;
  }
`;
