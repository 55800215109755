import React, { ReactElement, useEffect } from 'react';

import { ContentProvider } from '@interstellar/react-app-content';
import { Provider } from 'react-redux';

import { healthcheckAPI } from '../../apis/healthCheck';
import content from '../../content';
import Routes from '../../routes';
import store from '../../store';
import { getThemeFromUrl } from '../../utils/getThemeFromUrl';
import useAppConfig from '../appConfig/useAppConfig';
import AppLayout from '../appLayout/Applayout';
import BrandProvider, { Brand } from '../brandProvider';

function App(): ReactElement {
  const configBrand = useAppConfig().BRAND_NAME;
  const brand: Brand = getThemeFromUrl() || configBrand;

  const apiIsDown = (status) =>
    status === 500 ||
    status === 501 ||
    status === 502 ||
    status === 503 ||
    status === 504 ||
    status === 505 ||
    status === 506 ||
    status === 507 ||
    status === 508 ||
    status === 510 ||
    status === 511;

  useEffect(() => {
    const checkHealth = async () => {
      healthcheckAPI().catch((error) => {
        if (error.response && apiIsDown(error.response.status)) {
          window.location.href = `${window.appConfig.REACT_APP_WSS_URL}/blackhorse/#/`;
        }
      });
    };
    checkHealth();
  }, []);

  return (
    <Provider store={store}>
      <BrandProvider brand={brand}>
        <ContentProvider
          value={{
            ...content({ brand }).welcome,
            ...content({ brand }).layoutContent,
            ...content({ brand }).common,
          }}
        >
          <AppLayout>
            <Routes brand={brand} />
          </AppLayout>
        </ContentProvider>
      </BrandProvider>
    </Provider>
  );
}

export default App;
