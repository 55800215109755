interface Size {
  md: string;
  lg: string;
}

const size: Size = {
  md: '768px', // for tablets
  lg: '992px', // for laptops
};

export const device = {
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
};
