import React, { ReactElement, useEffect } from 'react';

import { ContentGroup, Heading, Grid, GridItem } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';

import { NotFoundContent } from './NotFound.config';
import { StyledParagraph, StyledLink } from './NotFound.styled';
import dataQaIds from '../../dataModel/dataQaIds';
import { dataLayer } from '../../utils/dataLayer';

function NotFound(): ReactElement {
  const { heading, subHeading, footer, footerLink, subFooter } =
    useContent<NotFoundContent>();

  useEffect(() => {
    window.appConfig.JOURNEY_NAME = 'Data Retrieval';
    window.appConfig.JOURNEY_STEP_NAME = 'Page Not Found';
    window.appConfig.PAGE_ROLE = 'Information';
    dataLayer();
  }, []);

  return (
    <ContentGroup>
      <Grid>
        <GridItem xs={5} />
        <GridItem xs={4}>
          <Heading as="h4" aria-level={3}>
            {heading}
          </Heading>
        </GridItem>
        <GridItem xs={3} />
      </Grid>
      <Grid>
        <GridItem xs={12}>
          <StyledParagraph>{subHeading}</StyledParagraph>
        </GridItem>
      </Grid>
      <Grid data-qa-id={dataQaIds.notFoundPage.content}>
        <GridItem xs={12}>
          <StyledParagraph>
            {footer}{' '}
            <StyledLink href="/" data-qa-id={dataQaIds.notFoundPage.homeLink}>
              {footerLink}
            </StyledLink>
            {subFooter}
          </StyledParagraph>
        </GridItem>
      </Grid>
    </ContentGroup>
  );
}

export default NotFound;
