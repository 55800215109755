import { Link, Heading, Text, Paragraph } from '@constellation/core';
import styled from 'styled-components';

interface IPrivacyNotice {
  isPaddingLeft?: boolean;
  isBold?: boolean;
  isSubheading?: boolean;
}
export const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  word-wrap: break-word;
`;
export const StyledLink = styled(Link)`
  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    text-decoration: none !important;
  }
`;
export const StyledSubHeading = styled(Heading)`
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
  font-weight: ${({ isBold }: IPrivacyNotice) =>
    isBold ? '600' : ''} !important;
  padding-top: ${({ isSubheading }: IPrivacyNotice) =>
    isSubheading ? ({ theme }) => theme.spacing_05 : 'none'} !important;
`;
export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
`;
export const StyledInnerDiv = styled('div')`
  padding: 1rem !important;
  padding-bottom: ${({ theme }) => theme.spacing_05}!important;
  > a {
    color: #0f7eb1 !important;
  }
  a:focus {
    background: none !important;
    box-shadow: none !important;
  }
  &.mobileFound {
    padding: 1rem !important;
  }
  &.notMobile {
    padding: 1.5rem !important;
  }
`;
