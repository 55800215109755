import { ILoginInfo, ILoginModalInfo } from '../action/login.action';

export interface ILoginState {
  emailAddress: string;
  loginConstraints: {
    isLoginAttemptPrevented: boolean;
    isLoginAttemptExceeded: boolean;
    isRegistered: boolean;
  };
}
const initialState = {
  emailAddress: '',
  loginConstraints: {
    isLoginAttemptPrevented: false,
    isLoginAttemptExceeded: false,
    isRegistered: true,
  },
};

export const LoginReducer = (
  state: ILoginState = initialState,
  action: Partial<ILoginInfo> & Partial<ILoginModalInfo> = {
    type: '',
    ...initialState,
  },
): ILoginState => {
  if (action.type === 'FORGOT_EMAIL') {
    return {
      ...state,
      emailAddress: action.emailAddress || '',
    };
  }
  if (action.type === 'LOGIN_MODAL_CONSTRAINTS') {
    return {
      ...state,
      loginConstraints: action.loginConstraints,
    };
  }
  return state;
};
