import { ITogglerInterface } from '../action/toggler.action';

export interface ITogglerState {
  isWss: boolean;
}
const initialState = {
  isWss: true,
};

export const TogglerReducer = (
  state: ITogglerState = initialState,
  action: ITogglerInterface = {
    type: '',
    ...initialState,
  },
): ITogglerState => {
  if (action.type === 'TOGGLE') {
    return {
      ...state,
      isWss: action.isWss,
    };
  }
  return state;
};
