import React, { ReactElement } from 'react';

import { Grid, GridItem, Text } from '@constellation/core';

import { IMultiAgreementSelectorProps } from './multiAgreementSelector.config';
import {
  StyledBorderDiv,
  StyledGridItem,
} from './multiAgreementSelector.styled';
import CarIcon from '../../assets/icons/carIcon';
import ChevronRightWssIcon from '../../assets/icons/chevronRightWssIcon';
import { StyledLink } from '../../components/appLayout/Applayout.styled';

export default function MultiAgreementSelector({
  firstTitle,
  firstInfo,
  secondTitle,
  secondInfo,
  navigateRoute,
  dataQaId = '',
  testId,
}: IMultiAgreementSelectorProps): ReactElement {
  return (
    <StyledBorderDiv onClick={navigateRoute} data-testid={`${testId}-1`}>
      <StyledLink data-qa-id={dataQaId} data-testid={testId}>
        <Grid alignY="center" alignX="justify">
          <StyledGridItem lg={11} md={10} sm={10}>
            <GridItem data-test-id="test-icon">
              <CarIcon />
            </GridItem>
            <GridItem>
              <Text size="s1" weight="bold">
                {firstTitle}
              </Text>
            </GridItem>
            <GridItem>
              <Text size="s1">{firstInfo}</Text>
            </GridItem>
            <GridItem>
              <Text size="s1" weight="bold">
                {secondTitle}
              </Text>
            </GridItem>
            <GridItem>
              <Text size="s1">{secondInfo}</Text>
            </GridItem>
          </StyledGridItem>

          <StyledGridItem lg={1} md={2} sm={2}>
            <ChevronRightWssIcon />
          </StyledGridItem>
        </Grid>
      </StyledLink>
    </StyledBorderDiv>
  );
}
