import { DateProps } from '../../components/appConfig/AppConfig';
import { ApiAuthResponse } from '../../routes/registration/Registration.config';
import {
  CustomerMtaDataInterface,
  CustomerWssDataInterface,
  DOBPostcodeContractsInterface,
  UpdateDOBPostcodeInterface,
} from '../action/registration.action';
import { UpdateEmailAPIErrorInterface } from '../action/updateEmail.action';

export interface IRegistrationState {
  isUpdateEmail: boolean;
  postcode: string;
  dob: DateProps;
  customerData: ApiAuthResponse[];
  customerDataMta: ApiAuthResponse[];
  customerDataWss: ApiAuthResponse[];
  contracts: {
    isMTA?: boolean;
    isWSS?: boolean;
  };
  constraints: {
    isDetailsFound?: boolean;
    isApiFailed?: boolean;
    isMoreThanOneUser?: boolean;
  };
}
const responseData = {
  phoneNumber: '',
  emailAddress: '',
  mtaCustomerId: '',
  isRegisteredMTA: false,
  vehcileMakeModel: '',
  vehicleReg: '',
  agreementNumber: '',
  redirectLink: '',
  authLiteUUID: '',
  obscuredContractId: '',
  vehicleMakeModel: '',
  isRegistered: false,
};
const initialState = {
  isUpdateEmail: false,
  postcode: '',
  dob: { day: '', month: '', year: '' },
  customerData: [responseData],
  customerDataMta: [responseData],
  customerDataWss: [responseData],
  contracts: {
    isMTA: false,
    isWSS: false,
  },
  constraints: {
    isDetailsFound: true,
    isApiFailed: false,
    isMoreThanOneUser: false,
  },
};

export const RegistrationReducer = (
  state: IRegistrationState = initialState,
  action: Partial<UpdateEmailAPIErrorInterface> &
    Partial<UpdateDOBPostcodeInterface> &
    Partial<DOBPostcodeContractsInterface> &
    Partial<CustomerMtaDataInterface> &
    Partial<CustomerWssDataInterface> = {
    type: '',
    ...initialState,
  },
): IRegistrationState => {
  if (action.type === 'UPDATE_EMAIL_API_ERROR') {
    return {
      ...state,
      isUpdateEmail: action.isUpdateEmail,
    };
  }
  if (action.type === 'UPDATE_POSTCODE_DOB') {
    return {
      ...state,
      postcode: action.resetValues ? initialState.postcode : action.postcode,
      dob: action.resetValues ? initialState.dob : action.dob,
    };
  }
  if (action.type === 'POSTCODE_DOB_DATA') {
    return {
      ...state,
      customerData:
        action.customerData.length > 0
          ? action.customerData
          : initialState.customerData,
      constraints: action.constraints,
    };
  }
  if (action.type === 'CUSTOMER_DATA_MTA') {
    return {
      ...state,
      customerDataMta: action.customerDataMta,
      contracts: action.contracts,
    };
  }
  if (action.type === 'CUSTOMER_DATA_WSS') {
    return {
      ...state,
      customerDataWss: action.customerDataWss,
      contracts: action.contracts,
    };
  }
  return state;
};
