import { Accordion, GridItem, Dialog } from '@constellation/core';
import styled from 'styled-components';

import { IDeviceInfo } from './marketingPreferences.config';
import { device } from '../../utils/breakpoints';

export const StyledList = styled('ul')`
  padding-inline-start: ${({ theme }) =>
    `calc(${theme.spacing_04} + ${theme.spacing_01})`};
`;

export const StyledListItem = styled('li')`
  margin-bottom: ${({ theme }) => theme.spacing_04};
`;

export const StyledAccordion = styled(Accordion)`
  background: none !important;
  &:hover {
    background: none !important;
  }
  > button {
    background: none !important;
  }
`;
export const StyledBoxContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing_04};
  @media ${device.md} {
    padding: ${({ theme }) => theme.spacing_05};
  }
  padding-bottom: ${({ theme }) => theme.spacing_02} !important;
  border-radius: 0.5rem;
  margin-bottom: ${({ theme }) => theme.spacing_06};
  background-color: ${({ theme }) => theme.color_background_panel_default_1};
`;

export const StyledMiddleGridItem = styled(GridItem)`
  margin-bottom: ${({ theme }) => theme.spacing_07};
`;

export const StyledLoginBtnGridItem = styled(GridItem)`
  margin-bottom: ${({ isMobileFound }: IDeviceInfo) =>
    isMobileFound ? ({ theme }) => theme.spacing_04 : ''};
`;

export const StyledDialog = styled(Dialog)`
  border-radius: ${({ theme }) => theme.spacing_02};
`;
