import React, { ReactElement, useRef, useState } from 'react';

import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';

import ForgotCredentialDataModal from './forgotCredentialDataModal';
import { RegistrationWSSProps } from './LoginAndRegistrationWss.config';
import { StyledNoPaddingGridItem } from './LoginAndRegistrationWss.styled';
import {
  checkMultipleAgreementCondition,
  checkIsMta,
  checkIsWss,
} from './parallelRunUtils';
import { authContractsAPI } from '../../apis/authContractsApi';
import { DateProps } from '../../components/appConfig/AppConfig';
import SpinnerContainer from '../../components/spinner/spinnerContainer';
import YourDetailsWss from '../../components/yourDetailsWss/yourDetailsWss';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import { changeStepAction } from '../../store/action/formStep.action';
import {
  CustomerMtaDataAction,
  CustomerWssDataAction,
  DOBPostcodeContractsDataAction,
  UpdateDOBPostcodeAction,
} from '../../store/action/registration.action';
import { toggleAction } from '../../store/action/toggler.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { dateFormat } from '../../utils/dateFormat';
import * as routes from '../manifest';
import {
  ApiAuthResponse,
  RegistrationContent,
} from '../registration/Registration.config';

export default function ForgotEmailWss({
  handleOnPassDetails,
  handleMultiAgreement,
  isMultiAgreement,
}: RegistrationWSSProps): ReactElement {
  const {
    forgotUserIdHeadingText,
    forgotUserIdHeadingSubText,
    fogotUserIdSubText,
    continueLabel,
    apiErrorMessage,
  } = useContent<RegistrationContent>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const postCodeValue = useRef('');
  const handleOnPostCode = (value: string): void => {
    postCodeValue.current = value;
  };
  const handleOnPostcodeFormat = (): string =>
    `${postCodeValue.current.split(' ').join('')}`;
  const { showSpinner } = useSelector((state: RootState) => state.UtilsReducer);
  const { constraints } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const [isDataFound, setIsDataFound] = useState(constraints.isDetailsFound);
  const [isApiFailure, setIsApiFailure] = useState(constraints.isApiFailed);
  const handleModelButtonClick = (): void => {
    navigate(routes.ForgotEmail);
    dispatch(DataModelAction(false));
    dispatch(
      DOBPostcodeContractsDataAction([], {
        isDetailsFound: true,
        isApiFailed: false,
        isMoreThanOneUser: false,
      }),
    );
    setIsDataFound(true);
  };
  const handleNeedHelpNavigate = (): void => {
    navigate(routes.HelpCentre);
  };
  const handleWssOrMtaContract = (data: any) => {
    if (checkMultipleAgreementCondition(data)) {
      dispatch(
        CustomerMtaDataAction(data.isMTA as ApiAuthResponse[], {
          isMTA: true,
          isWSS: true,
        }),
      );
      dispatch(
        CustomerWssDataAction(data.isWSS as ApiAuthResponse[], {
          isMTA: true,
          isWSS: true,
        }),
      );
    } else if (checkIsMta(data)) {
      dispatch(
        CustomerMtaDataAction(data.isMTA as ApiAuthResponse[], {
          isMTA: true,
          isWSS: false,
        }),
      );
    } else if (checkIsWss(data)) {
      dispatch(
        CustomerWssDataAction(data.isWSS as ApiAuthResponse[], {
          isWSS: true,
          isMTA: false,
        }),
      );
    }
  };
  const handleSingleAgreement = (res: any) => {
    if (checkIsMta(res)) {
      handleOnPassDetails(res.isMTA, {
        isForgotEmail: true,
      });
      dispatch(changeStepAction(2));
      dispatch(toggleAction(false));
      setIsDataFound(true);
      handleMultiAgreement(false);
    } else if (checkIsWss(res)) {
      window.location.href = res.isWSS[0].redirectLink;
    }
  };
  const handleMultiContractCondition = (res: any) =>
    Object.keys(res).length > 1 ||
    res.isMTA?.length > 1 ||
    res.isWSS?.length > 1;
  const handleApiResponse = (res: any, date: DateProps) => {
    if (handleMultiContractCondition(res)) {
      handleMultiAgreement(true);
      dispatch(UpdateDOBPostcodeAction(postCodeValue.current, date, false));
      handleWssOrMtaContract(res);
    } else if (Object.keys(res).length === 1) {
      handleSingleAgreement(res);
    }
    dispatch(ShowHideSpinnerAction(false));
  };
  const handleApiError = (err: { message: string }) => {
    if (err.message === apiErrorMessage) {
      setIsDataFound(false);
      dispatch(DataModelAction(true));
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: false,
          isApiFailed: false,
          isMoreThanOneUser: false,
        }),
      );
    } else {
      setIsApiFailure(true);
      dispatch(
        DOBPostcodeContractsDataAction([], {
          isDetailsFound: true,
          isApiFailed: true,
          isMoreThanOneUser: false,
        }),
      );
      dispatch(DataModelAction(true));
    }
    dispatch(ShowHideSpinnerAction(false));
  };
  const handleClick = (date: DateProps) => {
    dispatch(UpdateDOBPostcodeAction(postCodeValue.current, date, false));
    dispatch(ShowHideSpinnerAction(true));
    authContractsAPI(
      `${dateFormat(date, '-')}/${handleOnPostcodeFormat()}`,
      '3',
    )
      .then((res) => {
        handleApiResponse(res, date);
      })
      .catch((err: { message: string }) => {
        handleApiError(err);
      });
  };
  return showSpinner ? (
    <SpinnerContainer brand={undefined} />
  ) : (
    <>
      <StyledNoPaddingGridItem lg={4} md={6}>
        <YourDetailsWss
          headingText={forgotUserIdHeadingText}
          isRegistraionWss={false}
          subText={fogotUserIdSubText}
          buttonText={continueLabel}
          headingSubText={forgotUserIdHeadingSubText}
          isForgotUserIdWss
          handleOnPostCode={handleOnPostCode}
          handleClick={handleClick}
          isMultiAgreement={isMultiAgreement}
          isMoreThanOneCustomer={false}
          handleOnPassDetails={handleOnPassDetails}
          handleMultiAgreement={handleMultiAgreement}
        />
      </StyledNoPaddingGridItem>
      <ForgotCredentialDataModal
        isApiFailed={isApiFailure}
        isDataFound={isDataFound}
        handleModelButtonClick={handleModelButtonClick}
        handleNeedHelpNavigate={handleNeedHelpNavigate}
      />
    </>
  );
}
