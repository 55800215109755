import { GridItem } from '@constellation/core';
import styled from 'styled-components';

import { device } from '../../utils/breakpoints';
import { StyledCtaBorderDiv } from '../loginAndRegistrationWss/LoginAndRegistrationWss.styled';

export const StyledBorderDiv = styled(StyledCtaBorderDiv)`
  padding: ${({ theme }) => theme.spacing_04} !important;
  @media ${device.md} {
    padding: ${({ theme }) => theme.spacing_05} !important;
  }
  cursor: pointer;
`;
export const StyledGridItem = styled(GridItem)`
  padding: ${({ theme }) => theme.spacing_none} !important;
`;
