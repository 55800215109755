import React, { ReactElement, useState } from 'react';

import { Text, ContentGroup } from '@constellation/core';
import { Information } from '@constellation/core/icons';

import { PasswordFieldProps } from './PasswordField.config';
import {
  StyledErrorBox,
  StyledErrorText,
  StyledPasswordContainer,
  StyledShowHideBtn,
  StyledTextField,
} from './styledPasswordFieldComponent';
import { StyledBtnBox } from '../appConfig/common.styled';

export default function PasswordField({
  label,
  name,
  error,
  inputWidth,
  fieldMarginBottom,
  fieldMarginLeft,
  fieldMarginRight,
  fieldMarginTop,
  onChange,
  onBlur,
  inputRef,
  testId,
  dataQaId,
  inputValue,
}: PasswordFieldProps): ReactElement {
  const [showPassword, setShowPassword] = useState(false);
  const handleVisibility = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    event.stopPropagation();
    event.preventDefault();
    setShowPassword(!showPassword);
  };
  return (
    <ContentGroup
      marginBottom={fieldMarginBottom}
      marginLeft={fieldMarginLeft}
      marginRight={fieldMarginRight}
      marginTop={fieldMarginTop}
    >
      {label !== '' && (
        <ContentGroup marginBottom="03">
          <Text weight="bold" size="s2">
            {label}
          </Text>
        </ContentGroup>
      )}
      {error && (
        <StyledErrorBox>
          <Information color="critical" iconSize="md" size="s2" />
          <StyledErrorText marginLeft="03" size="s2">
            {error}
          </StyledErrorText>
        </StyledErrorBox>
      )}
      <StyledPasswordContainer
        isErrorFound={
          (typeof error === 'boolean' || typeof error === 'string') &&
          error !== ''
        }
        inputWidth={inputWidth}
      >
        <StyledTextField
          type={showPassword ? 'text' : 'password'}
          name={name}
          aria-label="password field"
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={(event) => {
            if (event.key === ' ') event.preventDefault();
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          data-testid={testId}
          ref={inputRef}
          data-qa-id={dataQaId}
          defaultValue={inputValue}
          autoComplete="off"
        />
        <StyledBtnBox>
          <StyledShowHideBtn
            data-testid="hideShowBtn"
            onMouseDown={handleVisibility}
          >
            {showPassword ? 'Hide' : 'Show'}
          </StyledShowHideBtn>
        </StyledBtnBox>
      </StyledPasswordContainer>
    </ContentGroup>
  );
}
