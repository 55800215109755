import axios from 'axios';

export const authZMockApi = (subject: string): Promise<object> =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `https://api.iau.ew2.dmn01-pre.oncp.group/token-service/v1.0/authorize/token?authGrantId=abc&partyId=1234-4444-11111&brand=LBG&channel=IB&exp=6171365328&userId=abc12345&extPtyId=pqr12345&sub=${subject}&aud=*.bld-02.ep.agw.oncp.cloud&scope=servicetoservice&days=2&cidpersId=zzz89675&extSysId=24&env=pre`,
      )
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });
