import common from './common';
import { AppContent } from '../AppContent';

const welcome: AppContent['welcome'] = {
  welcomeMessage: 'Finance to suit you',
  goBackLabel: 'Go back',
  logIntoAccountLabel: 'Log in to your account',
  ...common,
};

export default welcome;
