import React from 'react';

import { Main } from '@constellation/core';
import { useLocation } from '@interstellar/react-app-routing';
import { useSelector } from 'react-redux';

import { LayoutProps } from './Applayout.types';
import Footer from '../../routes/loginAndRegistrationWss/footerWss';
import Header from '../../routes/loginAndRegistrationWss/headerWss';
import { StyledBackground } from '../../routes/loginAndRegistrationWss/LoginAndRegistrationWss.styled';
import WelcomePage from '../../routes/welcomePage/WelcomePage';
import { RootState } from '../../store';

function AppLayout({ children }: LayoutProps): React.JSX.Element {
  const location = useLocation();
  const { isWss } = useSelector((state: RootState) => state.TogglerReducer);
  const renderWss = () => {
    return (
      <StyledBackground isWss={isWss}>
        <Header isWss={isWss} />
        <Main>{children}</Main>
        <Footer isWss={isWss} />
      </StyledBackground>
    );
  };
  return location.pathname !== '/' ? renderWss() : <WelcomePage />;
}

export default AppLayout;
