import React, { Fragment, ReactElement, useState } from 'react';

import { Text, Hr, Button } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch } from 'react-redux';

import {
  IMarketingPreferencesProps,
  MarketingInfoCode,
  MarketingPreferencesContent,
  MarketingSelect,
} from './marketingPreferences.config';
import {
  StyledAccordion,
  StyledList,
  StyledListItem,
  StyledBoxContainer,
} from './styledMarketingPreferences';
import { getCustomerAPI } from '../../apis/getCustomerAPI';
import { APICallHelperPUT } from '../../apis/updateThirdPartyApi';
import { StyledButtonsDiv } from '../../components/appConfig/common.styled';
import LinkButton from '../../components/linkButton/LinkButton';
import CustomSwitch from '../../components/switch';
import { ApiEndpoints } from '../../constants/apiEndpoints';
import dataQaIds from '../../dataModel/dataQaIds';
import { DataModelAction } from '../../store/action/dataModal.action';
import {
  marketingPrefsFailAction,
  profilInfoAction,
} from '../../store/action/profile.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import * as routes from '../manifest';

export default function MarketingPreferences({
  switchArray,
  selected,
  handleOnSaveBtnClick,
  handleCancelChanges,
  accessToken,
  email,
}: IMarketingPreferencesProps): ReactElement {
  const [marketingPreferences, setMarketingPreferences] = useState(selected);
  const [showSuccsMsg, setShowSuccsMsg] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    yesLabel,
    noLabel,
    accordionLabel,
    accordionIntroText,
    accordionListItemOne,
    accordionListItemTwo,
    accordionOutroText,
    marketingIntroText,
    listItemOne,
    listItemTwo,
    listItemThree,
    tellUs,
    marketingpreferences,
    savePreference,
    skipForNow,
  } = useContent<MarketingPreferencesContent>();

  const handleOnMarketingKeys = (resObject: object): any[] => {
    const marketingPref = [];
    Object.keys(resObject).forEach((val) => {
      marketingPref.push({
        code: MarketingInfoCode[val] || val,
        value: MarketingSelect[resObject[val]] || resObject[val] || ' ',
      });
    });
    return marketingPref;
  };

  const handleSetPreferences = (pref, value): void => {
    setShowSuccsMsg(true);
    delete marketingPreferences[pref];
    const newMarketingObject = Object.assign(marketingPreferences, {
      [MarketingInfoCode[pref]]: MarketingSelect[value],
    });
    setMarketingPreferences(newMarketingObject);
  };
  const handlePreferences = (result: any, profileInfoData: any) => {
    const reqObjectvalue = profileInfoData.cmsInfo;
    delete reqObjectvalue.updatedDate;
    return {
      ...reqObjectvalue,
      info: {
        surname: profileInfoData?.cmsInfo.info.surname,
        customerType: profileInfoData?.cmsInfo.info.customerType,
        dateOfBirth: profileInfoData?.cmsInfo.info.dateOfBirth,
        gender: profileInfoData?.cmsInfo.info.gender,
        vatCode: '1000',
      },
      marketingPreferences: result,
      otherCustomAttributes: profileInfoData.cmsInfo.otherCustomAttributes,
      isMarketingPrefUpdated: true,
      emailAddress: profileInfoData?.cmsInfo.emailAddress,
    };
  };
  const handleOnUpdatePrefs = (
    reqObjectvalue: object,
    businessPartnerId: string,
  ): void => {
    APICallHelperPUT(
      `${ApiEndpoints.CONTRACT_CUSTOMERS}/${businessPartnerId}`,
      reqObjectvalue,
      {
        Authorization: `Bearer ${accessToken}`,
      },
    )
      .then((info) => {
        navigate(routes.LoginWss);
        dispatch(ShowHideSpinnerAction(false));
        if (typeof info === 'object') {
          handleOnSaveBtnClick(true);
        }
      })
      .catch(() => {
        dispatch(DataModelAction(true));
        dispatch(marketingPrefsFailAction(true));
        dispatch(ShowHideSpinnerAction(false));
      });
  };
  const setInitialValue = (pref): string =>
    selected[pref] ? selected[pref] : '';

  const handleOnSavePreferences = (value: object): void => {
    const reqObject = Object.fromEntries(
      Object.entries(value).filter(([code]) => code !== 'undefined'),
    );
    const result = handleOnMarketingKeys(reqObject);

    if (showSuccsMsg) {
      dispatch(ShowHideSpinnerAction(true));
      getCustomerAPI(`?email=${email}`, {
        Authorization: `Bearer ${accessToken}`,
      })
        .then((info: any) => {
          if (typeof info === 'object') {
            dispatch(profilInfoAction(info));
            handleOnUpdatePrefs(
              handlePreferences(result, info),
              info.mtaInfo[0]?.businessPartnerId,
            );
          }
        })
        .catch(() => {
          dispatch(marketingPrefsFailAction(true));
          dispatch(ShowHideSpinnerAction(false));
        });
    } else {
      handleCancelChanges();
    }
  };
  const handleOnMarketingFields = (): ReactElement => (
    <>
      {switchArray.map((item, index) => (
        <Fragment key={item.name}>
          <CustomSwitch
            initialValue={setInitialValue(item.name)}
            label={item.label}
            handleChange={(value) => handleSetPreferences(item.name, value)}
            yesLabel={yesLabel}
            noLabel={noLabel}
            noTestId={`${item.name}NoTest`}
            yesTestId={`${item.name}YesTest`}
            dataQaId={{
              yesBtn: `profile-marketingPrefs-${item.name}YesBtn`,
              noBtn: `profile-marketingPrefs-${item.name}NoBtn`,
            }}
          />
          <Text
            color="subdued"
            dangerouslySetInnerHTML={{ __html: item.furtherInfo }}
            size="s1"
          >
            {}
          </Text>
          <Hr
            marginTop="05"
            marginBottom={switchArray.length - 1 === index ? '02' : '05'}
          />
        </Fragment>
      ))}
      <StyledAccordion
        label={accordionLabel}
        variation="inpage2"
        marginBottom="05"
        data-qa-id=""
      >
        <Text size="s1" color="subdued">
          {accordionIntroText}
          <StyledList>
            <StyledListItem>{accordionListItemOne}</StyledListItem>
            <StyledListItem>{accordionListItemTwo}</StyledListItem>
          </StyledList>
        </Text>
        <Text
          color="subdued"
          dangerouslySetInnerHTML={{
            __html: accordionOutroText,
          }}
          size="s1"
        >
          {}
        </Text>
      </StyledAccordion>
    </>
  );

  const handleOnRender = (): ReactElement => (
    <>
      <Text color="normal" size="s1" as="p" marginBottom="03">
        {marketingIntroText}
      </Text>
      <Text size="s1" color="normal">
        <StyledList>
          <StyledListItem>{listItemOne}</StyledListItem>
          <StyledListItem>{listItemTwo}</StyledListItem>
          <StyledListItem>{listItemThree}</StyledListItem>
        </StyledList>
      </Text>
      <Text color="normal" size="s1" marginBottom="07" as="p">
        {tellUs}
      </Text>
      <Text size="s3" marginBottom="04" as="p">
        {marketingpreferences}
      </Text>
      <StyledBoxContainer>{handleOnMarketingFields()}</StyledBoxContainer>
      <StyledButtonsDiv isMarketingPrefs>
        <LinkButton
          handleButtonClick={handleCancelChanges}
          testId="SkipforNoW"
          dataQaId={dataQaIds.setMarketingPreferences.skipForNoW}
        >
          {skipForNow}
        </LinkButton>
        <Button
          onClick={() => handleOnSavePreferences(marketingPreferences)}
          data-testid="savePreference"
          data-qa-id={dataQaIds.setMarketingPreferences.savePreference}
        >
          {savePreference}
        </Button>
      </StyledButtonsDiv>
    </>
  );

  return handleOnRender();
}
