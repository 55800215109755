import { AppContent } from '../AppContent';

const notfound: AppContent['notfound'] = {
  heading: 'Problem loading page',
  subHeading: `Sorry, the page you're looking for can't be found.`,
  footer: 'In the meantime, you can visit our',
  footerLink: 'Home',
  subFooter: 'page to find what you are looking for.',
};

export default notfound;
