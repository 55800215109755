import registration from './registration';
import { AppContent } from '../AppContent';

const forgotPassword: AppContent['forgotPassword'] = {
  apiEmailErrorMessage: 'Email Not Found',
  resetPasswordTitle: 'Reset password',
  needHelpLoggingIn: 'Find answers to your questions about logging in. ',
  customerNotFoundHeading: 'We can’t find your details in our records',
  customerNotFoundSubText:
    'This is because you have not yet signed an agreement, or an incorrect email address has been entered, in which case, select the "Forgotten your email address" link on the previous screen.',
  noContactFoundSubText:
    'Please call us so we can add your mobile number to your account.',
  oldPasswordErrorMessage: 'Sorry, you can’t reuse an old password.',
  dataModelPasswordHeading: 'New password successfully saved',
  dataModelPasswordText: 'You can now log in using your new password.',
  securityErrorMessage: 'This does not match your current password',
  currentPasswordLabel: 'Current password',
  verifyEmailText:
    'First let’s find you. Then you’ll be able to change your password.',
  verifyEmailFieldLabel: 'What’s your email address?',
  oldPassowordApiMessage: 'Password should not be one of the last 5 passwords',
  ...registration,
};

export default forgotPassword;
