import { Link } from '@constellation/core';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  text-decoration: none !important;
  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    text-decoration: none !important;
  }
`;
