export interface UpdateEmailAPIErrorInterface {
  type: string;
  isUpdateEmail: boolean;
}

export const UpdateEmailAPIAction = (
  isUpdateEmail: boolean,
): UpdateEmailAPIErrorInterface => ({
  type: 'UPDATE_EMAIL_API_ERROR',
  isUpdateEmail,
});
