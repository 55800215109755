import React, { ReactElement, useEffect, useState } from 'react';

import {
  Button,
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Hr,
  Text,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';

import {
  ApiAuthResponse,
  DateObject,
  RegistrationContent,
} from './Registration.config';
import { StyledMarginButton } from './Registration.styled';
import RegistrationCredentials from './registrationCredentials';
import RegistrationModal from './registrationModal';
import SetupPassword from './setupPassword';
import { authAPI } from '../../apis/authApi';
import { authSMSSender } from '../../apis/authSMSSenderApi';
import {
  StyledBox,
  StyledInlineBox,
} from '../../components/appConfig/common.styled';
import ChangeEmail from '../../components/changeEmail/changeEmail';
import FormStepper from '../../components/formStepper/FormStepper';
import LinkButton from '../../components/linkButton/LinkButton';
import NavigatePreviousLink from '../../components/navigatePreviousLink';
import NeedHelp from '../../components/needHelp/NeedHelp';
import OTPAuthentication from '../../components/otpAuthentication/otpAuthentication';
import PhoneLink from '../../components/phoneLink/PhoneLink';
import RegistrationAuthenticate from '../../components/registrationAuthenticate/registrationAuthenticate';
import RegistrationYourDetails from '../../components/registrationYourDetails/registrationYourDetails';
import SpinnerContainer from '../../components/spinner/spinnerContainer';
import dataQaIds from '../../dataModel/dataQaIds';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import {
  changeStepAction,
  changeNumberOfStepsAction,
} from '../../store/action/formStep.action';
import { toggleAction } from '../../store/action/toggler.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { dateFormat } from '../../utils/dateFormat';
import { isEmptyString } from '../../utils/isEmptyString';
import * as routes from '../manifest';

export default function Registration({
  brand,
  email,
  phoneNumber,
  mtaCustomerId,
  handlePassDetails,
  isContactFound,
  isChangeEmail,
  isEmailFound,
  isCustomerIdentificationError,
  numberOfSteps,
  handleOnChangeEmail,
  handleCustomerIdentityError,
  isRegisteredWss,
  handleContactFound,
  handleEmailFound,
  passwordValue,
  setPasswordValue,
}): ReactElement {
  const [date, setDate] = useState<DateObject>({
    day: undefined,
    month: undefined,
    year: undefined,
  });

  const [isAgreementFound, setIsAgreementFound] = useState(true);
  const [isCustomerRecordFound, setIsCustomerRecordFound] = useState(true);
  const [postCode, setPostCode] = useState('');
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const [isPostCodeError, setIsPostCodeError] = useState(true);
  const [isDateError, setIsDateError] = useState(true);
  const [isOTP, setIsOTP] = useState(false);
  const [isCreatePassword, setIsCreatePassword] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [journeySessionId, setJourneySessionId] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const dispatch = useDispatch();
  const utilsStore = useSelector((state: RootState) => state.UtilsReducer);
  const { stepNumber } = useSelector(
    (state: RootState) => state.FormStepsReducer,
  );
  const { isWss } = useSelector((state: RootState) => state.TogglerReducer);
  const {
    credentialsHeading,
    yourDetailsHeading,
    authenticateHeading,
    sendPasscodeLabel,
    apiErrorMessage,
    changeEmailLabel,
    howUseEmailLabel,
    yourDataLabel,
    numberNotRightLabel,
    createYourAccountLabel,
    confirmAndContinueLabel,
    continueLabel,
  } = useContent<RegistrationContent>();
  const handleOnPostCode = (value: string): void => {
    setPostCode(value);
  };
  const handleChangeEmailToggle = (value: boolean): void => {
    handleOnChangeEmail(value);
  };
  const handleOnSessionId = (value: string): void => {
    setJourneySessionId(value);
  };
  useEffect(() => {
    dispatch(changeNumberOfStepsAction(3));
  }, [dispatch]);

  useEffect(() => {
    if (!isEmptyString(email)) handleEmailFound(true);
    else {
      handleEmailFound(false);
      handleOnChangeEmail(true);
    }
  }, [email, handleEmailFound, handleOnChangeEmail]);
  useEffect(() => {
    if (!isEmptyString(phoneNumber)) handleContactFound(true);
    else handleContactFound(false);
  }, [phoneNumber, handleContactFound]);
  const handleOnDate = (value: DateObject): void => {
    setDate(value);
  };
  const handleOnPwd = (value: string): void => {
    setPasswordValue(value);
  };
  const handleCloseWarningDialog = (): void => {
    dispatch(DataModelAction(false));
    setIsContinueClicked(false);
    handleCustomerIdentityError(false);
  };
  const navigate = useNavigate();
  const handleNavigate = (): void => {
    navigate(routes.HelpCentre);
  };
  const handleOnNavigateHomepage = (): void => {
    navigate(routes.WelcomePage);
  };
  const handleOnPostCodeError = (value: boolean): void =>
    setIsPostCodeError(value);
  const handleOnDateError = (value: boolean): void => setIsDateError(value);
  const renderStepTitle = (): string => {
    if (stepNumber === 1) {
      return yourDetailsHeading;
    }
    if (stepNumber === 2) {
      return credentialsHeading;
    }
    return authenticateHeading;
  };
  const renderContinueButtonLabel = (): string => {
    if (stepNumber === 1) {
      return continueLabel;
    }
    if (stepNumber === 2) {
      return confirmAndContinueLabel;
    }
    return sendPasscodeLabel;
  };

  const renderOTPComponent = (): void => {
    setIsOTP(true);
  };
  const handleOnModalOpen = (): void => {
    dispatch(DataModelAction(true));
  };

  const handleOnStep = (): void => {
    dispatch(
      changeStepAction(
        stepNumber < numberOfSteps ? stepNumber + 1 : numberOfSteps,
      ),
    );
  };
  const handleOnReverseStep = (): void => {
    if (stepNumber === 1) {
      window.history.back();
      dispatch(toggleAction(true));
    } else if (isOTP && stepNumber === 3) setIsOTP(false);
    else if (!isCreatePassword && stepNumber === 3) {
      setIsCreatePassword(true);
      dispatch(changeStepAction(stepNumber - 1));
    } else if (
      !isCreatePassword &&
      stepNumber === 2 &&
      (isRegisteredWss || !isWss)
    ) {
      dispatch(toggleAction(true));
    } else if (isCreatePassword) setIsCreatePassword(false);
    else dispatch(changeStepAction(stepNumber - 1));
    setIsContinueClicked(false);
    setIsPostCodeError(false);
    setIsDateError(false);
    handleChangeEmailToggle(false);
  };

  const handleOnPreviousLink = (): void => {
    handleOnReverseStep();
  };

  useEffect(() => {
    window.addEventListener('popstate', () => {
      if (stepNumber === 1) {
        dispatch(toggleAction(true));
      } else if (isOTP && stepNumber === 3) setIsOTP(false);
      else if (!isCreatePassword && stepNumber === 3) {
        setIsCreatePassword(true);
      } else if (
        !isCreatePassword &&
        stepNumber === 2 &&
        (isRegisteredWss || !isWss)
      ) {
        dispatch(toggleAction(true));
      } else if (isCreatePassword) setIsCreatePassword(false);
      setIsContinueClicked(false);
      setIsPostCodeError(false);
      setIsDateError(false);
      handleOnChangeEmail(false);
    });
  }, [
    dispatch,
    handleOnChangeEmail,
    isCreatePassword,
    isOTP,
    isRegisteredWss,
    isWss,
    stepNumber,
  ]);

  const handleOnFormatDate = (): string => dateFormat(date, '-');
  const handleOnPostcodeFormat = (): string =>
    `${postCode.split(' ').join('')}`;
  const handleOnClickContinue = (): void => {
    setIsContinueClicked(true);
    if (!isDateError && !isPostCodeError && stepNumber === 1) {
      dispatch(ShowHideSpinnerAction(true));
      setIsApiCalled(true);
      authAPI(`${handleOnFormatDate()}/${handleOnPostcodeFormat()}`)
        .then((res: ApiAuthResponse[]) => {
          handlePassDetails(res, { isRegisterWss: false });
          dispatch(ShowHideSpinnerAction(false));
          dispatch(toggleAction(false));
          setIsApiCalled(false);
        })
        .catch((err: { message: string }) => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
          if (err.message === apiErrorMessage) {
            handleCustomerIdentityError(true);
            setIsCustomerRecordFound(false);
            setIsAgreementFound(false);
            handleOnModalOpen();
          }
        });
    } else if (stepNumber === 3) {
      dispatch(ShowHideSpinnerAction(true));
      setIsApiCalled(true);
      authSMSSender(mtaCustomerId)
        .then((res: { authSessionId: string; otpExpiresAt: string }) => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
          setJourneySessionId(res.authSessionId);
          setExpiryTime(res.otpExpiresAt);
          renderOTPComponent();
        })
        .catch(() => {
          dispatch(ShowHideSpinnerAction(false));
          setIsApiCalled(false);
        });
    } else if (stepNumber === 2 && isChangeEmail && !isCreatePassword) {
      setIsCreatePassword(true);
    } else if (isCreatePassword) {
      handleOnStep();
      setIsCreatePassword(false);
    }
  };
  const renderSetupPassword = (): void => {
    setIsCreatePassword(true);
  };

  const handleOnButtonRenderCondition = (): boolean => {
    if (stepNumber === 1) {
      return true;
    }
    if (stepNumber === 2 && !isCreatePassword && !isChangeEmail) {
      return true;
    }
    if (stepNumber === 3 && isContactFound && isCreatePassword) {
      return true;
    }
    return stepNumber === 3 && isContactFound && !isOTP;
  };

  const renderButtonGroup = (): ReactElement => (
    <ContentGroup marginRight="03" marginLeft="03" marginBottom="none">
      <Grid alignX="right">
        {stepNumber === 2 && !isCreatePassword && !isChangeEmail && (
          <StyledMarginButton
            variation="secondary"
            data-qa-id={dataQaIds.loginAndRegistration.changeEmailBtn}
            onClick={() => handleOnChangeEmail(true)}
          >
            {changeEmailLabel}
          </StyledMarginButton>
        )}
        {handleOnButtonRenderCondition() && (
          <Button
            data-qa-id={dataQaIds.loginAndRegistration.confirmAndContinueBtn}
            data-testid="continueBtn"
            onClick={
              stepNumber !== 2 ? handleOnClickContinue : renderSetupPassword
            }
          >
            {renderContinueButtonLabel()}
          </Button>
        )}
      </Grid>
    </ContentGroup>
  );
  const renderStepContent = (): ReactElement => {
    if (stepNumber === 2 && !isCreatePassword && !isChangeEmail) {
      return <RegistrationCredentials email={email} />;
    }
    if (
      stepNumber === 2 &&
      !isEmailFound &&
      isChangeEmail &&
      !isCreatePassword
    ) {
      return (
        <ChangeEmail
          handleStepper={handleOnClickContinue}
          handleOnReverseStepper={handleOnReverseStep}
          mtaCustomerId={mtaCustomerId}
          handleChangeEmailToggle={handleChangeEmailToggle}
          isEmailFound={isEmailFound}
        />
      );
    }
    if (stepNumber === 2 && isChangeEmail && !isCreatePassword) {
      return (
        <ChangeEmail
          handleStepper={handleOnClickContinue}
          handleOnReverseStepper={handleOnReverseStep}
          mtaCustomerId={mtaCustomerId}
          handleChangeEmailToggle={handleChangeEmailToggle}
          isEmailFound={isEmailFound}
        />
      );
    }
    if (stepNumber === 2 && isCreatePassword) {
      return (
        <SetupPassword
          handleStepper={handleOnClickContinue}
          isForgotPassword={false}
          handleOnPassword={handleOnPwd}
        />
      );
    }
    if (stepNumber === 3 && !isOTP) {
      return (
        <RegistrationAuthenticate
          phoneNumber={phoneNumber}
          isNumberFound={isContactFound}
        />
      );
    }
    if (stepNumber === 3 && isOTP) {
      return (
        <OTPAuthentication
          isForgotEmail={false}
          sessionId={journeySessionId}
          phoneNumber={phoneNumber}
          handleSendOtp={handleOnClickContinue}
          mtaCustomerId={mtaCustomerId}
          isForgetPassword={false}
          brand={brand}
          password={passwordValue}
          handleSessionId={handleOnSessionId}
          expiryTime={expiryTime}
        />
      );
    }
    return (
      <RegistrationYourDetails
        isContinueClicked={isContinueClicked}
        handleOnPostCodeError={handleOnPostCodeError}
        handleOnDateError={handleOnDateError}
        handleOnDate={handleOnDate}
        handleOnPostCode={handleOnPostCode}
      />
    );
  };

  const renderHr = (text: string): ReactElement => (
    <>
      <GridItem>
        <Hr marginTop="05" marginBottom="05" />
      </GridItem>
      <GridItem>
        <Text size="s2">{text}</Text>
      </GridItem>
    </>
  );
  const renderLink = (): ReactElement => {
    if (stepNumber === 2 && !isCreatePassword) {
      return (
        <LinkButton
          handleButtonClick={handleNavigate}
          testId="howWeEmailUseLink"
          dataQaId={dataQaIds.loginAndRegistration.howWeEmailUseLink}
        >
          {howUseEmailLabel}
        </LinkButton>
      );
    }
    if (stepNumber === 3 && !isOTP) {
      return <PhoneLink dataQaId="some-id" brand={brand} />;
    }
    return null;
  };

  const renderBottomContent = (): ReactElement => {
    if (stepNumber === 2 && !isCreatePassword && !isChangeEmail) {
      return (
        <>
          {renderHr(yourDataLabel)}
          <GridItem>
            <StyledInlineBox>{renderLink()}</StyledInlineBox>
          </GridItem>
        </>
      );
    }
    if (stepNumber === 3 && !isOTP) {
      return (
        <>
          {isContactFound && renderHr(numberNotRightLabel)}
          <GridItem>
            <ContentGroup
              marginTop={isContactFound ? '04' : 'none'}
              marginBottom="none"
            >
              <StyledInlineBox>{renderLink()}</StyledInlineBox>
            </ContentGroup>
          </GridItem>
        </>
      );
    }
    return null;
  };
  const handleOnRender = (): ReactElement =>
    isApiCalled && utilsStore.showSpinner ? (
      <SpinnerContainer brand={brand} />
    ) : (
      <>
        <Grid>
          <GridItem lg={2} />
          <GridItem lg={8}>
            <NavigatePreviousLink
              headingText=""
              navigateRoute={handleOnPreviousLink}
            />
            <Text size="s2" weight="bold">
              {createYourAccountLabel}
            </Text>
            <Heading size="s7" marginBottom="03" aria-level={2}>
              {renderStepTitle()}
            </Heading>
            <FormStepper
              activeStep={stepNumber}
              numberOfSteps={numberOfSteps}
            />
            <StyledBox padding={stepNumber !== 1}>
              <Grid>
                {renderStepContent()}
                <GridItem>{renderButtonGroup()}</GridItem>
                {renderBottomContent()}
              </Grid>
            </StyledBox>
            <NeedHelp
              dataQaId={dataQaIds.needhelp.regContainer}
              brand={brand}
            />
          </GridItem>
          <GridItem lg={2} />
        </Grid>
        {isCustomerIdentificationError && (
          <RegistrationModal
            brand={brand}
            isAgreementFound={isAgreementFound}
            isContactFound={isContactFound}
            isCustomerRecordFound={isCustomerRecordFound}
            handleCloseWarningDialog={handleCloseWarningDialog}
            handleOnNavigateHomepage={handleOnNavigateHomepage}
          />
        )}
      </>
    );

  return handleOnRender();
}
