import React, { ReactElement, useCallback, useRef, useState } from 'react';

import {
  ContentGroup,
  Grid,
  GridItem,
  Heading,
  Spinner,
  Text,
  TextField,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import { YourDetailsWssProps } from './yourDetailsWss.config';
import { getAgreementAPI } from '../../apis/getAgreementAPI';
import InfoIcon from '../../assets/icons/infoIcon';
import WarningIcon from '../../assets/icons/warningIcon';
import { DATE_PATTERN } from '../../constants/REGEX';
import dataQaIds from '../../dataModel/dataQaIds';
import {
  StyledHrWss,
  StyledLinkBGridItem,
  StyledLinkButtonBox,
  StyledDetailsBox,
  StyledParagraphWss,
  StyledFieldBox,
  StyledWssButton,
  StyledBox,
  StyledBackgroundPage,
  StyledFAQLink,
} from '../../routes/loginAndRegistrationWss/LoginAndRegistrationWss.styled';
import { MultiAgreement } from '../../routes/loginAndRegistrationWss/multiAgreementWss';
import * as routes from '../../routes/manifest';
import {
  RegistrationContent,
  RegistrationModalParams,
} from '../../routes/registration/Registration.config';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import { changeStepAction } from '../../store/action/formStep.action';
import {
  DOBPostcodeContractsDataAction,
  UpdateDOBPostcodeAction,
} from '../../store/action/registration.action';
import { toggleAction } from '../../store/action/toggler.action';
import { ShowHideSpinnerAction } from '../../store/action/utilsModal.action';
import { dateFormat } from '../../utils/dateFormat';
import { isEmptyString } from '../../utils/isEmptyString';
import { DateProps } from '../appConfig/AppConfig';
import DataModal from '../dataModal/dataModal';
import DateFieldWss from '../dateFieldWss/dateFieldWss';
import LinkButton from '../linkButton/LinkButton';
import PostcodeField from '../postcodeField/postcodeField';

export default function YourDetailsWss({
  isMoreThanOneCustomer,
  isRegistraionWss,
  headingSubText,
  subText,
  buttonText,
  headingText,
  isForgotUserIdWss,
  isForgotPwdWss,
  handleOnPostCode,
  handleClick,
  handleOnPassDetails,
  isMultiAgreement,
  handleMultiAgreement,
  setCreateAccountFAQ,
}: YourDetailsWssProps): ReactElement {
  const {
    yourDetailsHeading,
    goBackLink,
    needHelpText,
    postCodeFieldLabel,
    dateFieldLabel,
    loginHelpText,
    dateErrorMessageLabel,
    enterInfoLabel,
    agreementFieldLabel,
    agreementFieldPlaceHolder,
    alreadyRegisteredLabel,
    alreadyRegisteredDescription,
    registrationLabel,
    noContactFoundDescription,
    noContactFoundHeading,
    tryAgainLink,
    noCustomerRecordFoundHeading,
    detailsNotFoundSubText,
    forgotPasswordHeading,
    createAccountFAQsText,
  } = useContent<RegistrationContent>();
  const { dob, postcode, customerData } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const navigate = useNavigate();
  const theme: any = useTheme();
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const agreementNumber = useRef({} as HTMLInputElement);
  const dispatch = useDispatch();
  const [isDateFieldsEmpty, setIsDateFieldsEmpty] = useState(false);
  const [postCode, setPostCode] = useState(postcode || '');
  const isRegisteredCustomer = useRef(customerData[0].isRegistered);
  const [isContactFound, setIsContactFound] = useState(
    !isEmptyString(customerData[0].phoneNumber),
  );
  const [isDataFound, setIsDataFound] = useState(true);
  const [agreement, setAgreement] = useState('');
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [date, setDate] = useState<DateProps>(
    dob || {
      day: undefined,
      month: undefined,
      year: undefined,
    },
  );
  const handleNeedHelpNavigate = (): void => {
    navigate(routes.HelpCentre);
  };
  const handleOnPostCodeValue = (value: string): void => {
    handleOnPostCode(value);
    setPostCode(value);
  };
  const handleLoginNavigate = (): void => {
    navigate(routes.LoginWss);
    dispatch(DataModelAction(false));
  };

  const handleModelButtonClick = (): void => {
    navigate(routes.Registration);
    dispatch(DataModelAction(false));
    setIsContactFound(true);
  };
  const datePattern = new RegExp(DATE_PATTERN);
  const [isDateError, setIsDateError] = useState(true);
  const [isPostCodeError, setIsPostCodeError] = useState(true);
  const handleGoBackLink = (): void => {
    if (isMultiAgreement) {
      handleMultiAgreement(false);
      dispatch(UpdateDOBPostcodeAction(postCode, date, false));
    } else {
      navigate(routes.LoginWss);
      dispatch(
        UpdateDOBPostcodeAction('', { day: '', month: '', year: '' }, true),
      );
    }
  };

  const handleOnDate = (value: any) => {
    setDate(value);
  };
  const emptyDayField = useCallback(
    (): boolean => isEmptyString(date.day),
    [date],
  );
  const emptyMonthField = useCallback(
    (): boolean => isEmptyString(date.month),
    [date],
  );
  const emptyYearField = useCallback(
    (): boolean => isEmptyString(date.year),
    [date],
  );
  const handleOnEmptyFields = (): boolean => {
    return emptyDayField() || emptyMonthField() || emptyYearField();
  };
  const handleErrorMessage = (isValidDate: boolean) => {
    setIsDateError(isValidDate);
    setIsDateFieldsEmpty(false);
  };
  const handleValidPostCode = (value: boolean) => {
    setIsPostCodeError(value);
  };
  const handleDataFormat = (): boolean =>
    datePattern.test(dateFormat(date, '-'));

  const handleOnContinue = (): void => {
    setIsContinueClicked(true);
    if (handleOnEmptyFields() || !handleDataFormat())
      setIsDateFieldsEmpty(true);
    if (!isDateError && !isPostCodeError) handleClick(date);
  };
  const handleMtaAgreement = (res: any) => {
    setIsDataFound(true);
    dispatch(
      DOBPostcodeContractsDataAction([res], {
        isDetailsFound: true,
        isApiFailed: false,
        isMoreThanOneUser: true,
      }),
    );
    if (!res.isRegistered && !isEmptyString(res.phoneNumber)) {
      dispatch(
        DOBPostcodeContractsDataAction([res], {
          isDetailsFound: true,
          isApiFailed: false,
          isMoreThanOneUser: false,
        }),
      );
      handleOnPassDetails([res], { isRegisterWss: true });
      dispatch(changeStepAction(2));
      dispatch(toggleAction(false));
    } else if (!res.isRegistered && isEmptyString(res.phoneNumber)) {
      setIsContactFound(false);
      dispatch(DataModelAction(true));
    } else {
      setIsDataFound(true);
      setIsContactFound(true);
      isRegisteredCustomer.current = res.isRegistered;
      dispatch(DataModelAction(true));
    }
  };

  const handleApiResponse = (res: any) => {
    if (isEmptyString(res.redirectLink)) {
      handleMtaAgreement(res);
    } else window.location.href = res.redirectLink;
    setIsApiCalled(false);
  };

  const handleOnNext = (): void => {
    setIsApiCalled(true);
    getAgreementAPI(
      `${agreementNumber.current.value}/${dateFormat(date, '-')}/${postCode}`,
      '/1',
    )
      .then((res: any) => {
        handleApiResponse(res);
      })
      .catch(() => {
        setIsApiCalled(false);
        setIsDataFound(false);
        dispatch(DataModelAction(true));
        dispatch(ShowHideSpinnerAction(false));
      });
  };

  const renderModal = ({
    heading,
    description,
    dataQaId,
  }: RegistrationModalParams): ReactElement => {
    return (
      <DataModal
        brand={undefined}
        icon={
          <WarningIcon
            width="72"
            height="72"
            colour={theme.color_icon_inverse_default_2}
          />
        }
        heading={heading}
        description={description}
        isPhoneLink
        buttonTxt={tryAgainLink}
        link={needHelpText}
        onClick={handleModelButtonClick}
        onClickLink={handleNeedHelpNavigate}
        dataQaId={dataQaId}
        isWss
      />
    );
  };
  const renderBottomContent = (): ReactElement => {
    return (
      <>
        <StyledHrWss marginBottom="none" marginTop="none" />
        <Grid alignX="justify" alignY="center">
          <StyledLinkBGridItem lg={6} md={6} sm={6}>
            <StyledLinkButtonBox alignX="center">
              <LinkButton
                handleButtonClick={handleGoBackLink}
                testId="goBackLink"
              >
                {goBackLink}
              </LinkButton>
            </StyledLinkButtonBox>
          </StyledLinkBGridItem>
          <GridItem lg={6} md={6} sm={6}>
            <StyledLinkButtonBox alignX="center">
              <LinkButton
                handleButtonClick={handleNeedHelpNavigate}
                testId="needHelpLink"
              >
                {needHelpText}
              </LinkButton>
            </StyledLinkButtonBox>
          </GridItem>
        </Grid>
      </>
    );
  };
  const renderContent = (): ReactElement => (
    <>
      {isForgotPwdWss && (
        <Text size="s2" marginBottom="05" weight="bold">
          {forgotPasswordHeading}
        </Text>
      )}
      <StyledParagraphWss
        size="s1"
        marginBottom={isRegistraionWss ? '05' : '04'}
        marginTop={isForgotPwdWss ? '05' : 'none'}
        weight="normal"
      >
        {isMoreThanOneCustomer ? enterInfoLabel : headingText}
      </StyledParagraphWss>
      {isForgotUserIdWss && (
        <StyledParagraphWss size="s1" marginBottom="04" weight="normal">
          {headingSubText}
        </StyledParagraphWss>
      )}
      {isRegistraionWss && !isMoreThanOneCustomer && (
        <Heading size="s4" marginBottom="04" aria-level={3}>
          {yourDetailsHeading}
        </Heading>
      )}
      {!isMoreThanOneCustomer ? (
        <>
          <DateFieldWss
            handleDateValue={handleOnDate}
            FieldLabel={dateFieldLabel}
            isDateFieldsEmpty={isDateFieldsEmpty}
            errorMessage={dateErrorMessageLabel}
            handleErrorMessage={handleErrorMessage}
            handleOnEmptyFields={handleOnEmptyFields}
            handleDataFormat={handleDataFormat}
          />
          <ContentGroup marginBottom="none">
            <Text size="s2" weight="bold">
              {postCodeFieldLabel}
            </Text>
          </ContentGroup>
          <ContentGroup marginBottom="05">
            <StyledFieldBox>
              <PostcodeField
                isContinueClicked={isContinueClicked}
                handleOnPostCode={handleOnPostCodeValue}
                handleOnPostCodeError={handleValidPostCode}
              />
            </StyledFieldBox>
          </ContentGroup>
        </>
      ) : (
        <ContentGroup marginBottom="05">
          <StyledFieldBox>
            <TextField
              label={agreementFieldLabel}
              name="agreementNumber"
              placeholder={agreementFieldPlaceHolder}
              inputRef={agreementNumber}
              onBlur={(event) => setAgreement(event.target.value)}
              data-testid="agreementField"
              defaultValue={agreement}
            />
          </StyledFieldBox>
        </ContentGroup>
      )}
      {isRegistraionWss ? (
        !isMoreThanOneCustomer && (
          <StyledParagraphWss size="s1" weight="normal">
            {subText}
            <StyledFAQLink
              as="a"
              onClick={() => {
                setCreateAccountFAQ(true);
                navigate(routes.HelpCentre);
              }}
            >
              {createAccountFAQsText}
            </StyledFAQLink>
          </StyledParagraphWss>
        )
      ) : (
        <StyledParagraphWss size="s1" weight="normal">
          {subText}
        </StyledParagraphWss>
      )}
      <ContentGroup marginBottom={isRegistraionWss ? '05' : '04'}>
        <StyledWssButton
          width="fluid"
          onClick={!isMoreThanOneCustomer ? handleOnContinue : handleOnNext}
          data-testid="continueBtn"
        >
          {buttonText}
        </StyledWssButton>
      </ContentGroup>
      {isRegistraionWss && (
        <StyledLinkButtonBox alignX="center">
          <LinkButton
            handleButtonClick={handleNeedHelpNavigate}
            testId="needHelpBtn"
          >
            {needHelpText}
          </LinkButton>
        </StyledLinkButtonBox>
      )}
    </>
  );
  return (
    <>
      <StyledBox
        marginBottom="07"
        marginTop={!isRegistraionWss ? '07' : 'none'}
      >
        {isApiCalled ? (
          <Grid alignX="center">
            <StyledBackgroundPage marginTop="10" marginBottom="10">
              <Spinner />
            </StyledBackgroundPage>
          </Grid>
        ) : (
          <>
            <StyledDetailsBox marginBottom="none">
              {!isRegistraionWss && !isMoreThanOneCustomer && (
                <>
                  <Heading size="s4" marginBottom="none" aria-level={3}>
                    {loginHelpText}
                  </Heading>
                  <StyledHrWss marginBottom="05" marginTop="05" />
                </>
              )}
              {isMultiAgreement ? (
                <MultiAgreement
                  handleResponse={handleOnPassDetails}
                  isForgotEmail={isForgotUserIdWss}
                />
              ) : (
                renderContent()
              )}
            </StyledDetailsBox>
            {!isRegistraionWss && renderBottomContent()}
          </>
        )}
      </StyledBox>
      {!isDataFound &&
        isMoreThanOneCustomer &&
        renderModal({
          heading: noCustomerRecordFoundHeading,
          description: detailsNotFoundSubText,
          dataQaId: {
            btn: dataQaIds.loginAndRegistration.tryAgainWssBtn,
            link: dataQaIds.loginAndRegistration.needHelpLink,
          },
        })}
      {!isContactFound &&
        isMoreThanOneCustomer &&
        renderModal({
          heading: noContactFoundHeading,
          description: noContactFoundDescription,
          dataQaId: {
            btn: dataQaIds.loginAndRegistration.tryAgainWssBtn,
            link: dataQaIds.loginAndRegistration.needHelpLink,
          },
        })}
      {isRegisteredCustomer.current && isMoreThanOneCustomer && (
        <DataModal
          brand={undefined}
          icon={<InfoIcon colour={theme.color_icon_inverse_default_2} />}
          heading={alreadyRegisteredLabel}
          description={alreadyRegisteredDescription}
          buttonTxt={registrationLabel}
          link={needHelpText}
          onClick={handleLoginNavigate}
          onClickLink={handleNeedHelpNavigate}
          dataQaId={{
            btn: dataQaIds.loginAndRegistration.loginWssBtn,
            link: dataQaIds.loginAndRegistration.needHelpLink,
          }}
          isWss
        />
      )}
    </>
  );
}
