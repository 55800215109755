import { Hr, Accordion, ContentGroup } from '@constellation/core';
import styled from 'styled-components';

export const StyledHr = styled(Hr)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const StyledAccordion = styled(Accordion)`
  background-color: ${({ theme }) =>
    theme.color_background_panel_brand_2} !important;
  border-color: #e6e6e6 !important;
  &:first-child {
    border-top: none !important;
  }
  &:first-child ul {
    margin-top: 0 !important;
  }
  &:last-child {
    border-bottom: none !important;
  }
  > div span p {
    color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
    margin-bottom: ${({ theme }) => theme.spacing_01} !important;
  }
  > button {
    padding: ${({ theme }) => theme.spacing_04} 0 !important;
  }
  > button,
  > button span {
    color: ${({ theme }) => theme.color_text_inverse_default_1} !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    text-decoration: none !important;
    background: none !important;
  }
  &:hover,
  > button:hover {
    background-color: ${({ theme }) =>
      theme.color_background_panel_brand_2} !important;
  }
  > button:focus {
    box-shadow: none !important;
  }
  > button svg {
    color: #0f7eb1 !important;
  }
  > button div div:last-child {
    padding-left: 8px;
  }
`;

export const StyledContentGroup = styled(ContentGroup)`
  margin-bottom: 0 !important;
`;
