import { AppContent } from './AppContent';
import blackHorseContent from './black-horse';
import bosContent from './bos';
import halifaxContent from './halifax';
import lloydsContent from './lloyds';

import { BrandProps } from 'components/appConfig/AppConfig';

interface AppContentResolverContext extends BrandProps {}

export default ({ brand }: AppContentResolverContext): AppContent => {
  switch (brand) {
    case 'lloyds':
      return lloydsContent;

    case 'halifax':
      return halifaxContent;

    case 'bos':
      return bosContent;

    case 'black-horse':
      return blackHorseContent;

    default:
      return lloydsContent;
  }
};
