import { CommonContent } from '../../content/CommonContent';

export interface MarketingPreferencesContent extends CommonContent {
  marketingIntroText: string;
  listItemOne: string;
  listItemTwo: string;
  listItemThree: string;
  accordionLabel: string;
  accordionIntroText: string;
  accordionListItemOne: string;
  accordionListItemTwo: string;
  accordionOutroText: string;
  yesLabel: string;
  noLabel: string;
  saveButton: string;
  cancelButton: string;
  createAccount: string;
  marketing: string;
  tellUs: string;
  marketingpreferences: string;
  skipForNow: string;
  savePreference: string;
  selectionNotSavedHeading: string;
  selectionDescription: string;
  selectionButton: string;
  skipDescription: string;
  doItLaterButton: string;
  goBackToPrefsButton: string;
  perMarketingSwitches: {
    label: string;
    name: string;
    furtherInfo: string;
  }[];
}

export interface IDeviceInfo {
  isMobileFound: boolean;
  isTabletFound?: boolean;
}

export interface IMarketingPreferencesProps {
  switchArray: {
    name: string;
    label: string;
    furtherInfo: string;
  }[];
  handleOnSaveBtnClick: (preferences) => void;
  selected: any;
  handleCancelChanges: () => void;
  accessToken?: string;
  email?: string;
}

export enum MarketingInfoCode {
  email = 'mktngprefEmail',
  post = 'mktngprefPost',
  emailDataCollection = 'mktngprefemaildatacollection',
  onlineAccount = 'mktngprefdigitalinbox',
  phone = 'mktngprefPhone',
  textMessage = 'mktngprefSMS',
  thirdPartyOffers = 'mktngprefthirdpartyoffer',
}

export enum MarketingSelect {
  yes = 'Y',
  no = 'N',
}
