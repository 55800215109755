import React, { ReactElement } from 'react';

import { useContent } from '@interstellar/react-app-content';

import {
  RegistrationContent,
  RegistrationModalParams,
  RegistrationModalProps,
} from './Registration.config';
import WarningIcon from '../../assets/icons/warningIcon';
import DataModal from '../../components/dataModal/dataModal';
import dataQaIds from '../../dataModel/dataQaIds';

export default function RegistrationModal({
  handleCloseWarningDialog,
  handleOnNavigateHomepage,
  isAgreementFound,
  isContactFound,
  isCustomerRecordFound,
  brand,
}: RegistrationModalProps): ReactElement {
  const {
    noContactFoundHeading,
    noContactFoundDescription,
    backToHomeButton,
    contactUsLink,
    noCustomerRecordFoundDescription,
    noCustomerRecordFoundHeading,
  } = useContent<RegistrationContent>();
  const NoContactFoundModalParams: RegistrationModalParams = {
    heading: noContactFoundHeading,
    description: noContactFoundDescription,
    dataQaId: {
      btn: dataQaIds.loginAndRegistration.noContactFoundModalBtn,
      link: dataQaIds.loginAndRegistration.noContactFoundModalLink,
    },
  };
  const NoCustomerRecordFoundModalParams: RegistrationModalParams = {
    heading: noCustomerRecordFoundHeading,
    description: noCustomerRecordFoundDescription,
    dataQaId: {
      btn: dataQaIds.loginAndRegistration.noCustomerRecordFoundModalBtn,
      link: dataQaIds.loginAndRegistration.noCustomerRecordFoundModalLink,
    },
  };
  const renderModal = (params: RegistrationModalParams): ReactElement => (
    <DataModal
      icon={<WarningIcon colour="white" />}
      heading={params.heading}
      description={params.description}
      buttonTxt={backToHomeButton}
      onClick={handleOnNavigateHomepage}
      link={(!isAgreementFound || !isCustomerRecordFound) && contactUsLink}
      closeIcon={false}
      isPhoneLink={!isContactFound}
      onClose={handleCloseWarningDialog}
      onClickLink={handleCloseWarningDialog}
      dataQaId={{
        btn: params.dataQaId.btn,
        link: params.dataQaId.link,
      }}
      brand={brand}
    />
  );
  if (!isContactFound) return renderModal(NoContactFoundModalParams);
  if (!isAgreementFound || !isCustomerRecordFound)
    return renderModal(NoCustomerRecordFoundModalParams);
}
