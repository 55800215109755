import React, { ReactElement } from 'react';

interface ICircleIconProps {
  colour: string;
  width?: string;
  height?: string;
}
export default function circleIcon({ colour }: ICircleIconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M11.9999 24.0117C10.3599 24.0117 8.80989 23.6967 7.34991 23.0667C5.88993 22.4367 4.61494 21.5767 3.52496 20.4867C2.43497 19.3967 1.57498 18.1217 0.944989 16.6617C0.314996 15.2017 0 13.6517 0 12.0117C0 10.3517 0.314996 8.79172 0.944989 7.33172C1.57498 5.87172 2.43497 4.60172 3.52496 3.52172C4.61494 2.44172 5.88993 1.58672 7.34991 0.956719C8.80989 0.326719 10.3599 0.0117188 11.9999 0.0117188C13.6598 0.0117188 15.2198 0.326719 16.6798 0.956719C18.1398 1.58672 19.4098 2.44172 20.4898 3.52172C21.5697 4.60172 22.4247 5.87172 23.0547 7.33172C23.6847 8.79172 23.9997 10.3517 23.9997 12.0117C23.9997 13.6517 23.6847 15.2017 23.0547 16.6617C22.4247 18.1217 21.5697 19.3967 20.4898 20.4867C19.4098 21.5767 18.1398 22.4367 16.6798 23.0667C15.2198 23.6967 13.6598 24.0117 11.9999 24.0117ZM11.9999 22.2117C14.8398 22.2117 17.2498 21.2167 19.2298 19.2267C21.2097 17.2367 22.1997 14.8317 22.1997 12.0117C22.1997 9.17172 21.2097 6.76172 19.2298 4.78172C17.2498 2.80172 14.8398 1.81172 11.9999 1.81172C9.17989 1.81172 6.77492 2.80172 4.78494 4.78172C2.79497 6.76172 1.79998 9.17172 1.79998 12.0117C1.79998 14.8317 2.79497 17.2367 4.78494 19.2267C6.77492 21.2167 9.17989 22.2117 11.9999 22.2117Z"
        fill={colour}
      />
    </svg>
  );
}
