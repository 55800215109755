import React, { ReactElement, useRef, useState } from 'react';

import {
  GridItem,
  ContentGroup,
  Text,
  TextField,
  Grid,
  Icon,
} from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';

import { ILoginCredentialsProps, WelcomeContent } from './WelcomePage.config';
import {
  StyledInlineBox,
  StyledTextBox,
} from '../../components/appConfig/common.styled';
import LinkButton from '../../components/linkButton/LinkButton';
import PasswordField from '../../components/passwordField/passwordField';
import { REGEX } from '../../constants/REGEX';
import dataQaIds from '../../dataModel/dataQaIds';
import { isEmptyString } from '../../utils/isEmptyString';
import * as routes from '../manifest';

export default function LoginCredentials({
  handleOnLoginCredentialError,
  handlePwd,
  handleUserName,
  isLoginCredentialError,
}: ILoginCredentialsProps): ReactElement {
  const emailValue = useRef({} as HTMLInputElement);
  const [emailErrMsg, setEmailErrMsg] = useState('');
  const emailPattern = new RegExp(REGEX.EMAIL_PATTERN);
  const navigate = useNavigate();
  const {
    emailErrorMessage,
    logIntoAccountLabel,
    detailsDontLookRightError,
    emailAddressLabel,
    forgottenEmailLink,
    passwordLabel,
    forgottenPasswordLink,
  } = useContent<WelcomeContent>();
  const handleOnEmailSetErrMsg = (value: string): void => setEmailErrMsg(value);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEmailKeyDown = (event: any): boolean => {
    if (REGEX.SPECIAL_CHARACTERS_EMAIL.indexOf(event.key) !== -1) {
      event.preventDefault();
      return false;
    }
    handleOnEmailSetErrMsg('');
    handleOnLoginCredentialError(false);
    return true;
  };
  const handleOnPwdKeyDown = (): void => {
    handleOnLoginCredentialError(false);
  };
  const handleOnEmailBlurEvt = (): void => {
    if (
      !isEmptyString(emailValue.current.value) &&
      !emailPattern.test(emailValue.current.value)
    ) {
      handleOnEmailSetErrMsg(emailErrorMessage);
    } else {
      handleOnEmailSetErrMsg('');
    }
    handleUserName(emailValue.current.value);
  };
  const handlePwdBlurEvt = (
    event: React.FocusEvent<HTMLInputElement, Element>,
  ): void => {
    const { value } = event.target;
    handlePwd(value);
  };
  const renderForgotEmailLink = (): void => {
    navigate(routes.ForgotEmail);
  };
  const handleOnForgotPassword = (): void => {
    navigate(routes.ForgotPassword);
  };
  const renderErrorMessage = (errMessage: string): ReactElement => (
    <GridItem>
      <StyledTextBox marginTop="none" marginBottom="07" bgColor="warning">
        <GridItem>
          <Grid>
            <GridItem md={12} sm={12} lg={12}>
              <Icon name="warning" color="warning" iconSize="lg" />
            </GridItem>
            <GridItem md={12} sm={12} lg={12}>
              <ContentGroup
                marginLeft="none"
                marginRight="none"
                marginBottom="none"
                marginTop="04"
              >
                <Text size="s2" align="left">
                  {errMessage}
                </Text>
              </ContentGroup>
            </GridItem>
          </Grid>
        </GridItem>
      </StyledTextBox>
    </GridItem>
  );
  return (
    <>
      <Grid alignX="center">
        <ContentGroup marginBottom="05">
          <Text size="s5">{logIntoAccountLabel}</Text>
        </ContentGroup>
      </Grid>
      {isLoginCredentialError && renderErrorMessage(detailsDontLookRightError)}
      <GridItem>
        <ContentGroup marginBottom="none">
          <TextField
            name="email"
            label={emailAddressLabel}
            inputRef={emailValue}
            onKeyDown={handleEmailKeyDown}
            onBlur={handleOnEmailBlurEvt}
            marginBottom="04"
            data-testid="emailId"
            error={emailErrMsg}
            inputWidth="fluid"
            data-qa-id={dataQaIds.loginAndRegistration.loginCredentialsEmail}
            autoComplete="off"
          />
        </ContentGroup>
        <StyledInlineBox>
          <LinkButton
            handleButtonClick={renderForgotEmailLink}
            testId="forgot-email-link"
            dataQaId={
              dataQaIds.loginAndRegistration.loginCredentialsForgotEmailLink
            }
          >
            {forgottenEmailLink}
          </LinkButton>
        </StyledInlineBox>
      </GridItem>
      <GridItem>
        <ContentGroup marginTop="05" marginBottom="none">
          <PasswordField
            label={passwordLabel}
            name="password"
            testId="password"
            onBlur={handlePwdBlurEvt}
            onKeyDown={handleOnPwdKeyDown}
            onChange={undefined}
            inputRef={undefined}
            error={undefined}
            inputWidth="fluid"
            dataQaId={dataQaIds.loginAndRegistration.loginCredentialsPassword}
            fieldMarginBottom="04"
          />
        </ContentGroup>
        <ContentGroup marginBottom="07" marginTop="none">
          <StyledInlineBox>
            <LinkButton
              handleButtonClick={handleOnForgotPassword}
              testId="forgot-password-link"
              dataQaId={
                dataQaIds.loginAndRegistration
                  .loginCredentialsForgotPasswordLink
              }
            >
              {forgottenPasswordLink}
            </LinkButton>
          </StyledInlineBox>
        </ContentGroup>
      </GridItem>
    </>
  );
}
