import React, { ReactElement } from 'react';

export default function chevronRightWssIcon(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6667 8.00391L4.96286 16.0029L4 15.0029L10.74 8.00294L4 1.00294L4.96287 0.00292969L12.6667 8.00391Z"
        fill="#0F7EB1"
      />
    </svg>
  );
}
