import React, { ReactElement } from 'react';

import { useSelector } from 'react-redux';

import { StyledText } from './styledPreviousLinkStepper';
import { RootState } from '../../store';

export default function PreviousLinkStepper(): ReactElement {
  const data = useSelector((state: RootState) => state.FormStepsReducer);

  return (
    <StyledText size="s2">
      {`Step ${data.stepNumber} of ${data.numberOfSteps}`}
    </StyledText>
  );
}
