import { AppContent } from '../AppContent';

const privacyPolicy: AppContent['privacyPolicy'] = {
  PrivacyNoticeHeading: 'Privacy Notice',
  LastUpdatedHeading: 'Last updated May 2024',
  PrivacyPromiseHeading: 'Our Privacy Promise',
  PromiseSubHeading: 'We promise:',
  PromiseTxt:
    'To keep your personal information safe and private.// Not to sell your personal information.//To give you ways to manage and review your marketing choices at any time.',
  PersonalInformationHeading: 'HOW WE USE YOUR PERSONAL INFORMATION',
  PersonalInformationContent:
    'This privacy notice explains how companies within Lloyds Banking Group use and look after your personal information. This includes what you tell us about yourself, what we learn by having you as a customer, and your marketing choices. This notice also tells you about your privacy rights and how the law protects you. ',
  PrivacyWhoHeading: `WHO THIS PRIVACY NOTICE APPLIES TO`,
  PrivacyWhoTxt: `This privacy notice will apply to you if you are or have been a customer of Lloyds Banking Group. If you have provided your personal information to Lloyds Banking Group, but you are not a customer, it may also apply to you. For example, if you have applied for a product or raised a complaint with us.`,
  PrivacyWhoSubTxt: `Lloyds Banking Group offers a range of different products and services. Each one may have different requirements for the collection and use of your personal information. For this reason, some sections of this notice do not apply to all Lloyds Banking Group products and services.`,
  PrivacyWhoList: `This Privacy Notice applies to both personal customer and business customer relationships. For business customer relationships or information, the following definitions apply:  `,
  PrivacyWhoListItems: `You/your means Key Account Parties and Account Parties of the business.//
Key Account Parties means individuals who are sole traders, partners, directors, company secretaries, member or beneficial owners, trustees or other controlling officials within the business.//
Account Parties means individuals (other than Key Account Parties) who have provided their personal information in connection with the products and services provided to the business by us.//
The business means the business you are associated with and which is our customer, whether it is a company, limited liability partnership, partnership, sole trader, or other entity such as a special purpose vehicle, club, charity or trust.`,
  PersonalInfoHeading: 'PERSONAL INFORMATION AND THE LAW',
  SectionContent:
    'This section tells you who we are, what your personal information is, and how we get it. It explains how the law protects you by controlling what is allowed to happen to it.',
  SectionTxt: `1. Who we are// 2. How the law protects you//3. Groups of personal information//4. Where we collect personal information from//5. How long we keep your personal information//6. If you choose not to give personal information//7. Cookies and similar tracking technologies`,
  SectionSubTxtHeading: 'HOW PERSONAL INFORMATION IS USED',
  SectionSubTxt: `This tells you who we share personal information with. It explains what it's used for in marketing, to provide credit and to combat fraud - and how this can affect you.//8. Who we share your personal information with//9. How we work out what marketing you receive//10. How we use your personal information to make automated decisions//11. Credit Reference Agencies (CRAs)//12. Fraud Prevention Agencies (FPAs)//13. Sending data outside of the UK, Isle of Man, Channel Islands and EEA.`,
  TheControlHeading: 'THE CONTROL YOU HAVE',
  TheControlTxt:
    'This section explains your data privacy rights and how you can action them. It also covers how to contact us or the data privacy regulator, or how to make a complaint.',
  TheControlSubTxt: `14. Your data privacy rights//
  15. How to contact us`,
  WhoWeAreHeading: `1. WHO WE ARE`,
  PersonalInfoSubTxt: `This section gives you the legal name of the company that holds your personal information. This is known as the ‘legal entity’. It also tells you how you can get in touch with us.`,
  PeronsalInfoService: `Your personal information will be held by Black Horse, which is part of Lloyds Banking Group. Lloyds Banking Group is made up of a mix of companies, set up on different legal entities. We'll let you know which you have a relationship with when you take out a product or service with us.`,
  PeronsalInfoFindOutTxt: `You can find out more about us at <a href="https://www.lloydsbankinggroup.com/who-we-are/our-brands.html" target="_blank" >who we are.</a>`,
  ContactingUsHeading: 'CONTACTING US ABOUT DATA PRIVACY',
  PrivacyNoticeTxt:
    'Please see the ‘How to contact us’ section to contact us about any of the topics set out in this Privacy notice. ',
  LawHeading: '2. HOW THE LAW PROTECTS YOU',
  LegalTxt: `This section sets out the legal reasons we rely on, for each of the ways we may use your personal information.`,
  PrivacyPromiseTxt: `As well as our Privacy Promise, your privacy is protected by law. This section explains how that works.`,
  PersonalInfoHead: `WHAT IS 'PERSONAL INFORMATION'?`,
  PersonalInfoTxt: `'Personal information' means any information that we could use to identify you, directly or indirectly, and any information that relates to you. `,
  PersonalInfoSub: `Data Protection law only applies to personal information.`,
  UsePersonalInfo: `HOW CAN WE USE PERSONAL INFORMATION?`,
  DataProtectionTxt: `Data Protection law says that we can use personal information only if we have a proper reason to do so. This includes sharing it outside Lloyds Banking Group. The law says we must have one or more of these reasons:`,
  DataProtectionSubTxt: `To fulfil a contract we have with you, or//When it is our legal duty, or//When it is in our legitimate interest, or//When you consent to it, or//When it is in the public interest.`,
  BussinessTxt: `When we have a business or commercial reason of our own to use your personal information, this is called a ‘legitimate interest’. We’ll tell you what that is, if we are going to rely on it as the reason for using your personal information. Even then, it must not unfairly go against your interests. `,
  BusinessSub: `You have the right to object to use of your personal information in this way. You can do this by telling us anything that we may need to consider, to understand if our use of your personal information is fair. Please see the ‘Your data privacy rights’ section for more information.`,
  BussinessSubTxt: `The law and other regulations treat some types of sensitive personal information as special. This personal information is called ‘special categories of personal information’. We won’t collect or use these types of personal information without your consent unless the law allows us to do so. If we do, it will only be when it is necessary:`,
  SubstantialSubTxt: `for reasons of substantial public interest, or//
  to establish, exercise or defend legal claims.`,
  ReasonToRely: `Here is a list of all the ways that we may use your personal information, and which of the reasons we rely on to do so. This is also where we tell you what our legitimate interests are where it is the reason for using your personal information. `,
  ReasonToRelySub: `We may rely on different reasons for using the same personal information, as this depends on the situation. For example, we may use your personal information to make an automated decision about you. This could be because of a legal duty, or to fulfil a contract we’ve made with you.`,
  ServingAsCustomer: `SERVING YOU AS A CUSTOMER`,
  OurReasonHeading: `Our reasons for using your personal information:`,
  OurReasonSubTxt: `Your consent.//Fulfilling contracts.//Our legitimate interests.//Our legal duty.`,
  UsePersonalInfoTxt: `What we use your personal information for:`,
  UsePersonalInfoSubTxt: `To manage our relationship with you or your business.//
To study how our customers use products and services from us and other organisations.//
To communicate with you about our products and services.//
To develop and manage our brands, products and services.//
To develop and carry out marketing activities. //
To make automated decisions about you or your products.// 
Managing competitions and promotions.`,
  OurLegitimateInterestsHeading: 'Our legitimate interests:',
  OurLegitimateIntrest:
    'Keeping our records up to date, working out which of our products and services may interest you and telling you about them.//Developing products and services, and what we charge for them.//Defining types of customers for new products or services.//Seeking your consent when we need it to contact you.//Being efficient about how we fulfil our legal and contractual duties.',
  BusinessImprovementHeading: 'BUSINESS IMPROVEMENT',
  BusinessImprovementTxt: `To test new products.//To manage how we work with other companies that provide services to us and our customers.//To develop new ways to meet our customers’ needs and to grow our business.`,
  OurReasonContracts: `Fulfilling contracts.//Our legitimate interests.//Our legal duty.`,
  OurLegitimateProdServices: `Developing products and services, and what we charge for them.//Defining types of customers for new products or services.//Being efficient about how we fulfil our legal and contractual duties.`,
  ManagingOperations: 'MANAGING OUR OPERATIONS',
  UsePeronsalInfoFindOutTxt: `To deliver our products and services.//To make and manage customer payments.//To manage fees, charges and interest due on customer accounts.//To collect and recover money that is owed to us.//To manage and provide treasury and investment products and services.//To archive for historical purposes.//
To analyse our environmental impact and information about climate.`,
  OurLegitimateEfficientTxt: `Being efficient about how we fulfil our legal and contractual duties.//Following rules and guidance from regulators.  `,
  ManagingSecurity: 'MANAGING SECURITY, RISK AND CRIME PREVENTION',
  DetectTxt:
    'To detect, investigate, report, and seek to prevent financial crime.//To manage risk for us and our customers.//To obey laws and regulations that apply to us.//To respond to complaints and seek to resolve them.',
  LegitimateTxt: `Developing and improving how we deal with financial crime, and doing our legal duties in this respect.//
Following rules and guidance from regulators.//
Being efficient about how we fulfil our legal and contractual duties.`,
  BussinessMangement: 'BUSINESS MANAGEMENT',
  BussinessMgmtSubtext: `To run our business in an efficient and proper way. This includes managing our financial position, business capability, planning, adding and testing systems and processes. It also includes managing communications, corporate governance, and audit.
//To exercise our rights set out in agreements or contracts.
`,
  LegitimateInterestTxt: `Following rules and guidance from regulators.//
Being efficient about how we fulfil our legal and contractual duties.`,
  ProcessingSpecialTxt:
    'FOR PROCESSING SPECIAL CATEGORIES OF PERSONAL INFORMATION',
  SubstantialTxt: `Using criminal records data to help prevent, detect and prosecute unlawful acts and fraudulent behaviour.// Using criminal and health information to provide insurance products.// Using your data to understand, test systems and respond to your support needs.// Showing whether we have assessed your situation in the right way.// Passing information to the regulator so they can investigate whether we have acted in the right way.// Using any special categories of personal information as needed to establish, exercise or defend legal claims.// Telling you that we need your consent to process special categories of personal information, when that is what we rely on for doing so.`,
  CriminalRecordTxt: `Substantial public interest// Responding to regulatory requirements// Legal claims// Consent`,
  GroupsOfPersonalInfo: `3. GROUPS OF PERSONAL INFORMATION`,
  GroupsPersonalTxt: `This explains the meaning of the different types of personal information that data protection law covers.`,
  GroupsSubTxt: `We collect, use and hold many different kinds of personal information. The information we collect and use for you will depend on what products and services you have with us. Some of this information we may have received directly from you, some of it we may have learned or created by studying your accounts or transactions, or we may have received it from outside of the Lloyds Banking Group. `,
  GroupsSub: `Even though we may not use all of these types of information for you, we have listed them here to help you understand them.`,
  PersonalInfoType: `TYPE OF PERSONAL INFORMATION:`,
  Consent: `Financial`,
  Description: 'DESCRIPTION:',
  DescriptionSubTxt: `Your financial position, status and history.`,
  Contact: `Contact`,
  ContactSubTxt: `Your name, where you live and how to contact you.`,
  SocioDemo: `Socio-Demographic`,
  SocioDemoTxt: `This includes details about your work or profession, nationality, education and where you fit into general social or income groupings.`,
  Transactional: `Transactional`,
  TransactionalTxt: `Details about payments to and from your accounts, such as where you spend your money and what you buy, and details about insurance claims you make.`,
  Contractual: `Contractual`,
  ContractualTxt: `Details about the products or services we provide to you.`,
  Locational: `Locational`,
  LocationsalTxt: `Data we get about where you are. This may come from your mobile phone or the place where you connect a computer to the internet. It can also include shops where you can buy something with your card.`,
  Behavioural: `Behavioural`,
  BehaviouralTxt: `Details about how you use products and services from us and other organisations, and information about your visits to our websites, branches or offices. `,
  Technical: `Technical`,
  TechnicalTxt: `Details on the devices and technology you use, including your Internet Protocol (IP) address.`,
  Comms: `Communications`,
  CommsTxt: `What we learn about you from letters and emails you write to us, conversations between us, interactions you have with us on social media, and your use of our web chat.`,
  SocioRel: `Social Relationships`,
  SocioRelTxt: `Your family, friends, and other relationships.`,
  OpenData: `Open Data and Public Records`,
  OpenDataTxt: `Details about you that are in public records such as the Electoral Register, and information about you that is openly available on the internet.`,
  UsageData: `Usage Data`,
  UsageDataTxt: `Other data about how you use your products and services.`,
  DocData: `Documentary Data`,
  DocDataTxt: `Details about you that are stored in documents in different formats, or copies of them. This could include things like your passport, driving licence, or birth certificate.`,
  Gender: `Gender Identity`,
  GenderTxt: `Information about the gender that you may identify as.`,
  SpecialCat: `Special categories of personal information`,
  SpecialCatTxt: `The law and other regulations treat some types of personal information as special. We’ll only collect and use these if the law allows us to do so:`,
  SpecialCatList: `Racial or ethnic origin//
Religious, political or philosophical beliefs//
Trade union membership//
Genetic and biometric data (Information such as fingerprints, voice and facial recognition)//
Health data//
Information that could identify aspects of your sex life//
Sexual orientation//
Criminal records of convictions and offences//
Allegations of criminal offences.`,
  SpecialCatSub: `You can read how we may use special types of personal information in the table ‘How the law protects you’.`,
  Choices: `Choices`,
  ChoicesTxt: `Any permissions, consents, or preferences that you give us. This includes things like how you want us to contact you or if you prefer large-print formats.`,
  NationalId: `National Identifier`,
  NationalIdTxt: `A number or code given to you by a government to identify who you are. This may be a National Insurance or social security number, or Tax Identification Number (TIN).`,
  CollectInfoHead: `4. WHERE WE COLLECT PERSONAL INFORMATION FROM`,
  CollectInfoSub: `This section lists all the places where we get data that counts as part of your personal information.`,
  CollectInfoTxt: `We may collect personal information about you from other Lloyds Banking Group companies and any of these sources:`,
  DataHeading: `DATA YOU GIVE TO US`,
  DataTxt: `This covers data that you give, and data provided by people linked with you or your business’s product or service, or people working on your behalf. This could mean a joint account holder, trustee, fellow company director or partner.`,
  DataSubTxt: `When you apply for our products and services//
When you talk to us on the phone, online or in branch, including recorded calls or video chats and notes we make//
When you use our websites, mobile device apps, social media sites, web chat or SignVideo services//
In emails and letters//
In insurance claims or other documents//
In financial reviews and interviews//
In customer surveys//
If you take part in our competitions or promotions.`,
  DataCollectHead: `DATA WE COLLECT WHEN YOU USE OUR SERVICES`,
  AccountActivity: `This covers two things: details about how and where you access our services, and account activity that is shown on your statement. `,
  AccountActivitySubText: `Payment and transaction data - this includes the amount, frequency, type, location, origin and recipients. If you borrow money, it also includes details of repayments and whether they are made on time and in full.//
Profile and usage data - This includes the security details you create and use to connect to our services. It also includes your settings and marketing choices. We also gather data from the devices you use (such as computers and mobile phones) to connect to our internet, mobile and Telephone Banking services. We may also use cookies and similar tracking technologies to collect data. This can be from our websites or mobile apps, and when you receive, open or respond to emails we send you.`,
  AccountCookiesLink: `You can find out more about this in our <a href="/public/cookie-policy" target="_blank">Cookie Policy Page.</a>`,
  OutsideDataTxt: `DATA FROM OUTSIDE ORGANISATIONS`,
  OutsideDataSubTxt: ` Companies that introduce you to us. For example, a price comparison website, a broker, or a store or car dealership offering finance deals through us//
Financial advisers //
Credit card providers like Visa and Mastercard//
Credit reference agencies such as TransUnion, Equifax and Experian//
Insurers//
Retailers//
Social media and other technology providers. For example, when you click on one of our ads//
Fraud prevention agencies//
Other financial services companies. This could be to fulfil a payment or other service as part of a contract with you, or to help prevent, detect and prosecute unlawful acts and fraudulent behaviour//
Accounting software. For example, we may receive data from accounting packages that are linked to accounts you hold with us //
Employers. For example, to get a reference if you apply for a mortgage//
Payroll service providers //
Land agents, such as firms who do property valuations for mortgages//
Public information sources such as the Electoral Register, Companies House, published media and social media. For example, to confirm what you’ve told us //
Products that have a loyalty scheme, agents, suppliers, sub-contractors and advisers - these can be types of firms we use to help us run accounts and services. They can also be specialist companies who advise us on ways to develop and improve our business.//
Market researchers - these firms may get in touch with you on our behalf to ask you for your opinions and feedback. Sometimes these firms will combine what you tell them with data from other sources to study it. They’ll use this to help us understand our customers’ point of view, so we can improve the way we work as a business.//
Firms providing data services. We receive reports that we use to understand our customers better and look for general patterns and trends//
Medical professionals. For some insurance products, we may ask your GP or other medical practitioner to send us a report. We’ll only do this if we get your consent first//
Government and law enforcement agencies`,
  OutsideDataSub: `Companies you ask to share your data with us. This is to do with something called <a href="https://www.lloydsbank.com/help-guidance/everyday-banking/profile-and-settings/open-banking.html" target="_blank">Open Banking</a>, which gives you more freedom and control to use your own banking data. It can make it easier for you or your business to shop around for products like credit cards, savings and current accounts. `,
  KeepYourPersonalInfo: `5. HOW LONG WE KEEP YOUR PERSONAL INFORMATION `,
  InformationTxt: `This section explains how long we may keep your information for and why`,
  InformationSubTxt: `We will keep your personal information for as long as you have a product or are using a service from us, and in most situations, for up to 7 years after. The reasons we may do this are: `,
  InfoReasonSubTxt: `To respond to a question or complaint, or to show whether we gave you fair treatment.//
To understand our customers as part of our own internal research.//
To obey rules that apply to us about keeping records.  `,
  InfoReasonTxt: `We may keep your personal information for longer than 7 years if we archive it for historical purposes or if we can’t delete it for legal, regulatory or technical reasons. For example, we have to hold pension transfer information indefinitely.`,
  CustomerSubTxt: `We’ll only use your personal information for those purposes and will make sure that your privacy is protected.`,
  NotToGiveInfo: `6. IF YOU CHOOSE NOT TO GIVE PERSONAL INFORMATION`,
  ChooseInfoTxt: `You can choose not to give us personal information. In this section we explain the effects this may have.`,
  ChooseInfoSubTxt: `We may need to collect personal information by law, or to enter into or fulfil a contract we have with you.`,
  ChooseInfoDelayTxt: `If you choose not to give us this personal information, it may delay or prevent us from fulfilling our contract with you, or doing what we must do by law. It may also mean that we can’t run your products or services. It could mean we cancel a product or service you have with us.`,
  ChooseInfoContractTxt: `We sometimes ask for personal information that is useful, but not needed by law or a contract. We’ll make this clear when we ask for it. You don’t have to give us these extra details, and it won't affect the products or services you have with us.`,
  CookiesTxt: `7. COOKIES AND SIMILAR TRACKING TECHNOLOGIES`,
  HowToUseAppEmailTxt: `This section explains how we use data on our websites, apps and emails.`,
  HowToUseAppEmailSubTxt: `We may use cookies and similar tracking technologies on our websites and apps, and in emails we send you.`,
  Cookies: ` COOKIES`,
  CookiesParagraph: `Cookies are small computer files that get sent to your computer, tablet or mobile device by websites when you visit them. They stay on your device, so that when you visit the website again, it knows that you have been there before and can give you a better experience. Cookies store information about your visits to that website, such as your choices, the type of device you use to connect to it, where you were when you connected, and the pages you visited on that site. Even where this information does not have personal details about you or your business, it is still protected by this Privacy Notice. `,
  EmailTracking: `EMAIL AND SMS TRACKING`,
  EmailTrackingSubTxt: `We review how you interact with emails we send you by using small images that you cannot see called ‘pixels’ within our emails. These pixels allow us to capture some information about emails we’ve sent you, such as: `,
  EmailTrackingList: `When you opened the email,//
How many times you opened it, and//
The device you used to open it.`,
  EmailTrackingDef: `This is called ‘email tracking’. We use this information to help us understand how effective our emails are, and to improve our communications with you. `,
  SMSTrack: `We also place trackers on SMS messages we send that capture similar information: whether you’ve received the message, and whether you’ve opened it. We use this information for the same reasons as above.`,
  CookiePolicy: `To find out more about how we use cookies and email tracking, please see our <a href="/public/cookie-policy" target="_blank">Cookie Policy.</a> `,
  PrivRelayPersonalHeading: `8. WHO WE SHARE YOUR PERSONAL INFORMATION WITH`,
  PrivCreditCardTxt: `We may share your personal information with other companies in the Lloyds Banking Group. We may also share it with outside organisations such as credit card providers, insurers or tax authorities. This is so that we can provide you with products and services, run our business, and obey rules that apply to us. Here we list all the types of organisations that we may share your personal information with. `,
  PrivLloydsBankingGroup: 'LLOYDS BANKING GROUP',
  PrivLBGShare:
    'We may share your personal information with other companies in the Lloyds Banking Group. These companies may use different brand names. For details of our brands, please see the ‘Who we are’ section.',
  PrivLBGInfo: `We share the information to make sure our records are accurate. For example, if you hold products with different brands in the Group, and you update your information with one of them, we may share this information with the other brands you hold products with. `,
  PrivLBGProducts: `We also share the information to help us provide you with products and services. For example, we could use information we learn about you from your transaction history with one of our brands to support an insurance product application with another brand. `,
  PrivThirdParty: `THIRD PARTIES WHO HELP US PROVIDE PRODUCTS OR SERVICES`,
  PrivThirdPartySub: `We work with third parties outside of the Lloyds Banking Group who provide products or services under our brand names. If you apply for one of these products, we may share information about you with the third party. If we want to share information in this way, we, or the third party will tell you before we do so.  `,
  PrivAuth: `AUTHORITIES`,
  PrivAuthSub: `This means official bodies that include:`,
  PrivAuthList: `Central and local government//
HM Revenue & Customs, regulators and other tax authorities//
UK Financial Services Compensation Scheme and other deposit guarantee schemes//
Law enforcement and fraud prevention agencies.`,
  PrivBanking: `BANKING AND FINANCIAL SERVICES`,
  PrivBankingSub: `Outside companies we work with to provide services to you and to run our business.`,
  PrivBankingList: `Credit reference agencies such as TransUnion, Equifax and Experian//
Someone linked with you or your business’s product or service//
This could mean a joint account holder, trustee, fellow company director or partner.//
Other financial services companies. This can be to fulfil a payment or other service as part of a contract with you; or to help prevent, detect and prosecute unlawful acts and fraudulent behaviour//
Independent Financial Advisers - this could be someone who you, your employer, or product owner have chosen to advise you on things like pensions or life assurance.//
Agents who collect debt that is owed//
Price comparison websites and similar companies - we only do so if you buy something from us through such a company. We only share details needed to confirm what you have bought, to fulfil our own contract with the company.//
Employers. For example, to confirm your identity if we ask for a mortgage reference//
Companies you ask us to share your data with - `,
  PrivOpenLink: `This is to do with something called <a href="https://www.lloydsbank.com/help-guidance/everyday-banking/profile-and-settings/open-banking.html" target="_blank">Open Banking</a>, which gives you more freedom and control to use your own banking data. It can make it easier for you or your business to shop around for products like credit cards, savings and current accounts.`,
  PrivBankingListC: `Companies whose shares we hold for you in one of our nominee companies - this is usually to do with Share Dealing accounts. This is where we hold shares for you in the name of our nominee company, and that company or their agent asks us to share details of the beneficial owner of the shares with them.//`,
  InsurersHead: `INSURERS`,
  InsurersSub: `We share personal information with insurance industry companies to process claims and help reduce fraud. We do that in these ways:`,
  InsurersList: `If you apply for insurance through us, we may pass your personal or business details to the insurer. //
If you apply for insurance with us as the insurer, we may share your personal or business details with reinsurers.//
If you make an insurance claim, information you give to us or the insurer may be put on a register of claims. This will be shared with other insurers, our agents, suppliers and sub-contractors. In some cases, we may share it with our business partners if you also have a relationship with them.`,
  PrivOtherService: `OTHER SERVICES AND SCHEMES`,
  PrivOtherServiceSub: `These are organisations that we may need to share your personal information with, because of what you can do with the product or service you have with us.`,
  PrivOtherServiceList: `If you have a debit, credit or charge card with us, we will share transaction details with companies that help us to provide this service (such as Visa and Mastercard). We need to do this to keep your account balance and statements up to date, for example.//
If you use Direct Debits, we’ll share your data with the Direct Debit scheme.//
If you have a product that has a loyalty scheme, we will share your data with that scheme.// 
If you have a product with benefits such as travel insurance or discount offers, we will share your data with the benefit providers. We may also share it with other companies involved in how you use the service, such as a private car park operator.//
If you have a secured loan or mortgage with us, we may share information with other lenders who also hold a charge on the property, such as the other party in a shared ownership scheme.//
If you or your employer have a product with us that includes extra services or features, such as daily rental vehicles, we’ll share your data with the providers of those services or features.//  
If a third party contacts us about fines, penalties or charges imposed on you, we may share this with your employer, or share your details with these third parties. //
If we carry out driving licence, vehicle or any other checks as part of our products, services or features, we may share information with suppliers and other third-parties needed for us to provide those to you, or your employer.  `,
  PrivBusiness: `GENERAL BUSINESS`,
  PrivBusinessSub: `Outside companies we use to provide our services, help grow and improve our business.`,
  PrivBusinessList: `Agents, suppliers, sub-contractors and advisers - these are types of firms that we use to help us run accounts, policies and services.//
Companies we have a contractual arrangement, joint venture or agreement with - this could be a store, a manufacturer or a broker who is offering finance deals or other products, services or features through us. //
Organisations that introduce you to us - this might be a store or car dealership that offers finance deals through us, or a price comparison website.//
Market researchers - these firms may get in touch with you on our behalf to ask you for your opinions and feedback. Sometimes these firms will combine what you tell them with data from other sources to study it. They’ll use this to help us understand our customers’ point of view, so we can improve the way we work as a business.//
Advisers who help us to come up with new ways of doing business - this might be a legal firm, IT supplier or consultancy.//
Advertisers and technology providers that you use, such as websites you visit, social media, and providers of apps and smart devices.
`,
  PrivBusinessThis: `This is so that:`,
  PrivBusinessSo: `You are only shown marketing we think you might be interested in.//
You are not shown marketing we think you won’t be interested in.//
We can try to find new customers who are like you, or have similar interests to yours.`,
  PrivBusinessLearn: `Learn more about 'how we use your information to make automated decisions'.`,
  PrivBusinessOutside: `Outside companies you may use.`,
  PrivBusinessInclude: `This could include:`,
  PrivIncludeList: `Price comparison websites and similar companies - we only do so if you buy something from us through such a company. We only share details needed to confirm what you have bought, to fulfil our own contract with the company.//
Third-party payment providers. For example, if you use an app on your phone to pay for something, then we may share payment and transaction data with them. This includes the amount, type, location, origin and recipients.`,
  MergerHead: `COMPANY MERGERS, TAKEOVERS AND TRANSFERS OR PRODUCTS OR SERVICES`,
  MergerSub: `We may also share your personal information if the ownership of products or services or the make-up of Lloyds Banking Group changes in the future:`,
  MergerList: `We may choose to sell, transfer, or merge parts of our business, or our assets, including products or services. Or we may try to bring other businesses into Lloyds Banking Group. This is sometimes called Mergers and Acquisitions or ‘company takeovers’.//
During any such process, we may share your data with other parties involved. We’ll only do this if they agree to keep your data safe and private. //
If the change to our Group happens, then other parties may use your data in the same way as set out in this notice.`,
  SharingInfoHead: `SHARING INFORMATION THAT DOESN'T REVEAL YOUR IDENTITY`,
  SharingInfo: `We may share or sell some information to other companies outside Lloyds Banking Group, but only when we have removed any personal details, so that no-one’s identity can be known or found out. `,
  SharingInfoTxt: `We and those other companies do this to help them learn about the types of customers they have, how customers use their products, and how their products perform. It also helps us to learn about the markets we each operate in and how our competitors perform. The law says data is not considered to be personal information after customer identities have been removed.`,
  SharingInfoSubTxt: `One of the ways we can share information without revealing customers' identities is by grouping customers' information together. Companies can use this grouped customer information to look for general patterns and trends in the information, without knowing individual customer identities. For example, we might provide them with information about:`,
  SharingInfoList: `types of customers in a specific location shopping for clothes//
customers that fall into a particular age group, or live in a particular area//
patterns of spending habits, such as the average amount spent in one month`,
  SharingInfoEnd: `To create this information, we analyse all of the details we hold about you and other customers. This includes information about your current and past products and services you have with us.`,
  PrivWorkOutMarktingHeading: `9. HOW WE WORK OUT WHAT MARKETING YOU RECEIVE`,
  PrivWorkOut: `This section tells you how we decide what marketing to show or send you. It also explains how we work out what you may be interested in.//
  We may use your personal information to make decisions about what products, services and offers we think you may be interested in. This is what we mean when we talk about ‘marketing’. We may send you carefully selected messages on products and offers from ourselves, other companies within Lloyds Banking Group and selected third parties.`,
  MarketingInfoHead: `WHEN WE CAN USE YOUR PERSONAL INFORMATION FOR MARKETING`,
  MarketingInfo: `We can only use your personal information to send you marketing messages if we have either your consent or a ‘legitimate interest’. That is when we have a business or commercial reason to use your personal information. It must not conflict unfairly with your own interests.`,
  MarketingDecide: `HOW WE DECIDE WHAT MARKETING MAY INTEREST YOU `,
  MarketingDecideTxt: `The personal information we have for you is made up of what you tell us, and data we collect when you use our services, or from outside organisations we work with. We study this to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you.//
We use the information to understand what kind of things you like, special events in your life, and how you react to different kinds of marketing material.//
This is called profiling for marketing purposes. It helps us to decide what products, services and offers we can promote to you. We may also conduct profiling for other purposes – please see 'Tailoring products and services' under 'How we use your information to make automated decisions' for further information.//
You can contact us at any time and ask us to stop using your personal information this way. Please see the ‘Your data privacy rights’ section for more information, and the ‘How to contact us’ section to contact us about this.`,
  SendMarketing: `HOW WE SEND YOU MARKETING`,
  SendMarketingTxt: `We may show or send you marketing material online (on our own and other websites including social media), in our own and other apps, or by email, mobile phone, post or through smart devices and other digital channels. `,
  MarketChoice: `YOUR MARKETING CHOICES`,
  MarketChoiceTxt: `You can tell us to stop sending you marketing at any time. Whatever you choose, we’ll still send you statements and other important information such as changes to your existing products and services. //
You can also tell us not to collect your personal information for marketing purposes while you are using our websites or mobile apps. If you do, you may still see some marketing, but it may not be tailored to you. See our Cookie Policy for details about how we use this information to improve our websites and mobile apps.//
We may ask you to confirm or update your choices, if you take out any new products or services with us in the future. We’ll also ask you to do this if there are changes in the law, regulation or the structure of our business. //
If you change your mind, you can contact us to update your choices at any time. Please see the ‘Your data privacy rights’ section for more information, and the ‘How to contact us’ section to contact us about this.`,

  PrivAutomatedInfo: `10. HOW WE USE YOUR INFORMATION TO MAKE AUTOMATED DECISIONS`,
  PrivAutomatedInfoSubTxt: `Here we tell you how we use automated systems to make decisions about you and your money. We also explain your right to challenge decisions made this way.//
  We sometimes use systems to make automated decisions about you or your business. This helps us to make sure our decisions are quick, fair, efficient and correct, based on what we know. Automated decisions can affect the products, services or features we may offer you now or in the future, or the price we charge for them. They are based on personal information that we have or that we are allowed to collect from others. //
Some of our systems learn from this information to make sure our automated decisions stay accurate and fair. This is sometimes known as ‘artificial intelligence’ or ‘machine learning’. //  
Here are the types of automated decisions we make://
PRICING//
We may decide what to charge for some products and services based on what we know. For example, if you use our online mortgage calculator, it will use the personal financial details you put in to estimate the kind of mortgage we may offer you. If you apply for insurance, we’ll compare what you tell us with other records to work out how likely you are to make a claim. This will help us to decide whether to offer you the product and what price to charge you.//
TAILORING PRODUCTS AND SERVICES//
The personal information we have for you is made up of what you tell us, and information we collect when you use our services, or from outside organisations we work with. We study this to form a view on what we think you may do, want, or need. This is how we decide which products, services and offers may be relevant for you. //
PROFILING FOR OTHER NON-MARKETING PURPOSES//
Sometimes we use your personal information to create a profile about you, for purposes other than marketing or making automated decisions about you. This means we build up a picture over time of your tastes, and things we think you may need, want or do based on how you interact with us. This may be changed into a ‘score’ or number we add to your record with us.//
We use this information to place you in groups with similar customers. These are called customer segments. We use these to study and learn about our customers’ needs. It helps us to design different products, services and offers for different customer segments, and to manage our relationships with them. It also helps us tailor what individuals see on our own and other websites and mobile apps, including social media. //
We also use customer segments from outside companies we work with, such as social media. We ask them for groups of people who are like you, or have interests that are like yours in some ways. These companies help us look for new customers who may be interested in products, services or offers that our existing customers are interested in. //
You can ask us to stop using your information in this way. Please see the ‘Your data privacy rights’ section, and the ‘How to contact us’ section for details about how to do this.//
DETECTING FRAUD//
We use your personal information to help decide if your personal or business accounts may be being used for fraud or money-laundering. We may detect that an account is being used in ways that fraudsters work. Or we may notice that an account is being used in a way that is unusual for you or your business. If we think there is a risk of fraud, we may stop activity on the accounts or refuse access to them.//
OPENING ACCOUNTS//
When you open an account with us, we check that the product or service is relevant for you, based on what we know. We also check that you or your business meet the conditions needed to open the account. This may include checking age, residency, nationality or financial position. It may mean we can’t offer you the account you want.//
APPROVING CREDIT//
We use a system to decide whether to lend money to you or your business, when you apply for credit such as a loan or credit card. This is called credit scoring. It uses past data to assess how you’re likely to act while paying back any money you borrow. This includes data about similar accounts you may have had before. //
Credit scoring uses data from three sources://`,
  PrivAutomatedSubTxt: `Your application form//
  Credit reference agencies//
  Data we may already hold.//
  //
  It gives an overall assessment based on this. Banks and other lenders use this to help us make responsible lending decisions that are fair and informed. `,
  PrivUnbiasedTxt: `Credit scoring methods are tested regularly to make sure they are fair and unbiased.`,
  PrivUnbiasedSubTxt: `This credit score can determine whether we’ll give you credit or allow you to take another product or service. `,
  YourRights: `YOUR RIGHTS`,
  PrivReviewTxt: `You can object to an automated decision we have made, and ask that a person reviews it.`,
  PrivPleaseContactUs: `If you want to know more about these rights, please see the ‘Your data privacy rights’ section for more information, and the ‘How to contact us’ section to contact us about this.`,
  privCRAHeading: '11. CREDIT REFERENCE AGENCIES (CRAs)',
  privSection:
    'This section explains how we work with outside companies to decide whether to lend you money through products like credit cards or loans. It also explains how we manage our products during your relationship with us. It explains what we do and why we do it.',
  privCreditAndIdentity:
    'We carry out credit and identity checks when you apply for a product or services for you or your business. We may use Credit Reference Agencies to help us with this.  ',
  privSearchInfo:
    'We’ll go on sharing your personal information with CRAs during your relationship with us. From time to time, we may also search information that the CRAs have to help us manage your accounts. ',
  privExchangeTitle:
    'We’ll share your personal information with CRAs and they’ll give us information about you. The data we exchange can include:',
  privExchangeInfoList: `Name, address and date of birth//
  Credit application//
  Details of any shared credit//
  Financial situation and history//
  Fraud prevention information  `,
  privUsedataHeading: `We'll use this data to:`,
  privUseDataList: `Assess whether you or your business is able to afford to make repayments//
  Make sure what you've told us is true, correct and up to date//
  Help detect and prevent financial crime//
  Manage your accounts with us//
  Trace and recover debts//
  Make sure any offers are relevant for you.`,
  PrivMoneyIn: `This will include details of money going into the account, and the account balance. If you borrow, it will also include details of your repayments, and whether you repay in full and on time. We’ll also tell the CRAs when you settle your accounts with us. The CRAs may give this information to other organisations that want to check your credit status.`,
  privCRABussiness:
    'When we ask CRAs about you or your business, they’ll note it on your credit file. This is called a credit search. Other lenders may see this and we may see credit searches from other lenders.',
  privApplyForSomeOne:
    'If you apply for a product with someone else, we’ll link your records with theirs. We’ll do the same if you tell us you have a spouse, partner or civil partner – or that you are in business with other partners or directors. ',
  privRecordsLinked:
    'You should tell them about this before you apply for a product or service. It’s important that they know your records will be linked together, and that credit searches may be made on them.',
  privCRArecords:
    'CRAs will also link your records together. These links will stay on your files unless one of you asks the CRAs to break the link. You’ll normally need to give proof that you no longer have a financial link with each other. ',
  privMoreOnCRA:
    'You can find out more about the CRAs on their websites, in the Credit Reference Agency Information Notice (CRAIN). This includes details about:',
  privWhoAreTheyList: `Who they are//
  Their role as fraud prevention agencies//
  The data they hold and how they use it//
  How they share personal information//
  How long they can keep data//
  Your data protection rights.`,
  privLinksToInfo:
    'Here are links to the information notice for each of the three main Credit Reference Agencies:',
  privTransUnionLink: 'TransUnion',
  privEquifax: 'Equifax',
  privExperian: 'Experian',
  PrivCRAEnd: `We use these agencies mainly with UK, Isle of Man or EEA products and customers. For non-UK, Isle of Man or EEA products and customers, we may not use these agencies. We may instead use other regional CRAs. Please see the ‘How to contact us’ section if you’d like to speak to us about this.`,
  privFPAHeading: '12. FRAUD PREVENTION AGENCIES (FPAs)',
  PrivFPASub: `This section deals with information we share outside our group to help fight financial crime. This includes crimes such as fraud, money-laundering and terrorist financing.`,
  privConfirmIdentity:
    'We may need to confirm your identity before we provide products or services to you or your business. This may include carrying out fraud checks at the point of sale.',
  privBecomeCustomer:
    'Once you have become a customer of ours, we’ll share your personal information as needed to help combat fraud and other financial crime. The organisations we share data with are:',
  agencyAndBody:
    'Registered Fraud Prevention Agencies//Other agencies and bodies acting for the same purpose//Industry databases used for this purpose//Insurers',
  preventDetect:
    'Throughout our relationship with you, we and these organisations exchange data to help prevent, deter, detect and investigate fraud and money-laundering.',
  obeyingTheLaw: `We and these organisations can’t use your personal information unless we have a proper reason to do so. It must be needed either for us to obey the law, or for a ‘legitimate interest’.`,
  legitimateIntrestTitle: `When we have a business or commercial reason of our own to use your personal information, this is called a ‘legitimate interest’. We’ll tell you what that is, if we are going to rely on it as the reason for using your personal information. Even then, it must not unfairly go against your interests. `,
  useInformationTitle: 'We will use the information to:',
  useInfoContent: `Confirm identities//
  Help prevent fraud and / or money-laundering//
  Fulfil any contracts you or your business has with us.//
  We or an FPA may allow law enforcement agencies to access your personal information. This is to support their duty to prevent, detect, investigate and prosecute crime.`,
  otherOrgTitle:
    'These other organisations can keep personal information for different lengths of time, up to six years.',
  personalInfoHeading: 'THE INFORMATION WE USE',
  PersonalInfoTitle:
    'These are some of the kinds of personal information that we use:',
  KindsOfPersonalInfo: `Name//
Date of birth//
Residential address//
History of where you have lived//
Contact details, such as email addresses and phone numbers//
Financial data//
Whether you have been a victim of fraud//
Data about insurance claims you have made//
Data about your or your business’s products or services//
Employment details//
Vehicle details//
Data that identifies the computers or other devices you use to connect to the internet. This includes your Internet Protocol (IP) address.`,
  AutomatedDecHead: `AUTOMATED DECISIONS FOR FRAUD PREVENTION`,
  ThirdPartyContent: `The information we have for you or your business is made up of what you tell us, and data we collect when you use our services, or from third parties we work with. `,
  PreventFraudContent: `We and other organisations acting to prevent fraud may process your personal information in systems that look for fraud by studying patterns in the data. We may find that an account or policy is being used in ways that fraudsters work. Or we may notice that an account is being used in a way that is unusual for you or your business. Either of these could indicate a risk of fraud or money-laundering against a customer, the bank or the insurer.`,
  AffectTitle: `HOW THIS CAN AFFECT YOU`,
  DataFPA: `If we or an FPA decide there is a risk of fraud, we may stop activity on the accounts or block access to them. FPAs and cross-industry organisations may also keep a record of the risks that you or your business may pose. `,
  DataFPAResult: `This may result in other organisations refusing to provide you with products or services, or to employ you. `,
  DataTransferUKaandEEA: `DATA TRANSFERS OUT OF THE UK, ISLE OF MAN, CHANNEL ISLANDS AND EEA`,
  FPADescription: `FPAs and other organisations we share data with for these purposes may send personal information to countries outside the UK, Isle of Man, Channel Islands and European Economic Area (‘EEA’). This may include countries where the UK Government has decided that your data will be protected to UK standards. If the data is sent to another type of country, the FPAs or other organisations will make sure your data stays protected to the same standard as the EEA by making sure appropriate safeguards are in place. This may include following international frameworks for making data sharing secure.`,
  FPATitle: `The main Fraud Prevention Agency we use, CIFAS, has published more information about data transfers: <a href="https://www.cifas.org.uk/fpn" target="_blank">CIFAS</a>`,
  DataOutsideUkandEEA: `13. SENDING DATA OUTSIDE OF THE UK, ISLE OF MAN, CHANNEL ISLANDS AND EEA`,
  DataOutsideUkandEEAContent: `This section tells you about the safeguards that keep your personal information safe and private, if it is sent outside the UK, Isle of Man, Channel Islands and EEA.`,
  DataOutside: `We’ll only send your data outside of the UK, Isle of Man, Channel Islands and European Economic Area (‘EEA’) to:`,
  DataOfEEA: `Follow your instructions. For example, if you book a hotel in Australia with your Lloyds Banking Group card, we’ll send the details necessary to fulfil that payment to the hotel’s bank.//
Comply with a legal duty. For example, we share information about US citizens with the US tax authority.//
Work with our suppliers who help us to run your accounts and services.`,
  DoTransfer: `If we do transfer your personal information outside the UK, Isle of Man, Channel Islands and EEA to our suppliers, we’ll make sure it’s protected to the same extent as in the UK, Isle of Man, Channel Islands and EEA. We’ll use one of these safeguards:`,
  Transfer: `Transfer it to a non-EEA country with privacy laws that give the same protection as the UK, Isle of Man, Channel Islands and EEA. Learn more on the <a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/rules-international-data-transfers_en" target="_blank">European Commission Justice website.</a>`,
  TransferContent: `Put in place a contract with the recipient that means they must protect it to the same standards as the UK, Isle of Man, Channel Islands and EEA. Read more about this on the <a href="https://digital-strategy.ec.europa.eu/en/policies/digital-privacy" target="_blank">European Commission Justice website.</a>`,
  DataPrivacy: `14. YOUR DATA PRIVACY RIGHTS`,
  DataPrivacySub: `This section explains your data privacy rights and how to contact us about them.`,
  DataPrivacyContent: `You have several rights around use of your personal information. Following is a list of these rights, including a description and, if applicable, how to contact us about them. These rights do not apply in all scenarios.//
THE RIGHT TO BE INFORMED//
You have the right to be informed about the collection and use of your personal information. This means that we should provide you with details of how we use your personal information. This Data Privacy Notice is an example of this. //
THE RIGHT OF ACCESS//
You have the right to access a copy of your personal information, referred to as a Subject Access Request (SAR). Please see the ‘How to get a copy of your personal information’ instructions in the ‘How to contact us’ section.//
THE RIGHT OF RECTIFICATION//
You have the right to question any information we have about you that you think is incorrect. We’ll take reasonable steps to check this for you and correct it. Please visit one of our branches, or see the ‘How to contact us’ section if you want to do this.//
THE RIGHT TO ERASURE//
You have the right to delete or remove your personal information. This is also known as ‘the right to be forgotten’. We don’t always have to agree to this, for example if there are legal or other reasons why we need to keep or use the information. If this is the case, we’ll explain our reasons to you. Please see the ‘How to contact us’ section if you want to do this.//
THE RIGHT TO RESTRICT PROCESSING//
You have the right to restrict the processing of your personal information. This means that you can ask us to limit what we use your information for, for a period of time. You can do this if you think that your personal information is not accurate, has been used unlawfully, is not relevant to us anymore, or if you have already asked us to stop using your personal information but you are waiting for us to tell you if we are allowed to keep on using it. If we do restrict your personal information in this way, we won’t use or share it in other ways while it is restricted. This means that we may not be able to provide you some of your products or services while the restriction is in place. Please see the ‘How to contact us’ section to do this. //
THE RIGHT TO DATA PORTABILITY//
You have the right to get certain personal information from us as a digital file. This means you can keep and use it yourself and give it to other organisations if you choose to. If you want, we’ll give it to you in an electronic format that can be easily re-used, or you can ask us to pass it on to other organisations for you. Please see the ‘How to contact us’ section to do this.//
THE RIGHT TO OBJECT//
You have the right to object to us using your personal information for marketing purposes, and we must act on this. You can also object to any use of your information where we have given ‘legitimate interest’ as our reason for using it. You must tell us the reason for the objection and how it affects you as an individual. We can refuse your objection if we can show that there are legal or other official reasons why we need to keep or use the information. If this is the case, we’ll explain our reasons to you. Please see the ‘How to contact us’ section if you want to object to us using your information.//
RIGHTS IN RELATION TO AUTOMATED DECISION MAKING AND PROFILING//
You have rights around automated decision making and profiling. Automated decision making means a decision made solely by automated means, without any human involvement. Profiling means the automated processing of your personal information to evaluate certain things about you. You have the right to information about these kinds of processing, and the right to challenge and ask for a human to review an automated decision. You can do this when an automated decision is made about you, or you can see the ‘How to contact us’ section to speak to us about this. Please see the ‘How we work out what marketing you receive’ section for more information on profiling and see the ‘How we use your information to make automated decisions’ section for more information on automated decision making.//
THE RIGHT TO WITHDRAW CONSENT//
Where consent is our reason for using your personal information you have the right to withdraw that consent at any time. If you withdraw your consent, we may not be able to provide certain products or services to you. If this is the case, we’ll tell you. You can manage your choices for activities like marketing through your internet or mobile banking applications, by visiting one of our branches, or see the ‘How to contact us’ section to speak to us about this.`,
  ContactUs: `15. HOW TO CONTACT US`,
  ContactUsSub: `This section gives details of how to contact us about how we use your personal information. It also shows you where you can get in touch with the government regulator.`,
  QuestionTxt: `If you have a question about how we use your personal information, or would like to speak to us about a Data Privacy Individual Rights Request you can call us on <a href="tel:03448248888">03448 248 888</a> (+44 344 824 8888 from outside the UK). We may monitor or record your calls. Lines are open Monday to Friday 8:30am - 6:00pm and Saturday: 9:00am - 1:00pm.`,
  RelayTxt: `If you have a hearing or speech impairment, you can also call us using Relay UK. If you use a textphone, just add 18001 before the number you're calling. On a smartphone, tablet or computer, you can call through the Relay UK app. There's more information on the <a href="https://www.relayuk.bt.com/" target="_blank" >Relay UK help pages.</a>`,
  BSLTxt: `If you’re Deaf and a BSL user, you can talk to us live through a BSL interpreter online with our <a href="https://www.lloydsbank.com/help-guidance/accessibility/sign-video.html" target="_blank">BSLSignVideo service.</a> `,
  DSARTxt: `If you would like to speak to us about a Data Subject Access Request (DSAR)`,
  DSARSub: `To get a copy of all the personal information we hold about you, write to us at this address: `,
  DSARAddress: `DSAR Unit//
Black Horse//
25 Gresham Street//
London//
EC2V 7HN`,
  GDPOTxt: `//If you would like to contact our Group Data Protection Officer//
  You can contact our Group Data Protection Officer by writing to the following address: `,
  GDPOAddress: `LBG Data Protection Officer//
Lloyds Banking Group//
PO BOX 548//
Leeds//
LS1 1WU`,
  UnhappyHeading: `IF YOU ARE UNHAPPY WITH HOW WE HAVE USED YOUR PERSONAL INFORMATION`,
  UnhappyTxt: `If you are unhappy with how we have used your personal information, you can <a href="https://www.blackhorse.co.uk/help/complaints.html" >make a complaint.</a>`,
  ComplaintHeading: `IF YOU ARE NOT HAPPY WITH THE OUTCOME OF A COMPLAINT`,
  ComplaintTxt: `You also have the right to complain to the regulator, and to lodge an appeal if you’re not happy with the outcome of a complaint. `,
  UKTxt: `In the UK this is the Information Commissioner’s Office. Find out on their website how to <a href="https://ico.org.uk/make-a-complaint/" target="_blank">report a concern.</a>`,
  JerseyTxt: `In Jersey, please contact the <a href="https://jerseyoic.org/make-a-complaint/" target="_blank">Office of the Information Commissioner.</a> `,
  GuernseyTxt: `In Guernsey, please contact the <a href="https://www.odpa.gg/for-individuals/make-a-complaint/" target="_blank">Office of the Data Protection Commissioner.</a>`,
  IOMTxt: `In Isle of Man, please contact the <a href="https://www.inforights.im/complaint-handling/how-to-make-a-complaint-to-the-information-commissioner/" target="_blank">Information Commissioner.</a>`,
  goBackLink: 'Go back',
};

export default privacyPolicy;
