export interface DataModalInterface {
  type: string;
  openDialog: boolean;
}

export const DataModelAction = (openDialog: boolean): DataModalInterface => ({
  type: 'OPEN_DATAMODAL',
  openDialog,
});

export interface MarketingDataModalInterface {
  type: string;
  openMarketingDataModal: boolean;
}

export const MarketingDataModelAction = (
  openMarketingDataModal: boolean,
): MarketingDataModalInterface => ({
  type: 'MARKETING_DATAMODAL',
  openMarketingDataModal,
});
