import { AppContent } from '../AppContent';

const termsAndConditions: AppContent['termsAndConditions'] = {
  TermsHeading: 'Terms and Conditions',
  TermsWebsiteHeading:
    'THIS WEBSITE IS AN OFFICIAL WEBSITE OF BLACK HORSE LIMITED ("WE" OR "US").',
  TermsShouldRead:
    'YOU SHOULD READ THESE TERMS AND CONDITIONS ("TERMS") CAREFULLY BEFORE USING THIS WEBSITE. USING THE WEBSITE INDICATES THAT YOU ACCEPT THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS, DO NOT USE THE WEBSITE.',
  TremsChangedContent:
    'Please note these Terms changed on 26 April 2014. We may revise these Terms at any time and you should periodically check the website to review the then current Terms because they are binding on you. Certain provisions of these Terms may be superseded by expressly designated legal notices or terms located on particular pages of the website.',
  TradingDisclosure: 'TRADING DISCLOSURE',
  TradingDisclosureTxt:
    'Black Horse provides finance for thousands of customers every year to buy their vehicle from a network of dealers throughout the UK.',
  TradingDisclosureSubTxt:
    'Black Horse Motor Finance, Black Horse Motorcycle Finance and Black Horse Caravan and Motorhome Finance are trading styles of Black Horse Limited',
  BHHeading: 'BLACK HORSE LIMITED IS:',
  BHRegistered:
    'Registered as a company in England and Wales with number 661204 and registered office at 25 Gresham Street, London EC2V 7HN.',
  BHConduct:
    'Authorised and regulated by the Financial Conduct Authority registration number 313409.',
  BHvat: 'VAT number 244 1555 76',
  BHLendingCode:
    'A member of the Finance & Leasing Association and complies with its Lending Code a copy of which we will provide on request or is available at <a href="https://www.fla.org.uk/home/" target="_blank">www.fla.org.uk</a>',
  ContactUsHeading: 'CONTACT US:',
  ContactUsTxt:
    'Please click <a href="https://www.blackhorse.co.uk/help/contact.html" target="_blank">here</a> for a full list of contact details.',
  TermsWhoCanUseHeading: 'WHO CAN USE THIS SITE',
  TermsWhoCanUseContent:
    'The products and services described on this website are ONLY available to you if you are resident in and access the site from within the UK (excluding Channel Islands and Isle of Man). Certain areas of the website are only open to existing customers.',
  TermsServiceHeading: 'SERVICE ACCESS',
  TermsServiceContent:
    'While the website is normally available 24 hours a day, certain services provided through the website may not be available 24 hours a day, and we shall not be liable if for any reason the website is unavailable at any time. Access to the website may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for reasons beyond our control.',
  TermsDisclaimerHeading: 'DISCLAIMER',
  TermsProductDisclaimerContent:
    'This website is here to give you information about our products and services. It is up to you to decide whether they are suitable for you. You may want to get advice about this from an independent financial advisor.',
  TermsCopyrightsHeading:
    'COPYRIGHTS, TRADE MARKS AND COPYING MATERIALS ON THIS SITE',
  TermsCopyrightsContent:
    'We own (or have full licence for) all copyrights for this website and all trade marks and other materials used on it. You may use anything on this website only for your personal information and for this purpose you may print, copy, download or temporarily store extracts from this website. You must not alter anything. Any other use is prohibited unless you first get our written permission.',
  TermsLiabilityHeading: 'EXCLUSIONS OF OUR LEGAL LIABILITY',
  TermsLiabilityUKContent:
    'The website only complies with UK laws. You should only use it from the UK. The products and services on it are only available to you if you are a UK resident. You may not always be able to access this website. We are not responsible for any losses if you cannot do so (for example if you cannot obtain a product at a stated price). We are not responsible if the means of communication you use to access the website or to send us information about yourself or to receive information from us does not work properly.',
  TermsLawAndJurisdictionHeading: 'LAW AND JURISDICTION',
  TermsLawAndJurisdictionContent:
    'This site and these Terms are governed by English law. Any disputes arising from this site shall be resolved by the English courts unless you can show you accessed the site from Scotland in which case the Scottish courts may be used and Scottish law may apply.',
  goBackLink: 'Go back',
};

export default termsAndConditions;
