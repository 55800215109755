export function setCookie(name, value, expiry) {
  let cookieString = `${name}=${value}`;
  const expires = new Date(expiry);
  cookieString += `; expires=${expires.toLocaleString()}`;
  document.cookie = cookieString;
}

export function getCookie(cookie_name) {
  const results = document.cookie.match(`(^|;) ?${cookie_name}=([^;]*)(;|$)`);
  return results ? results[2] : null;
}
