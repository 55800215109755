import { Text } from '@constellation/core';
import styled from 'styled-components';

export const StyledText = styled(Text)`
  display: flex;
  align-items: baseline;
  border-left: 2px solid ${({ theme }) => theme.color_text_subdued};
  color: ${({ theme }) => theme.color_text_subdued} !important;
  margin-left: ${({ theme }) => theme.spacing_02} !important;
  padding-left: ${({ theme }) => theme.spacing_02} !important;
  margin-bottom: ${({ theme }) => theme.spacing_02} !important;
`;
