import common from './common';
import { AppContent } from '../AppContent';

const loginAndRegistrationWss: AppContent['loginAndRegistrationWss'] = {
  createOnlineAccountText:
    'Creating an online account takes two minutes and is easy to do.',
  postCodeFieldLabel: 'What is your postcode?',
  dateFieldLabel: 'What is your date of birth?',
  PostcodeAdditionalInfo:
    'This should be the postcode on your agreement with us. If your postcode has changed, please read our ',
  forgotEmailOrPassLink: 'Forgot email address or password?',
  notRegisteredLabel: 'Not registered?',
  loginFindAccountError:
    'Sorry, we can’t find an account with that email address and password. Check the details you entered below and try again.',
  loginResetPasswordLink: 'Reset password',
  nextButtonLabel: 'Next',
  forgotUserIdHeadingText:
    'We can help you by telling you the email address for your account.',
  forgotUserIdHeadingSubText:
    'Answer the questions below. Then we’ll send you a text to confirm your identity.',
  fogotUserIdSubText: 'This is the postcode you have on your account with us.',
  goBackLink: 'Go back',
  loginHelpText: 'Log in help',
  forgotPasswordHeading: 'Forgot your password?',
  forgotPasswordHeadingText:
    'Don’t worry, changing your password is easy. Answer the questions below. Then we’ll send you a text to confirm your identity.',
  forgotPasswordSubText:
    'This is the postcode you have on your account with us.',
  chooseAnAccountLabel: 'Choose an account to view:',
  agreementNumberLabel: 'Agreement number:',
  vehicleRegistrationLabel: 'Vehicle registration:',
  agreementFieldLabel: 'What is your agreement number?',
  agreementSupportiveText: 'Where can I find my agreement number?',
  agreementFieldPlaceHolder: 'Agreement number here',
  detailsNotFoundSubText:
    'This is either because the incorrect details have been entered or you’ve tried to create your online account too soon. It may take up to 5 days to upload your details. If you need any help, please call us.',
  tryAgainLink: 'Try again',
  alreadyRegisteredLabel: 'Already registered',
  alreadyRegisteredDescription:
    'It looks like you’re already registered. Please log in with your email and password.',
  forgotUserIdDetailsNotFoundMessage:
    'We can’t find an online account with the details you’ve given. Try again or if you need any help, please call us.',
  createAccountFAQsText: 'Create an account FAQs.',
  dataRetrievalErrorHeading: 'Data Retrieval Error',
  dataRetrievalErrorDescription:
    'Sorry, we can’t fetch your data right now. Please check your connection. If this issue persists, please contact support.',
  emailTooltipContent:
    'Unless you’ve changed it on your online account, this is the email address you used when you created your online account with us.',
  loginFailedDataModalHeading: 'We can’t log you in',
  loginAttemptsExceededModalSubText:
    'You’ve used the wrong details too many times. Please reset your password.',
  loginNotRegisteredModalSubText:
    'In order to log in, we need to verify your details.',
  loginAttemptPreventedModalSubText:
    'Please contact us, so we can try to resolve this.',
  loginRegisterModalButtonText: 'Continue to registration',
  loginResetPasswordModalButtonText: 'Reset password',
  loginNotRegisteredApiErrorMessage: 'User is not Registered',
  loginAccountNotActiveErrorMessage: 'User Account is not Active',
  ...common,
};
export default loginAndRegistrationWss;
