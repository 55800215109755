import React from 'react';

import { AppConfig } from './AppConfig';
import { Brand } from '../brandProvider';

export default React.createContext<AppConfig>({
  BRAND_NAME: Brand.BLACK_HORSE,
  JOURNEY_NAME: '',
  JOURNEY_STEP_NAME: '',
  PAGE_ROLE: '',
});
