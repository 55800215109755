import { AppContent } from '../AppContent';

const footer: AppContent['layoutContent'] = {
  footerContent: `Black Horse Limited. Registered Office: 25 Gresham Street, London, EC2V 7HN. Registered in England and Wales, with registered number 661204. Black Horse is authorised and regulated by the Financial Conduct Authority.`,
  footerTermsText: 'Terms & Conditions',
  footerPrivacyText: 'Privacy',
  footerCookiePolicyText: 'Cookie Policy',
};

export default footer;
