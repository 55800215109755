import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { ContentGroup, GridItem, Text, TextField } from '@constellation/core';
import { useContent } from '@interstellar/react-app-content';
import { useNavigate } from '@interstellar/react-app-routing';

import {
  ForgotPasswordContent,
  VerifyEmailAddressProps,
} from './forgotPassword.config';
import { StyledInlineBox } from '../../components/appConfig/common.styled';
import LinkButton from '../../components/linkButton/LinkButton';
import { REGEX } from '../../constants/REGEX';
import dataQaIds from '../../dataModel/dataQaIds';
import { isEmptyString } from '../../utils/isEmptyString';
import * as routes from '../manifest';

export default function VerifyEmailAddress({
  handleEmailAddress,
  handleEmailError,
  isContinueClicked,
}: VerifyEmailAddressProps): ReactElement {
  const emailValue = useRef({} as HTMLInputElement);
  const [emailErrMsg, setEmailErrMsg] = useState('');
  const navigate = useNavigate();
  const emailPattern = new RegExp(REGEX.EMAIL_PATTERN);
  const handleOnEmailSetErrMsg = (value: string): void => setEmailErrMsg(value);
  const {
    emailErrorMessage,
    verifyEmailText,
    verifyEmailFieldLabel,
    forgottenEmailLink,
  } = useContent<ForgotPasswordContent>();
  useEffect(() => {
    if (isContinueClicked && isEmptyString(emailValue.current.value)) {
      setEmailErrMsg(emailErrorMessage);
      handleEmailError(true);
    }
  }, [emailErrorMessage, handleEmailError, isContinueClicked]);
  const handleEmailKeyDown = (event: any): boolean => {
    if (REGEX.SPECIAL_CHARACTERS_EMAIL.indexOf(event.key) !== -1) {
      event.preventDefault();
      return false;
    }
    handleOnEmailSetErrMsg('');
    return true;
  };
  const handleOnEmailBlurEvt = (): void => {
    if (
      !isEmptyString(emailValue.current.value) &&
      !emailPattern.test(emailValue.current.value)
    ) {
      handleOnEmailSetErrMsg(emailErrorMessage);
      handleEmailError(true);
    } else if (isEmptyString(emailValue.current.value)) {
      setEmailErrMsg(emailErrorMessage);
    } else {
      handleOnEmailSetErrMsg('');
      handleEmailError(false);
      handleEmailAddress(emailValue.current.value);
    }
  };
  return (
    <GridItem>
      <ContentGroup marginBottom="05">
        <Text size="s2">{verifyEmailText}</Text>
      </ContentGroup>
      <TextField
        name="email"
        label={verifyEmailFieldLabel}
        marginBottom="04"
        onKeyDown={handleEmailKeyDown}
        onBlur={handleOnEmailBlurEvt}
        inputRef={emailValue}
        error={emailErrMsg}
        data-testid="verifyEmail"
        data-qa-id={dataQaIds.loginAndRegistration.verifyEmailField}
        autoComplete="off"
      />
      <ContentGroup marginBottom="07">
        <StyledInlineBox>
          <LinkButton
            handleButtonClick={() => navigate(routes.ForgotEmail)}
            testId="forgotEmailLink"
          >
            {forgottenEmailLink}
          </LinkButton>
        </StyledInlineBox>
      </ContentGroup>
    </GridItem>
  );
}
