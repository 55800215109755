import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ForgotPasswordProps } from './forgotPassword.config';
import ForgotPasswordMTA from './forgotPasswordMTA';
import { RootState } from '../../store';
import { changeStepAction } from '../../store/action/formStep.action';
import { DOBPostcodeContractsDataAction } from '../../store/action/registration.action';
import { dataLayer } from '../../utils/dataLayer';
import { isEmptyString } from '../../utils/isEmptyString';
import ForgotPasswordWss from '../loginAndRegistrationWss/forgotPasswordWss';
import { ApiAuthResponse } from '../registration/Registration.config';

export default function ForgotPassword({
  brand,
  isAuthenticated,
  setIsAuthuenticated,
  authToken,
  setAuthToken,
  setForgotCredentialSelector,
}: ForgotPasswordProps) {
  const dispatch = useDispatch();
  const { stepNumber } = useSelector(
    (state: RootState) => state.FormStepsReducer,
  );
  const { isWss } = useSelector((state: RootState) => state.TogglerReducer);
  const { customerData } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  useEffect(() => {
    window.appConfig.JOURNEY_NAME = 'Login';
    window.appConfig.JOURNEY_STEP_NAME = 'Forgot Password';
    window.appConfig.PAGE_ROLE = 'Servicing';
    dataLayer();
  }, []);
  const [mtaCustomerId, setMtaCustomerId] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [isContactFound, setIsContactFound] = useState(
    !isEmptyString(customerData[0].phoneNumber),
  );
  const numberOfSteps = 2;

  const [authLiteUUID, setAuthLiteUUID] = useState('');
  const [isForgetPasswordWss, setIsForgetPasswordWss] = useState(false);
  const [isMultiAgreement, setIsMultiAgreement] = useState(false);

  const handleObsucredNumber = (phoneNumber: string): void => {
    setContactNumber(
      '*'.repeat(Math.max(0, phoneNumber.length - 4)) + phoneNumber.slice(-4),
    );
  };
  const isNoContactFound = (number: string): boolean => {
    if (isEmptyString(number)) {
      setIsContactFound(false);
      setIsAuthuenticated(true);
      return true;
    }
    setIsContactFound(true);
    setIsAuthuenticated(true);
    handleObsucredNumber(number);
    return false;
  };
  const handleOnStep = (isMultipleAgreement: boolean) => {
    if (!isMultipleAgreement) {
      dispatch(
        changeStepAction(
          stepNumber < numberOfSteps ? stepNumber + 1 : numberOfSteps,
        ),
      );
    }
  };
  const handleOnNoContactCondition = (
    number: string,
    isMultipleAgreement: boolean,
  ): void => {
    if (isNoContactFound(number || customerData[0].phoneNumber)) {
      setIsContactFound(false);
    } else {
      handleObsucredNumber(number);
      if (isForgetPasswordWss) handleOnStep(isMultipleAgreement);
    }
  };
  const handleAuthenticated = (value: boolean): void => {
    setIsAuthuenticated(value);
  };
  const handleOnModalConditions = (
    res: ApiAuthResponse[],
    { isForgotPassword },
  ): void => {
    dispatch(
      DOBPostcodeContractsDataAction(res, {
        isDetailsFound: true,
        isApiFailed: false,
        isMoreThanOneUser: false,
      }),
    );
    setIsForgetPasswordWss(isForgotPassword);
    handleOnNoContactCondition(res[0].phoneNumber, isMultiAgreement);
    setMtaCustomerId(res[0].mtaCustomerId);
    setAuthLiteUUID(res[0].authLiteUUID);
  };
  const handleMultiAgreement = (value: boolean): void =>
    setIsMultiAgreement(value);
  const hanldeIsContactFound = (value: boolean): void =>
    setIsContactFound(value);
  return isWss ? (
    <ForgotPasswordWss
      handleOnPassDetails={handleOnModalConditions}
      handleMultiAgreement={handleMultiAgreement}
      isMultiAgreement={isMultiAgreement}
    />
  ) : (
    <ForgotPasswordMTA
      brand={brand}
      contactNumber={contactNumber || customerData[0].phoneNumber}
      mtaCustomerId={mtaCustomerId || customerData[0].mtaCustomerId}
      isContactFound={isContactFound}
      numberOfSteps={numberOfSteps}
      isAuthenticated={isAuthenticated}
      authLiteUUID={authLiteUUID || customerData[0].authLiteUUID}
      handlePassDetails={handleOnModalConditions}
      handleAuthenticated={handleAuthenticated}
      isForgetPasswordWss={isForgetPasswordWss}
      hanldeIsContactFound={hanldeIsContactFound}
      handleMultiAgreement={handleMultiAgreement}
      isMultiAgreement={isMultiAgreement}
      authToken={authToken}
      setAuthToken={setAuthToken}
      setForgotCredentialSelector={setForgotCredentialSelector}
    />
  );
}
