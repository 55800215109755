import React, { ReactElement } from 'react';

import { Link, Text } from '@constellation/core';
import { ContentProvider, useContent } from '@interstellar/react-app-content';
import { useTheme } from 'styled-components';

import { PhoneLinkProps } from './PhoneLink.config';
import PhoneIcon from '../../assets/icons/phoneIcon';
import content from '../../content';
import { CommonContent } from '../../content/CommonContent';
import { DataQaId } from '../appConfig/AppConfig';

function PhoneLinkInner({ dataQaId }: DataQaId): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();

  const { phoneNumber } = useContent<CommonContent>();
  return (
    <>
      <PhoneIcon colour={theme.color_icon_default_2} />
      <Text marginLeft="04">
        <Link href={`tel:${phoneNumber}`} data-qa-id={dataQaId}>
          {phoneNumber}
        </Link>
      </Text>
    </>
  );
}

export default function PhoneLink({ dataQaId, brand }: PhoneLinkProps) {
  return (
    <ContentProvider value={content({ brand }).common}>
      <PhoneLinkInner dataQaId={dataQaId} />
    </ContentProvider>
  );
}
