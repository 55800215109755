import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ApiAuthResponse, RegistrationMainProps } from './Registration.config';
import RegistrationMTA from './RegistrationMTA';
import { RootState } from '../../store';
import { DataModelAction } from '../../store/action/dataModal.action';
import { changeStepAction } from '../../store/action/formStep.action';
import { DOBPostcodeContractsDataAction } from '../../store/action/registration.action';
import { toggleAction } from '../../store/action/toggler.action';
import { dataLayer } from '../../utils/dataLayer';
import { isEmptyString } from '../../utils/isEmptyString';
import { RegistrationWss } from '../loginAndRegistrationWss/registrationWss';

export default function Registration({
  brand,
  passwordValue,
  setPasswordValue,
  setCreateAccountFAQ,
}: RegistrationMainProps) {
  const dispatch = useDispatch();
  const { stepNumber } = useSelector(
    (state: RootState) => state.FormStepsReducer,
  );
  const { customerData } = useSelector(
    (state: RootState) => state.RegistrationReducer,
  );
  const [mtaCustomerId, setMtaCustomerId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isContactFound, setIsContactFound] = useState(
    !isEmptyString(customerData[0].phoneNumber),
  );
  const [isChangeEmail, setIsChangeEmail] = useState(false);
  const [isEmailFound, setIsEmailFound] = useState(false);
  const [isCustomerIdentificationError, setIsCustomerIdentificationError] =
    useState(false);
  const isRegistered = useRef(false);
  const numberOfSteps = 3;
  const [isRegistrationWss, setIsRegistrationWss] = useState(false);
  const { isWss } = useSelector((state: RootState) => state.TogglerReducer);
  const isNoContactFound = (number: string): boolean => {
    if (isEmptyString(number)) {
      setIsContactFound(false);
      return true;
    }
    setIsContactFound(true);
    return false;
  };
  const isNoEmailFound = (mail: string): boolean => {
    if (isEmptyString(mail)) {
      setIsEmailFound(false);
      setIsChangeEmail(true);
      return true;
    }
    setIsEmailFound(true);
    return false;
  };
  useEffect(() => {
    const getStepName = () => {
      switch (stepNumber) {
        case 1:
          return 'Personal details';
        case 2:
          return 'Credentials';
        default:
          return 'OTP';
      }
    };
    window.appConfig.JOURNEY_NAME = 'Registration';
    window.appConfig.JOURNEY_STEP_NAME = getStepName();
    window.appConfig.PAGE_ROLE = 'Servicing';
    dataLayer();
  }, [stepNumber]);
  const handleContactFound = (value: boolean): void => setIsContactFound(value);
  const handleOnStep = () => {
    if (!isRegistrationWss && !isWss) {
      dispatch(
        changeStepAction(
          stepNumber < numberOfSteps ? stepNumber + 1 : numberOfSteps,
        ),
      );
    }
  };
  const handleIsRegisteredMta = (value: boolean): void => {
    isRegistered.current = value;
  };

  const handleOnNoContactCondition = (
    number: string,
    emailAddress: string,
  ): void => {
    if (isNoContactFound(number || customerData[0].phoneNumber)) {
      setIsCustomerIdentificationError(true);
      setIsContactFound(false);
      dispatch(DataModelAction(true));
    } else if (isNoEmailFound(emailAddress || customerData[0].emailAddress)) {
      setIsEmailFound(false);
      setPhoneNumber(number);
      setIsContactFound(true);
      handleOnStep();
    } else {
      setIsContactFound(true);
      setIsEmailFound(true);
      setIsChangeEmail(false);
      setEmail(emailAddress);
      setPhoneNumber(number);
      handleOnStep();
    }
  };
  const handleEmailFound = (value: boolean): void => {
    setIsEmailFound(value);
  };
  const handleOnChangeEmail = (value: boolean): void => {
    setIsChangeEmail(value);
  };
  const handleIsContactFoundMta = (value: boolean): void => {
    setIsContactFound(value);
  };
  const handleCustomerIdentityError = (bool): void => {
    setIsCustomerIdentificationError(bool);
  };
  const handleOnModalConditions = (
    res: ApiAuthResponse[],
    { isRegisterWss },
  ): void => {
    setIsRegistrationWss(isRegisterWss);
    handleOnNoContactCondition(res[0].phoneNumber, res[0].emailAddress);
    setMtaCustomerId(res[0].mtaCustomerId);
  };
  const handleSingleMtaAgreement = (res: any) => {
    dispatch(
      DOBPostcodeContractsDataAction(res, {
        isDetailsFound: true,
        isApiFailed: false,
        isMoreThanOneUser: false,
      }),
    );
    if (!res[0].isRegisteredMTA && isEmptyString(res[0].phoneNumber)) {
      setIsContactFound(false);
      dispatch(DataModelAction(true));
    } else if (!res[0].isRegisteredMTA) {
      dispatch(changeStepAction(2));
      dispatch(toggleAction(false));
      handleOnModalConditions(res, { isRegisterWss: true });
    } else {
      setIsContactFound(true);
      isRegistered.current = res[0].isRegisteredMTA;
      dispatch(DataModelAction(true));
    }
  };
  const renderComponent = (): ReactElement =>
    isWss ? (
      <RegistrationWss
        handleOnPassDetails={handleOnModalConditions}
        handleSingleAgreementData={handleSingleMtaAgreement}
        handleIsRegisteredMta={handleIsRegisteredMta}
        isRegisteredMta={
          isRegistered.current || customerData[0].isRegisteredMTA
        }
        isContactFoundMta={isContactFound}
        handleIsContactFoundMta={handleIsContactFoundMta}
        setCreateAccountFAQ={setCreateAccountFAQ}
      />
    ) : (
      <RegistrationMTA
        brand={brand}
        email={email || customerData[0].emailAddress}
        phoneNumber={phoneNumber || customerData[0].phoneNumber}
        mtaCustomerId={mtaCustomerId || customerData[0].mtaCustomerId}
        handlePassDetails={handleOnModalConditions}
        isContactFound={isContactFound}
        isChangeEmail={isChangeEmail}
        isEmailFound={isEmailFound}
        isCustomerIdentificationError={isCustomerIdentificationError}
        numberOfSteps={numberOfSteps}
        handleOnChangeEmail={handleOnChangeEmail}
        handleCustomerIdentityError={handleCustomerIdentityError}
        isRegisteredWss={isRegistrationWss}
        handleContactFound={handleContactFound}
        handleEmailFound={handleEmailFound}
        passwordValue={passwordValue}
        setPasswordValue={setPasswordValue}
      />
    );

  return renderComponent();
}
